import React, { useState, useEffect, useRef } from 'react'
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar'
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import Loader from '../Containers/Loader';
import { IoMdClose } from 'react-icons/io';
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import CountDownTimer from '../Tools/CountDownTimer';

const ForwardedOrderDetails = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [selectedDelivery, setSelectedDelivery] = useState("self");
  const [fwdOrders, setFwdOrders] = useState(null);
  const [fwdOrderDetails, setFwdOrderDetails] = useState(null);

  // const [dpPharmacyOrderDetails, setDpPharmacyOrderDetails] = useState(null);
  const [dpPharmacyOrderItems, setDpPharmacyOrderItems] = useState(null);
  // const [orderTotals, setOrderTotals] = useState([0, 0, 0, 0]);
  const getPharmacyOrderDetails = (orderId) => {
    setLoader(true);
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?digiOrders=2&user_Id=' + ReactSession.get('id') + '&order_id=' + orderId;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "success") {
        var invItems = [];
        setDpPharmacyOrderItems(resp.orderItems);
        setIsViewOrderListModalShow(true);
        // setDpPharmacyOrderDetails(resp.orderDetails);
        // setOrderTotals([resp.orderDetails[0][16], resp.orderDetails[0][16], resp.orderDetails[0][17], resp.orderDetails[0][18]]);
        // for (let i = 0; i < resp.orderItems.length; i++) {
        //   invItems.push([resp.orderItems[i][5], resp.orderItems[i][9], resp.orderItems[i][10], resp.orderItems[i][11], 0, resp.orderItems[i][6], resp.orderItems[i][7], resp.orderItems[i][8], resp.orderItems[i][3]]);
        // }

        // setNewOrderItems(invItems);
      }
      setLoader(false);
    }).catch((err) => {
      console.error("API Error :", err);
    });
  }


  const updateOrder = (order_id, action, fwdID) => {
    setLoader(true);
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'PUT',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        acceptReject: action,
        user_Id: ReactSession.get('id'),
        order_Id: order_id,
        fwdID: fwdID,
      })
    }).then((response) => response.json())
      .then((resp) => {
        if (resp.status === "validityExpired")
        {
          alert("You have no active subscription plan. please subscribe to continue.");
        }
        
        if (resp.status === "success") {
          alert("Order Updated Successfully");
        }
        getForwardedOrders();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const getForwardedOrders = () => {
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?forwardedOrders=1&user_Id=' + ReactSession.get('id');
    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      // console.log(resp.forwardedOrders[0]);
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }

      if (resp.status === "success") {
        // console.log("Status :", resp.status);
        setFwdOrders(resp.forwardedOrders);
      }
      setLoader(false);
    }).catch((Error) => {
      console.log("Error " + Error);
    });
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          getForwardedOrders();
        }
  }, []);

  const [isViewOrderListModalShow, setIsViewOrderListModalShow] = useState(false);

  const animeGsap = useRef();

  useGSAP(()=>{
  
    gsap.from(".blinkAnime",{
      opacity:0,
      duration:1.5,
      color:"red",
      repeat:-1,
    })


  }, {scope: animeGsap});
  

  return (
    <>
      <DigiPartNavBar />
      <div ref={animeGsap} className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent congOrderRecDetails' id='DigiDashContent' style={{ backgroundColor: '#fff', margin: '20px auto', padding: "0px 30px" }}>

          <div style={{ width: '98%', fontSize: '120%', display: 'flex', justifyContent: 'space-between', borderBottom: '3px solid rgb(61, 197, 238)', marginBottom: 10, paddingBottom: 10 }}>
            <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <div style={{ fontWeight: '600', fontSize: '1.5vw', minWidth: '35%', marginRight: "1%" }} >
                Farwarded Orders
              </div>
              <div style={{ marginLeft: '0%', display: "flex", alignItems: "center", minWidth: '30vw', position: "relative", border: "1.5px solid #000", borderRadius: 10 }}>
                <input style={{ flex: 1, fontSize: 14, border: 0, outline: 0, padding: 7, borderRadius: 10, background: "transperent" }} type='text' name='searchTxt' id='searchTxt' placeholder='Search by Order ID/Amount...!' />
                <button style={{ backgroundColor: "black", width: "80px", height: "37px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} type="submit"><i className="fa fa-search" style={{ color: "#fff" }}></i></button>
              </div>
            </div>

            <div style={{ display: 'flex', fontSize: '75%', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "16vw" }}>
                <div style={{ margin: 0, padding: 0 }}><input type='date' name='dateFrom' id='dateFrom' className='form-control form-control-sm removeDateIcon' style={{ width: "82%", textTransform: "uppercase" }} /></div>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: 0, padding: 0, marginLeft: -15, marginRight: 10 }}>-</p>
                <div style={{ margin: 0, padding: 0 }}><input type='date' name='dateTo' id='dateTo' className='form-control form-control-sm removeDateIcon' style={{ width: "82%", textTransform: "uppercase" }} /></div>
              </div>
              <div>&nbsp;&nbsp;&nbsp;</div>
              <div className='digiNavBtn2' style={{ padding: 5, border: 0, outline: 0, color: "#fff", borderRadius: 5, background: "#00BAFF" }}>Go</div>
              <div className='digiNavBtn2' style={{ padding: 5, border: 0, outline: 0, color: "#fff", borderRadius: 5, background: "#00BAFF" }}>Print</div>
            </div>
          </div>

          {
            fwdOrderDetails && fwdOrderDetails !== null ?
              <>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1.5px solid #000", paddingBottom: "10px", marginBottom: "10px" }}>
                  <div>
                    <h3 style={{ margin: 0, padding: 0, }}>Order Details</h3>
                    <p style={{ textTransform: "uppercase", margin: 0, padding: 0, color: "gray", fontSize: 14 }}>shipping type : <b style={{ color: "#000" }}>standard</b> |
                      Location : <b style={{ color: "#000" }}>kolar road, bhopal</b> | order time <b style={{ color: "#000" }}>21:34</b>
                    </p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <p style={{ color: "gray" }}>22-05-2024</p>
                  </div>
                </div>

                <div className="products customScroll" style={{ width: "100%", overflowY: "auto", maxHeight: "50vh", paddingRight: 10 }}>
                  <div className="product">
                    <div>
                      <h3 style={{ margin: 0, padding: 0, color: "#000", fontSize: "16px" }}>Ecosprin A 75 Tablet</h3>
                      <p style={{ margin: 0, padding: 0, color: "gray", fontSize: "14px" }}>Strip of 14 Tablets</p>
                      <p style={{ margin: 0, padding: 0, color: "gray", fontSize: "14px" }}>USV Ltd</p>
                    </div>
                    <p style={{ margin: 0, padding: 0, color: "gray", fontSize: "14px" }}>Qty : 7 Unit * 78.22</p>
                    <p style={{ margin: 0, padding: 0, color: "gray", fontSize: "14px" }}>Total Estimated MRP : 156.78 | <b style={{ color: "green" }}>OFF 16%</b></p>
                  </div>
                </div>

                <div className="continueProccessCon">

                  <div className="continueProccess">
                    <h3 style={{ color: "gray", fontSize: "18px", margin: 0, padding: 0 }}>Estimated MRP Value : 1472.00</h3>
                    <h3 style={{ color: "gray", fontSize: "18px", margin: 0, padding: 0 }}>Estimated Discoun on MRP : 322.00</h3>
                    <h3 style={{ color: "gray", fontSize: "18px", margin: 0, padding: 0, fontWeight: 700 }}>Estimated Payable Value : 322.00</h3>

                    <div style={{ display: "flex", justifyContent: "center", gap: "5px", marginTop: 10 }}>
                      <p>Choose Delivery</p>
                      &nbsp;
                      &nbsp;
                      <div onClick={() => setSelectedDelivery("self")} style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" }}>
                        <div className="customRadio " style={{ width: "20px", height: 20, borderRadius: "50%", background: selectedDelivery === "self" ? "#00BAFF" : "#6E6E6E", marginBottom: 15 }}></div>
                        <p>Self Delivery</p>
                      </div>

                      <div onClick={() => setSelectedDelivery("logistic")} style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" }}>
                        <div className="customRadio " style={{ width: "20px", height: 20, borderRadius: "50%", background: selectedDelivery == "logistic" ? "#00BAFF" : "#6E6E6E", marginBottom: 15 }}></div>
                        <p>Logistic</p>
                      </div>

                    </div>

                    <button style={{ minWidth: 300, padding: 10, background: "#00BAFF", color: "#fff", fontSize: 15, cursor: "pointer", border: 0, outline: 0, borderRadius: 10 }}>Proccess for billing</button>
                  </div>

                </div>
              </>
              :
              <>
                <div className='tableHead forOrderDetailsTableHead' style={{ width: '100%', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='col-sm' style={{ width: '6vw', padding: '5px', }}>
                    Order ID
                  </div>
                  <div className='col-sm' style={{ width: '20vw', padding: '5px', }}>
                    From /
                    Date
                  </div>
                  <div className='col-sm' style={{ Width: '20vw', padding: '5px' }}>
                    Customer Details
                  </div>
                  <div className='col-sm' style={{ width: '10vw', padding: '5px', marginLeft: 20 }}>
                    Order Details
                  </div>
                  <div className='col-sm' style={{ width: '10vw', padding: '5px', }}>
                    Order Status
                  </div>
                  <div className='col-sm' style={{ width: '6vw', padding: '5px', justifyContent: "center" }}>
                    Action
                  </div>
                </div>
                {fwdOrders && fwdOrders !== null ?
                  fwdOrders.map((order, index) => {
                    return (
                      <div key={index} className={index % 2 === 0 ? 'purchBookRecord tableRecords' : 'purchBookRecord tableRecords'}
                        style={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
                        <div className="col-sm" style={{ width: '6vw', padding: '5px', }}>
                          #{order[4]}
                        </div>
                        <div className="col-sm" style={{ width: '20vw', padding: '5px' }}>
                          {order[1]} <br />
                          {order[3].split('T')[0]} | {order[3].split('T')[1]} <br />
                          <span style={{ color: "red" }}>Express Delivery {"(1 Hour)"}</span>
                        </div>
                        <div className="col-sm" style={{ width: '20vw', padding: '5px' }}>
                          <b style={{ color: "#000" }}>{order[9]}</b>
                          <p>{order[7]}</p>
                        </div>
                        <div className="col-sm" style={{ width: '10vw', padding: '5px', display: 'block', marginLeft: 20 }}>
                          <div style={{ color: "#000", fontWeight: 700 }}>
                            Payable Value : {order[16]?.toFixed(2)}
                          </div>
                          <div>
                            Quantity : {order[14]}
                          </div>
                          <div>
                            <a className='blinkAnime' onClick={()=>{getPharmacyOrderDetails(order[4])}} style={{ textDecoration: "underline", color: "#000" }}>View Item List</a>
                          </div>
                        </div>
                        <div className="col-sm" style={{ width: '10vw', padding: '5px' }}>
                          <div style={{ color: order[2] === "Missed" ? "red" : order[2] === "Pending" ? "" : order[2] === "Accepted" ? "green" : "" }}>

                            {
                              order[2] === "Pending" ?
                                <>
                                  <p style={{ margin: 0, padding: 0, color: "#CC5500" }}>Waiting...</p>
                                  <p style={{ margin: 0, padding: 0 }}>Response Pending</p>
                                  <p style={{ margin: 0, padding: 0 }}><CountDownTimer startTime={order[3]} initialSeconds={3600} /> </p>
                                </> : order[2]
                            }
                          </div>
                        </div>

                        <div className="col-sm" style={{ width: '6vw', padding: '5px', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                          {order[2] === "Pending" ? (
                            <>
                              <div className='digiNavBtnGreen' onClick={() => { updateOrder(order[4], 'accept', order[20]); }}>Accept</div>
                              <div className='digiNavBtnRed' onClick={() => { updateOrder(order[4], 'reject', order[20]); }}>Reject</div>
                            </>
                          ) : order[2] === "Missed" ? (
                            <>
                              <div className='digiNavBtnGreen disabled' disabled>Accept</div>
                              <div className='digiNavBtnRed disabled' disabled>Reject</div>
                            </>
                          ) : order[2] === "Accepted" ? (
                            <>
                              <div style={{ borderRadius: "30px" }} className='btnPrimary1' onClick={() => { navigate("/DigiPartnerPharmacyOrders") }}>Process For Billing</div>
                            </>
                          ) : ""
                          }
                        </div>
                      </div>
                    )
                  }) :

                  <div>Sorry for this time, you dont have and forwarded orders</div>
                }
              </>
          }
        </div>
      </div>

      {
        isViewOrderListModalShow ? <>
          <div className="viewOrderListCon" onClick={()=>{setIsViewOrderListModalShow(false)}}>

            <div className="viewOrderListModal">
              <div className="viewOrderListModalHead">
                <h3 style={{ textTransform: "uppercase", margin: 0, padding: 0, color: "#000", fontSize: 20 }}>Order ID : #{dpPharmacyOrderItems !== null ? dpPharmacyOrderItems[0][0] : ''}</h3>
                <i onClick={()=>{setIsViewOrderListModalShow(false)}} style={{ fontSize: 25, cursor: "pointer", margin: 0, padding: 0 }}><IoMdClose /></i>
              </div>

              <div className="products customScroll" style={{ overflowY: "auto", maxHeight: "80%", paddingRight: 15 }}>

                {
                  dpPharmacyOrderItems && dpPharmacyOrderItems !== null ? 

                  dpPharmacyOrderItems.map((orderDetails)=>{
                    return (
                      <div className="product">
                        <div>
                          <h3 style={{ margin: 0, padding: 0, fontSize: 14, color: "#000" }}>{orderDetails[6]}</h3>
                          <p style={{ margin: 0, padding: 0, fontSize: 12, color: "gray" }}>{orderDetails[7]}</p>
                          <p style={{ margin: 0, padding: 0, fontSize: 12, color: "gray" }}>{orderDetails[8]}</p>
                        </div>

                        <p style={{ margin: 0, padding: 0, fontSize: 12 }}>
                          {orderDetails[9]} Unit
                        </p>

                        <div style={{ textAlign: "right" }}>
                          <p style={{ margin: 0, padding: 0, color: "gray", fontSize: 12 }}>MRP : { Number(orderDetails[10] / parseInt(orderDetails[9])).toFixed(2) }</p>
                          <p style={{ margin: 0, padding: 0, color: "green", fontSize: 12 }}>Discount : 20%</p>
                          <p style={{ margin: 0, padding: 0, fontSize: 12, color: "#000", fontWeight: 700 }}>Total Price : {Number(orderDetails[10]).toFixed(2)}</p>
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className="product">
                    <div>
                      <h3 style={{ margin: 0, padding: 0, fontSize: 14, color: "#000" }}>Order Details not found</h3>
                    </div>
                  </div>
                }

              
              </div>

            </div>

          </div>
        </> : ""
      }

    </>
  )
}

export default ForwardedOrderDetails