import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Select from 'react-select';

ReactSession.setStoreType("localStorage");

const VoucherEntry = () => {
  const navigate = useNavigate();
  const tranTypeRef = useRef(0);
  const savVoucRef = useRef(1);
  const refBankMode = useRef(2);

  const tranTypes = [
  { key: 1, value: 1, label: 'Contra' },
  { key: 2, value: 2, label: 'Payment' },
  { key: 3, value: 3, label: 'Receipt' },
  { key: 4, value: 4, label: 'Sales' },
  { key: 5, value: 5, label: 'Purchase' },
  { key: 6, value: 6, label: 'Journal' },
  { key: 7, value: 7, label: 'Other Vouchers' },
  ];

  const [voucherItems, setVoucherItems] = useState([]);

  const [invoiceNewNo, setInvoiceNewNo] = useState('');

  const [tranType, setTranType] = useState("Payment");
  const [ledgers, setLedgers] = useState([{ key: -1, value: -1, label: 'None' }]);
  const [fillLedgers, setFillLedgers] = useState(null);
  // const [ledgerGroups, setLedgerGroups] = useState(null);
  const [contraType, setContraType] = useState("");
  const [DebitOrCredit, setDebitOrCredit] = useState("To");
  const [bankInp, setBankInp] = useState(false);
  const [bankMode, setBankMode] = useState("Cheque");

  const removeErrorMessage = (element) => {
  document.getElementById(element).classList.remove("errorField");
  }


  const handleTranType = e => {
  setTranType(e.label);
  if (invoiceNewNo === "") {
    getNewVoucherNo();
  }

  if (e.label === "Payment" || e.label === "Sales")
    setDebitOrCredit('By');
  else
    setDebitOrCredit('To');

  document.getElementById('voDate').focus();
  }

  const handleBankMode = e => {
  setBankMode(e.label);
  }

  const addVoucherItem = () => {
  var errorElements = [];

  var ledID = document.getElementById('ledID');
  var ledName = document.getElementById('ledName');
  var amtDr = document.getElementById('amtDr');
  var amtCr = document.getElementById('amtCr');
  var cheque = document.getElementById('cheque');
  var chequeDate = document.getElementById('chequeDate');
  var ifsc = document.getElementById('ifsc');
  var bankName = document.getElementById('bankName');

  var DrSum = 0;
  var CrSum = 0;

  if (invoiceNewNo === "") {
    getNewVoucherNo();
  }

  if (ledName.value === '') {
    errorElements.push('ledName');
  }
  else {
    const fillLed = ledgers.filter(userDa => userDa[3]?.toLowerCase().includes(ledName.value.toLowerCase()));
    if (fillLed.length < 1) {
    document.getElementById('errorMessage').innerHTML += "<br /><p>Ledger does not exist</p>";
    errorElements.push('ledName');
    }
    else {
    ledID.value = fillLed[0][0];
    ledName.value = fillLed[0][3];
    }
  }

  if (DebitOrCredit === "By") {
    if (amtDr.value === '' || isNaN(amtDr.value)) {
    document.getElementById('errorMessage').innerHTML += "<br /><p>and debit amount must be numeric</p>";
    errorElements.push('amtDr');
    }
  }
  else {
    if (amtCr.value === '' || isNaN(amtCr.value)) {
    document.getElementById('errorMessage').innerHTML += "<br /><p>and credit amount must be numeric</p>";
    errorElements.push('amtCr');
    }
  }

  errorElements.forEach(showError);
  function showError(item) {
    document.getElementById(item)?.classList.add("errorField");
  }

  if (errorElements.length > 0) {
    document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
    document.getElementById(errorElements[0])?.focus();
    return false;
  }
  else {
    DrSum = 0;
    CrSum = 0;
    for (let i = 0; i < voucherItems.length; i++) {
    if (voucherItems[i][4] === "By")
      DrSum += parseInt(voucherItems[i][2]);

    if (voucherItems[i][4] === "To" && voucherItems[i][3] != null)
      CrSum += parseInt(voucherItems[i][3]);
    }
    if (amtCr != null)
    CrSum += parseInt(amtCr?.value);
    if (amtDr != null)
    DrSum += parseInt(amtDr?.value);

    if (voucherItems.length > 0 && DrSum < CrSum && (tranType === "Payment" || tranType === "Sales")) {
    document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
    document.getElementById("errorMessage").innerHTML += "<p>Credit amount are larger then debit Amount</p>";
    document.getElementById('amtCr')?.classList.add("errorField");
    }
    else if (voucherItems.length > 0 && DrSum > CrSum && (tranType !== "Payment" && tranType !== "Sales")) {
    document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
    document.getElementById("errorMessage").innerHTML += "<p>Credit amount are smaller then debit Amount</p>";
    document.getElementById('amtCr')?.classList.add("errorField");
    }
    else {
    document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
    setVoucherItems([...voucherItems, [ledID.value, ledName.value, amtDr?.value, amtCr?.value, DebitOrCredit, bankMode, cheque?.value, ifsc?.value, bankName?.value, chequeDate?.value]]);
    ledName.focus();
    ledName.value = "";
    if (amtCr)
      amtCr.value = "";

    if (amtDr)
      amtDr.value = "";
    }
  }

  if (DebitOrCredit === "To" && voucherItems <= 1)
    setDebitOrCredit('By');

  if (DebitOrCredit === "By" && voucherItems <= 1)
    setDebitOrCredit('To');

  if (DrSum === CrSum) {
    document.getElementById("tranDiv").style.display = "none";
    document.getElementById("narration").focus();
  }
  }

  const selectStyle2 = {
  options: (baseStyles, state) => ({
    ...baseStyles,
    // fontSize: '1vw',
    border: '1px solid black',
    borderRadius: '0px',
    width: '100%',
  }),

  menu: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '1vw',
    background: "white",
    border: '1px solid black',
    borderRadius: '0px',
  }),

  control: (baseStyles, state) => ({
    ...baseStyles,
    border: '1px solid black',
    borderRadius: '0px',
    height: '25px',
    minHeight: '25px',
    // marginTop:'-20px',

    // fontSize: '1.5vw',
    // width: '10',
  }),

  input: (provided, state) => ({
    ...provided,
    // marginTop: '-20px',
    fontSize: '1vw',
  }),

  placeholder: (provided, state) => ({
    ...provided,
    // fontSize: '1.5vw',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: '-5px',
    width: 36,  // Set the desired width
    height: 36, // Set the desired height
    // Add any other styles you want for the dropdown indicator
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    marginTop: '-5px',
    // fontSize: '1vw',
    // lineHeight: '.5vw',
  }),
  }

  //Creating Voucher
  const createVoucher = () => {
  fetch('http://35.154.53.53/digiPartnerAPI/', {
    mode: 'cors',
    method: 'POST',
    headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    body: JSON.stringify({
    tranType: tranType,
    dp_id: ReactSession.get("id"),
    invoiceNewNo: invoiceNewNo,
    voDate: document.getElementById('voDate').value,
    voucherItems: voucherItems,
    narration: document.getElementById('narration').value,
    })
  })
    .then((response) => response.json())
    .then((resp) => {
    if (resp.status === "validityExpired")
    {
      alert("You have no active subscription plan. please subscribe to continue.");
    }
    else if (resp.status === "accessError")
    {
      alert("Your current subscription doesnot support transaction service, please contact pillsit sales team");
    }
    else if (resp.status === 'success') {
      // getLedgers();
      document.getElementById("tranDiv").style.display = "flex";
      document.getElementById('narration').value = "";
      document.getElementById('ledName').value = "";
      if (document.getElementById('amtDr') != null)
      document.getElementById('amtDr').value = "";

      if (document.getElementById('amtCr') != null)
      document.getElementById('amtCr').value = "";
      
      setInvoiceNewNo('');
      setVoucherItems([]);
      setDebitOrCredit("To");
      setTranType("Payment");
      setBankInp(false);
      setBankMode("Cheque");
      alert("Voucher Successfully Created");
      getNewVoucherNo();
    }
    else
      alert("Error Creating Voucher");
    })
    .catch((err) => {
    console.log(err.message);
    });

  tranTypeRef.current.focus();
  }

  //Generate new voucherNo
  const getNewVoucherNo = () => {
  if (invoiceNewNo === '') {
    fetch("http://35.154.53.53/digiPartnerAPI?getInvNo=1&user_Id=" + ReactSession.get("id"), {
    method: 'GET',
    headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
    return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else
      {
        setInvoiceNewNo(resp.invLastRec);
      }
    }).catch((err) => {
    console.log(err.message);
    });
  }
  }

  // getLedgers
  const getLedgers = () => {
  var dp_id = ReactSession.get("id");
  var apiURL = 'http://35.154.53.53/digiPartnerAPI?getLedgers=1&user_Id=' + dp_id;

  fetch(apiURL, {
    mode: 'cors',
    method: 'GET',
    headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
  }).then((response) => {
    return response.json()
  }).then((resp) => {
    if (resp.status === "validityExpired")
    {
      alert("You have no active subscription plan. please subscribe to continue.");
    }
    else
    {
      setLedgers(resp.ledgers);
      setFillLedgers(resp.ledgers);
    }
    // setLedgerGroups(resp.ledgerGroups);
  }).catch((err) => {
    console.log(err.message);
  });
  }

  // const setMode = (e) => {
  //   console.log("Alt Key pressed :", e.key, '   Code :', e.keyCode);
  //   if (e.key === "F6")
  //   setTranType("Contra");
  //   else if (e.key === "F3") {
  //   setTranType("Payment");
  //   document.getElementById('debit').focus();
  //   }
  //   else if (e.key === "F4") {
  //   setTranType("Receipt");
  //   document.getElementById('debit').focus();
  //   }
  //   else if (e.key === "F7")
  //   setTranType("Journal");
  //   else if (e.key === "F8")
  //   setTranType("Sales");
  //   else if (e.key === "F9")
  //   setTranType("Purchase");
  // }

  const setContra = (e) => {
  setContraType(e.target.value);
  }


  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) => {
  if (value) {
    document.getElementById('DigiDashPanel').style.display = 'block';
    document.getElementById('dpSidePanelBTN').style.left = '75vw';
  }
  else {
    document.getElementById('DigiDashPanel').style.display = 'none';
    document.getElementById('dpSidePanelBTN').style.left = '0px';
  }
  setMenuCollapse(value);
  }

  useEffect(() => {
  if (!ReactSession.get('dpsession')) {
    alert("You are not logged in.")
    navigate('/DigitalPartner');
  }
  else
    if (String(ReactSession.get('is_verified')) === "0") {
    alert("PLease complete your profile before search...")
    navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        getLedgers();
      }
  }, []);


  return (
  <>
    <DigiPartNavBar />
    <div className='DigitalPartnerContainer'>
    <div className='DigiDashPanel' id='DigiDashPanel'>
      <DigiPartnerSidePanel />
    </div>
    <div className='DigiDashContent addVoucher' id='DigiDashContent' style={{ paddingInline: '2%', backgroundColor: 'white' }}>

      <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
      {menuCollapse ?
        <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
        :
        <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
      }
      </div>

      <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
      <div className='VoucherHeader'>
       <h3 style={{fontWeight: "700",margin:0,padding:0}}>Create Voucher</h3>
      </div>


      <div>

      <div style={{ padding: 10, background: "#D3D3D3", Width: "300px", textAlign:'center' }}>Voucher Number : {invoiceNewNo}</div>
      {/* <div>Voucher Number : {invoiceNewNo}</div> */}
      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.5%', alignItems: 'center' }}>

        <div style={{ width: '20%', marginTop: 50 }}>
        <p style={{ fontSize: 14, color: "gray", margin: 0, padding: 0, marginBottom: 5 }}>Transaction Type</p>
        <Select onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Tab") {
          document.getElementById('voDate').focus();
          }
        }} ref={tranTypeRef} options={tranTypes} onChange={handleTranType} isSearchable={true} placeholder={"Select Voucher Type"}
          tabIndex="1" id="TranType" autoFocus className='voucherSelect' styles={{ minWidth: "400px" }} />
        </div>


        <div style={{ marginTop: 50, display: "flex", alignItems: "center" }}>
        <input type='date' style={{ border: 0, outline: 0, width: "100px" }} id="voDate" name="voDate"
          onKeyDown={(e) => {
          // e.preventDefault();
          if (e.key === "Enter" || e.key === "Tab") {
            document.getElementById('ledName').focus();
          }
          }}
        />
        <img src={require("../../static/DPImages/calendar.png")} style={{ width: "25px", cursor: "pointer", height: "25px", marginLeft: 10 }} alt="" />
        </div>
      </div>
      </div>
      {
      tranType === "Sales" ?
        <div style={{ borderBlock: '1px solid lightgray', paddingBlock: '.5%' }}>
        <h6>Dispatch Details</h6>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <input style={{ marginBlock: '5px' }} type='text' name="dispatchDate" id="dispatchDate" placeholder='Dispatch Date' tabIndex="2" />
          <input style={{ marginBlock: '5px' }} type='text' name="delNoteNos" id="delNoteNos" placeholder='Delivery Note Nos' tabIndex="3" />
          <input style={{ marginBlock: '5px' }} type='text' name="dispatchDocNo" id="dispatchDocNo" placeholder='Dispatch Doc No' tabIndex="4" />
          <input style={{ marginBlock: '5px' }} type='text' name="dispatchThru" id="dispatchThru" placeholder='Dispatched Through' tabIndex="5" />
          <input style={{ marginBlock: '5px' }} type='text' name="destination" id="destination" placeholder='Destination' tabIndex="6" />
          <input style={{ marginBlock: '5px' }} type='text' name="carrierNameAgent" id="carrierNameAgent" placeholder='Carrier Name/Agent' tabIndex="7" />
          <input style={{ marginBlock: '5px' }} type='text' name="billLading" id="billLading" placeholder='Bill of Lading' tabIndex="8" />
          <input style={{ marginBlock: '5px' }} type='text' name="motorVehicleNo" id="motorVehicleNo" placeholder='Motor Vehicle No' tabIndex="9" />
        </div>
        </div>
        : ''
      }

      <div style={{ marginBlock: '10px',marginTop:40,paddingBottom:10, display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid gray', marginBottom: "16px" }}>
      <div style={{ width: '70%',fontSize: '14px',color:"gray"}}>
        Particulars
      </div>
      <div style={{ width: '15%',fontSize: '14px',color:"gray"}}>
        Debit Amount
      </div>
      <div style={{ width: '15%',fontSize: '14px',color:"gray",textAlign:"right"}}>
        Credit Amount
      </div>
      </div>

      {voucherItems && voucherItems.length > 0 ?
      voucherItems.map((item, index) => {
        return (
        <div key={index} style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div>
          {item[4] + " "}
          </div>
          <div style={{ display: 'flex', width: '70%' }}>
          <input style={{ paddingInline: '5px', border: 'none' }} type='text' value={item[1]} />
          {/* bankMode, cheque?.value, ifsc?.value, bankName?.value, chequeDate?.value */}
          {item[1].toLowerCase().includes('bank') ?
            <div>
            <div style={{ display: 'flex', fontSize: '80%', paddingInline: '20px' }}>
              &nbsp;&nbsp;<input style={{ paddingInline: '5px', border: 'none', width: '20%' }} type='text' value={item[5]} />
              &nbsp;&nbsp;<input style={{ paddingInline: '5px', border: 'none', width: '20%' }} type='text' value={item[6]} />
              &nbsp;&nbsp;&Date : <input style={{ paddingInline: '5px', border: 'none', width: '40%' }} type='text' value={item[9]} />
            </div>

            {item[6].toLowerCase().includes("Online") ?
              <div style={{ display: 'flex', fontSize: '80%', paddingInline: '20px' }}>
              <input style={{ paddingInline: '5px', border: 'none', width: '30%' }} type='text' value={item[7]} />
              &nbsp;&nbsp;<input style={{ paddingInline: '5px', border: 'none', width: '30%' }} type='text' value={item[8]} />
              </div>
              : ''
            }
            </div>
            : ''
          }
          </div>
          <div style={{ width: '15%' }}>
          <input style={{ width: '70%', paddingInline: '5px', border: 'none' }} type='text' value={item[2]} />
          </div>
          <div style={{ width: '15%' }}>
          <input style={{ width: '70%', paddingInline: '5px', border: 'none' }} type='text' value={item[3]} />
          </div>
        </div>
        )
      })
      :
      ''}

      <div id="tranDiv" style={{display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '70%' }}>
        <span className="tranType" id="tranType">{ DebitOrCredit+' ' }</span>
        <input type='text' name="ledID" id="ledID" hidden />
        <input style={{ paddingInline: '5px', width: "40vw", padding: 6, border: "1px solid hsl(0, 0%, 80%)", borderRadius: 3, outline: 0 }} type='text' name='ledName' id='ledName'
        onChange={() => {
          var ledInp = document.getElementById('ledName');
          if (ledInp.value.toLowerCase().includes('bank')) {
          setBankInp(true);
          }
          else {
          setBankInp(false);
          }
        }}

        onKeyDown={(e) => {
          removeErrorMessage('ledName');
          if (e.key !== "ArrowDown" && e.key !== "Enter" && e.key !== "Tab" && e.key !== "Alt" && e.key !== "Control" && e.key !== "Shift") {
          document.getElementById('ledListCont').classList.remove("hide");
          const fillLed = ledgers.filter(userDa =>
            userDa[3]?.toLowerCase().includes(e.target.value.toLowerCase())
          );
          setFillLedgers(fillLed);
          }

          if (e.key === "ArrowDown") {
          const ledDiv = document.getElementById('ListofLedgers');
          ledDiv.firstElementChild.focus();
          }
          if (e.key === "Enter") {
          if (bankInp)
            refBankMode.current.focus();
          else if (DebitOrCredit === "By") {
            document.getElementById('amtDr').focus();
          }
          else {
            document.getElementById('amtCr').focus();
          }
          }
        }}
        tabIndex={10} />

        {
        bankInp ?
          <div>
          <div style={{ display: 'flex', fontSize: '80%', paddingInline: '20px' }}>
            <Select onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Tab") {
              document.getElementById('cheque').focus();
            }
            }} ref={refBankMode} defaultValue={{ key: 1, value: 1, label: 'Cheque' }}
            options={[{ key: 1, value: 1, label: 'Cheque' }, { key: 2, value: 2, label: 'Online' }, { key: 3, value: 3, label: 'Others' },]}

            onChange={handleBankMode} isSearchable={true} placeholder={"Mode"} styles={selectStyle2} tabIndex="1" id="bankMode" />

            &nbsp;&nbsp;<input style={{ paddingInline: '5px', width: '25%' }} type='text' name='cheque' id='cheque' placeholder='Inst No'
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === "Tab")
              document.getElementById("chequeDate").focus();
            }}
            />

            &nbsp;&nbsp;&Date : <input style={{ paddingInline: '5px' }} type='date' name='chequeDate' id='chequeDate'
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === "Tab")
              if (bankMode === "Online")
                document.getElementById("bankName").focus();
              else
                if (DebitOrCredit === "By") {
                document.getElementById('amtDr').focus();
                }
                else {
                document.getElementById('amtCr').focus();
                }
            }}
            />
          </div>

          {bankMode && bankMode === "Online" ?
            <div style={{ display: 'flex', fontSize: '80%', paddingInline: '20px' }}>
            <input style={{ paddingInline: '5px', width: '25%' }} type='text' name='bankName' id='bankName' placeholder='Bank Name' tabIndex={118}
              onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === "Tab")
                if (bankMode === "Online")
                document.getElementById("ifsc").focus();
              }}
            />

            &nbsp;&nbsp;<input style={{ paddingInline: '5px', width: '25%' }} type='text' name='ifsc' id='ifsc' placeholder='IFSC' tabIndex={119}
              onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === "Tab")
                if (DebitOrCredit === "By") {
                document.getElementById('amtDr').focus();
                }
                else {
                document.getElementById('amtCr').focus();
                }
              }}
            />
            </div>
            : ''
          }
          </div>
          : ''
        }
      </div>

      {/* tranType === "Receipt" || tranType === "Purchase" || tranType === "Contra" */}
      <div style={{ width: '15%' }}>
        {
        DebitOrCredit === "By" ?
          <input style={{ width: '70%', paddingInline: '5px' }} type='text' name='amtDr' id='amtDr' tabIndex="11"
          onKeyDown={(e) => {
            removeErrorMessage('amtDr');
            if (e.key === "Enter") {
            addVoucherItem();
            }
          }}
          />
          : ''
        }
      </div>

      <div style={{ width: '15%',display:"flex",alignItems:"flex-end",flexDirection:"column"}}>
        {
        DebitOrCredit === "To" ?
          <input placeholder={"0.00"} style={{ width: '70%', paddingInline: '5px', border: "0", borderBottom: "1px solid #000", outline: 0, textAlign: "center" }} type='text' name='amtCr' id='amtCr' tabIndex="11"
          onKeyDown={(e) => {
            removeErrorMessage('amtCr');
            if (e.key === "Enter") {
            addVoucherItem();
            }
          }}
          />
          : ''
        }
      </div>
      </div>

      <div style={{ display: 'none' }}>
      {/*   {
      tranType === "Payment" ?
        <>
        <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '70%' }}>By <input style={{paddingInline:'5px'}} type='text' name='debit' id='debit' 
          onKeyDown={(e)=>{
            document.getElementById('ledListCont').classList.remove("hide");
            const fillLed = ledgers.filter(userDa =>
            userDa[3]?.toLowerCase().includes( e.target.value.toLowerCase())
            );
            setFillLedgers(fillLed);
          }}
           />
          </div>
          <div style={{ width: '15%' }}>
          <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtDr' id='amtDr' />
          </div>
          <div style={{ width: '15%' }}>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
          To <input style={{paddingInline:'5px'}} type='text' name='credit' id='credit' onKeyDown={(e) => {
            // setMode(e)
          }} />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          <div style={{ width: '15%' }}>
          <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtCr' id='amtCr' />
          </div>
        </div>
        </>
        : tranType === "Receipt" ?
        <>
          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            To <input style={{paddingInline:'5px'}} type='text' name='credit' id='credit' onKeyDown={(e) => {
            // setMode(e)
            }} />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtCr' id='amtCr' />
          </div>
          </div>


          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '70%' }}>By <input style={{paddingInline:'5px'}} type='text' name='debit' id='debit' onKeyDown={(e) => {
            // setMode(e)
          }} />
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtDr' id='amtDr' />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          </div>
        </>
        :
        tranType === "Contra" ?
        <>
          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            To <input style={{paddingInline:'5px'}} type='text' name='credit' id='credit' onKeyDown={(e) => {
            // setMode(e);
            setContra(e);
            }} />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtCr' id='amtCr' />
          </div>
          </div>

          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '70%' }}>By <input style={{paddingInline:'5px'}} type='text' name='debit' id='debit' onKeyDown={(e) => {
            // setMode(e)
          }} />
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtDr' id='amtDr' />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          </div>
          {
          contraType && contraType.toLowerCase().match("bank") ?
            <div >
            <div style={{ width: '70%' }}>
              Cheque No: <input style={{paddingInline:'5px'}} type='text' name='cheque' id='cheque' />
            </div>
            </div>
          : contraType && contraType.toLowerCase().match("cash") ?
            <div>
            <div style={{ width: '70%' }}>
              Bank Name : <input style={{paddingInline:'5px'}} type='text' name='bankName' id='bankName' />
            </div>
            </div>
            : ''
          }

        </>
        :
        tranType === "Purchase" ?
        <>
          <div>
          Supplier Invoice No <input style={{paddingInline:'5px'}} type='text' name='supInvNo' id='supInvNo' onKeyDown={(e) => {
            // setMode(e)
          }} />
          </div>

          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            To <input style={{paddingInline:'5px'}} type='text' name='credit' id='credit' onKeyDown={(e) => {
            // setMode(e)
            }} />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtCr' id='amtCr' />
          </div>
          </div>

          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '70%' }}>By <input style={{paddingInline:'5px'}} type='text' name='debit' id='debit' onKeyDown={(e) => {
            // setMode(e)
          }} />
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtDr' id='amtDr' />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          </div>
        </>
        :
        tranType === "Sales" ?
        <>
          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '70%' }}>By <input style={{paddingInline:'5px'}} type='text' name='debit' id='debit' onKeyDown={(e) => {
            // setMode(e)
          }} />
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%', paddingInline:'5px' }} type='text' name='amtDr' id='amtDr' />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          </div>

          <div style={{ marginBlock: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            To <input style={{paddingInline:'5px'}} type='text' name='credit' id='credit' onKeyDown={(e) => {
            // setMode(e)
            }} />
          </div>
          <div style={{ width: '15%' }}>
          </div>
          <div style={{ width: '15%' }}>
            <input style={{ width: '70%',paddingInline:'5px' }} type='text' name='amtCr' id='amtCr' />
          </div>
          </div>
        </>
        : ''
      } */}
      </div>

      <div style={{ width: '100%', paddingBlock: '1%', display: "flex", flexDirection: "column" }}>
      <textarea style={{ padding: '10px', width: '40vw', height: '170px', outline: 0, resize: 'none', border: "0px", background: "#e6e6e6", borderRadius: 5 }} name="narration" id="narration" placeholder='Narration'
        onKeyDown={(e) => {
        if (e.key === "Enter") {
          createVoucher();
        }
        }}

        onKeyPress={(e) => {
        if (e.key === "Tab") {
          document.getElementById("digiNavBtn3").focus();
          // savVoucRef.current.focus();
        }
        }}
        tabIndex="12" />

      <div style={{ display: "flex", alignItems: "center" }}>
        <input style={{ width: "27%",padding:8,fontSize:14,background:"#2C9CF7",outline:0,border:0,color:"#fff",borderRadius:5,marginLeft:0 }} type='button' tabIndex={55} ref={savVoucRef} className='digiNavBtn3' id="digiNavBtn3" value="Save Voucher"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
          createVoucher();
          }
        }}
        onClick={createVoucher} />

        <div className='digiNavBtn' style={{ width: "27%" , textAlign:"center",padding:8,fontSize:14,background:"rgb(253, 81, 81)",outline:0,border:0,color:"#fff",borderRadius:5}}>Cancel</div>
      </div>

      </div>

      {/* Ledger Comtainer */}
      <div className="ledListCont customScroll hide" style={{boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",position: 'fixed', right: '10px', backgroundColor: '#fff', top: '10%', overflowX: 'scroll', height: '85vh', fontSize: '90%' , width:"25vw"}} id='ledListCont' >
      <div style={{ fontSize: '105%', backgroundColor: '#000', padding: '10px', color: 'white',position:"sticky",top:0 }} >
      List of Ledgers
      </div>

      <div className='ListofLedgers' style={{border:"none"}} id='ListofLedgers'>
        {fillLedgers ? fillLedgers.map((item, index) => {
        return (
          <div key={index} className='searchResModal' style={{paddingLeft:10, marginInline: '5px',background:"#fff",border:"none",alignItems: 'center' }} tabIndex={0}
          onClick={(e) => {
            document.getElementById('ledID').value = item[0];
            document.getElementById('ledName').value = item[3];
            document.getElementById("ledListCont").classList.add("hide");
            document.getElementById('ledName').focus();
            if (item[3].toLowerCase().includes('bank'))
            setBankInp(true)
            else
            setBankInp(false);
          }}

          onKeyDown={(e) => {
            if (e.key === "Enter") {
            document.getElementById('ledID').value = item[0];
            document.getElementById('ledName').value = item[3];
            document.getElementById("ledListCont").classList.add("hide");
            document.getElementById('ledName').focus();

            if (item[3].toLowerCase().includes('bank'))
              setBankInp(true)
            else
              setBankInp(false);
            }
            else
            if (e.key === "ArrowUp") {
              const focusedInput = document.activeElement;
              if (focusedInput.previousElementSibling) {
              focusedInput.previousElementSibling.focus();
              }
            }
            else
              if (e.key === "ArrowDown") {
              const focusedInput = document.activeElement;
              if (focusedInput.nextElementSibling) {
                focusedInput.nextElementSibling.focus();
              }
              }
          }}
          >
          <p style={{color:"gray",padding:0,margin:0}}>{item[3]} {'-->'} {item[4]}</p>
          </div>
        )
        }
        ) : ''
        }
      </div>
      </div>
    </div>
    </div>
  </>
  )
}

export default VoucherEntry