import React, { useEffect, useState } from 'react'
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import Footer from '../Containers/Footer';
// import { createRoot } from 'react-dom/client';
import cart from '../../SharedItems';
import { ReactSession } from 'react-client-session';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import defProdImage from '../../static/images/Icons/defproducts.png';
import { useNavigate } from 'react-router-dom';

const ProductGallery = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const dispCat = queryParameters.get("dispCat");
  const catName = queryParameters.get("catName");
  const navigate = new useNavigate();

  const [prodGallData, setProdGallData] = useState(null);
  const [prodGallImage, setProdGallImage] = useState(null);

  const [pagesize, setPageSize] = useState(25);
  const [noofRec, setNoofRec] = useState(0);

  const [page, setPage] = useState(1);;

  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }

  // Method to add product to cart
  function addtoCart(ProId) {
    fetch('http://35.154.53.53/selectProduct/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        proId: ProId,
        userId: ReactSession.get('id_eu'),
      })
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('CartCount').classList.replace('hide', 'show');
        cart.value = data.cart;
        document.getElementById("CartCount").innerText = cart.value;
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const getProducts = () => {
    var url = "http://35.154.53.53/manageProdAPI?prodCat=" + dispCat + "&page=" + String(page) + "&pagesize=" + String(pagesize);
    fetch(url, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      setProdGallData(res.data);
      setProdGallImage(res.images);
      setNoofRec(res.noofRec);
      document.getElementById('pageNo').placeholder = page;
    });
  }

  useEffect(() => {
    getProducts();
  }, [page])

  return (
    <>
      <Logo />
      {/* &#10140;   */}
      <NavSearchBar />
      <div className='productGallery'>
        <h6>Product Gallery &#10132;{catName}</h6>
        <Row className="OrderPagination" style={{ fontSize: '115%' }}>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <button className="pageNav" onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
            <button className="pageNav" onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
            <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
            <button className="pageNav " onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
            <button className="pageNav" onClick={() => lastPage()} >Bottom</button>
          </Col>
        </Row>
        <div id="displayProducts">
          {
            prodGallData && prodGallImage !==null ?
              prodGallData.map((product) => {
                if (prodGallImage[product[6]] !== "None")
                {
                  var base64String = prodGallImage[product[6]];
                }
                else
                {
                  var base64String ='';
                }

                return <>
                  <Col md={3} lg={3} xl={3} sm={6} xs={12} style={{ textAlign: "center" }}>
                    <img className="galleryImages" id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                      onClick={() => { navigate("/DisplayProduct?pro_id=" + product[0]) }}
                      onError={(e) => { e.target.src = defProdImage }} alt="" />
                    <div style={{ fontSize: '1.5vh' }}>{product[1]}</div>
                    <div>Offer Price {product[7]} :<span style={{ textDecoration: 'line-through' }}>{product[4]}</span></div>
                    <div className='orderManageBtn' style={{ fontSize: '90%', width: '40%', marginLeft: 'auto', marginRight: 'auto' }} onClick={() => { addtoCart(product[0]) }}>ADD TO CART</div>
                  </Col>
                </>
              })

              : ''
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ProductGallery