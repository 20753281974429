import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Select from 'react-select';
import Loader from '../Containers/Loader';

ReactSession.setStoreType("localStorage");

const DigiPartnerProfile = () => {
  const [loader, setLoader] = useState(false);

  // Select Styles
  const selectStyle = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      background: "white",
      border: '1px solid gray',
      lineHeight: '.5rem',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      paddingTop: '-20px',
      height: '20px',
      marginTop: '.4rem',
      minHeight: '30px',
      border: selectedCityPer !== null ? '1px solid gray' : '2px solid orangered',
    }),

    input: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop: '-5px',
    }),
  }


  const navigate = useNavigate();

  const [isEdit, SetEdit] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [partnerType, setPartnerType] = useState(null);
  const [profileDocuments, setProfileDocuments] = useState(null);

  const [cities, setCities] = useState([{ key: 0, value: 0, label: 'None' },]);
  const [states, setStates] = useState([{ key: 0, value: 0, label: 'None' },]);

  const [selectedCityPer, setCityPer] = useState(cities.label);
  const handleSelectCity = e => {
    setCityPer(e.value);
    const optionToSelect = cities.find((option) => option.value === e.value);
    setFilterCityPer(optionToSelect);
  }
  const [filterCityPer, setFilterCityPer] = useState(null);

  const [selectedStatePer, setStatePer] = useState(states.label);
  const handleSelectState = e => {
    setStatePer(e.value);
    const optionToSelect = states.find((option) => option.value === e.value);
    setFilterStatePer(optionToSelect);
  }
  const [filterStatePer, setFilterStatePer] = useState(null);


  const [selectedCityBusi, setCityBusi] = useState(cities.label);
  const handleSelectCity2 = e => {
    removeErrorMessage("busiState");
    setCityBusi(e.value);
    const optionToSelect = cities.find((option) => option.value === e.value);
    setFilterCityBusi(optionToSelect);
  }
  const [filterCityBusi, setFilterCityBusi] = useState(null);

  const [selectedStateBusi, setStateBusi] = useState(states.label);
  const handleSelectState2 = e => {
    removeErrorMessage("busiState");
    setStateBusi(e.value);
    const optionToSelect = states.find((option) => option.value === e.value);
    setFilterStateBusi(optionToSelect);
  }
  const [filterStateBusi, setFilterStateBusi] = useState(null);


  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  // dlpdfRetailer dlpdfWholeSaler regLicencepdf GSTpdf PANpdf FSSAIpdf BANKpdf
  const [dlpdfRetailer, setDL_PDF_Ret] = useState(null);
  const [dlpdfWholeSaler, setDL_PDF_WS] = useState(null);
  const [regLicencepdf, setRegPDF] = useState(null);
  const [GSTpdf, setGSTPDF] = useState(null);
  const [PANpdf, setPanPDF] = useState(null);
  const [FSSAIpdf, setFSSAI_PDF] = useState(null);
  const [BANKpdf, setBankPDF] = useState(null);

  const [isUpdateModelShow, setIsUpdateModelShow] = useState(false);

  const changeFileHandler = (event) => {
    removeErrorMessage(event.target.id);
    const file = event.target.files[0];

    // const preview = document.getElementById('pdfPreview');
    // // Ensure a file is selected
    // if (input.files.length > 0) {
    //   const file = input.files[0];

    //   // Check if the selected file is a PDF
    //   if (file.type === 'application/pdf') {
    //     // Use the URL.createObjectURL to create a temporary URL for the selected file
    //     const pdfUrl = URL.createObjectURL(file);

    //     // Set the source of the iframe to the temporary URL
    //     preview.src = pdfUrl;
    //   } else {
    //     alert('Please select a valid PDF file.');
    //     // Clear the file input to allow the user to choose another file
    //     input.value = '';
    //   }
    // }


    if (!file.type.match('application/pdf')) {
      alert("Please upload only PDF..");
      event.target.value = null;
    }
    else {
      var reader = new FileReader();
      reader.onload = function () {
        if (event.target.id === "dlpdfRetailer") {
          setDL_PDF_Ret(event.target.files[0]);
          var output = document.getElementById('dlRetpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "dlpdfWholeSaler") {
          setDL_PDF_WS(event.target.files[0]);
          var output = document.getElementById('dlWSpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "regLicencepdf") {
          setRegPDF(event.target.files[0]);
          var output = document.getElementById('regpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "GSTpdf") {
          setGSTPDF(event.target.files[0]);
          var output = document.getElementById('gstpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "PANpdf") {
          setPanPDF(event.target.files[0]);
          var output = document.getElementById('panpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "FSSAIpdf") {
          setFSSAI_PDF(event.target.files[0]);
          var output = document.getElementById('fssaipdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "BANKpdf") {
          setBankPDF(event.target.files[0]);
          var output = document.getElementById('bankpdfPreview');
          output.src = reader.result;
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  const updateProfile = () => {
    let formData = new FormData();    //formdata object

    // fname lname gender perAddress1 perAddress2 pincode mobile email busiName busiAddress1 busiAddress2 pincode2 mobile2 email2 stdCode 
    // landline1 landline2 landline3 ctx1 ctx2 ctx3 dlRetailer1 dlRetailer2  dlWholeSaler1 dlWholeSaler2 regLicence
    // gst pan fssai banckAccount bankIFSC dlpdfRetailer dlpdfWholeSaler regLicencepdf GSTpdf PANpdf FSSAIpdf BANKpdf

    // alert("Updating....");

    formData.append('updateProf', 1);
    formData.append('dlpdfRetailer', dlpdfRetailer);
    if (parseInt(partnerType) > 1) {
      formData.append('dlpdfWholeSaler', dlpdfWholeSaler);
      formData.append('dlWholeSaler1', document.getElementById('dlWholeSaler1').value);
      formData.append('dlWholeSaler2', document.getElementById('dlWholeSaler2').value);
    }

    formData.append('regLicencepdf', regLicencepdf);
    formData.append('GSTpdf', GSTpdf);
    formData.append('PANpdf', PANpdf);
    formData.append('FSSAIpdf', FSSAIpdf);
    formData.append('BANKpdf', BANKpdf);
    formData.append('dp_id', ReactSession.get("id"));
    formData.append('name', document.getElementById('fname').value + ' ' + document.getElementById('lname').value);
    formData.append('gender', document.getElementById('gender').value);
    formData.append('perAddress1', document.getElementById('perAddress1').value);
    formData.append('perAddress2', document.getElementById('perAddress2').value);
    formData.append('selectedCityPer', selectedCityPer);
    formData.append('selectedStatePer', selectedStatePer);
    formData.append('pincode', document.getElementById('pincode').value);
    formData.append('mobile', document.getElementById('mobile').value);
    formData.append('email', document.getElementById('email').value);
    formData.append('busiName', document.getElementById('busiName').value);
    formData.append('busiAddress1', document.getElementById('busiAddress1').value);
    formData.append('busiAddress2', document.getElementById('busiAddress2').value);
    formData.append('selectedCityBusi', selectedCityBusi);
    formData.append('selectedStateBusi', selectedStateBusi);
    formData.append('pincode2', document.getElementById('pincode2').value);
    formData.append('mobile2', document.getElementById('mobile2').value);
    formData.append('mobile3', document.getElementById('mobile3').value);
    formData.append('email2', document.getElementById('email2').value);
    formData.append('stdCode', document.getElementById('stdCode').value);
    formData.append('landline1', document.getElementById('landline1').value);
    formData.append('landline2', document.getElementById('landline2').value);
    formData.append('landline3', document.getElementById('landline3').value);
    formData.append('ctx1', document.getElementById('ctx1').value);
    formData.append('ctx2', document.getElementById('ctx3').value);
    formData.append('ctx3', document.getElementById('ctx3').value);
    formData.append('dlRetailer1', document.getElementById('dlRetailer1')?.value);
    formData.append('dlRetailer2', document.getElementById('dlRetailer2')?.value);
    formData.append('regLicence', document.getElementById('regLicence').value);
    formData.append('gst', document.getElementById('gst').value);
    formData.append('pan', document.getElementById('pan').value);
    formData.append('fssai', document.getElementById('fssai').value);
    formData.append('banckAccount', document.getElementById('banckAccount').value);
    formData.append('bankIFSC', document.getElementById('bankIFSC').value);
    formData.append('confirm', document.getElementById('confirm').checked ? "Yes" : "No");

    setLoader(true)
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setIsUpdateModelShow(true);
          ReactSession.set("is_verified", "2");
        }
        else {
          alert("Error updating profile :", result.status);
        }
        setLoader(false);
      }).catch((error) => {
        console.error('Error:', error);
      });

      setLoader(false);
  }


  const verifyProfile = () => {

    var errorElements = [];
    var neccessaryFields = [];
    // neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
    //   "dlWholeSaler1", "dlWholeSaler2", "gst", "regLicence", "pan", "banckAccount", "bankIFSC", "dlpdfRetailer", "dlpdfWholeSaler", "regLicencepdf", "GSTpdf", "PANpdf", "BANKpdf",
    // ]

    if (parseInt(partnerType) === 1) {
      if (profileDocuments !== null)
        neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "mobile3", "email2", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
          "gst", "pan", "banckAccount", "bankIFSC"
        ]
      else
        neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "mobile3", "email2", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
          "gst", "pan", "banckAccount", "bankIFSC", "dlpdfRetailer", "GSTpdf", "PANpdf", "BANKpdf",
        ]
    }
    else
      if (parseInt(partnerType) === 2) {
        if (profileDocuments !== null)
          neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "mobile3", "email2", "pincode2", "stdCode", "landline1",
            "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC",
          ]
        else
          neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "mobile3", "email2", "pincode2", "stdCode", "landline1",
            "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC", "dlpdfWholeSaler", "GSTpdf", "PANpdf", "BANKpdf",
          ]
      }
      else
        if (parseInt(partnerType) === 3) {
          if (profileDocuments !== null)
            neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "mobile3", "email2", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
              "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC",
            ]
          else
            neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "mobile3", "email2", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
              "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC", "dlpdfRetailer", "dlpdfWholeSaler", "GSTpdf", "PANpdf", "BANKpdf",
            ]
        }


    for (let i = 0; i < neccessaryFields.length; i++) {
      if (document.getElementById(neccessaryFields[i]).value === "") {
        errorElements.push(neccessaryFields[i]);
        document.getElementById(neccessaryFields[i]).classList.add("errorField");
      }
    }

    if (selectedCityBusi === null) {
      errorElements.push("busiCity");
    }

    if (selectedStateBusi === null) {
      errorElements.push("busiState");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      if (!document.getElementById('confirm').checked) {
        alert("Please tick checkbox to confirm");
        document.getElementById('confirm').focus();
      }
      else {
        // console.log("Updating Profile....");
        updateProfile();
      }
    }
  }

  const getProfile = () => {
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?getProfile=1&user_Id=' + dp_id;
    setLoader(true)
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      setPartnerType(res.partnerType);
      if (res.status === "success") {
        setProfileData(res.profileData);
        setProfileDocuments(res.profileDocuments);
        setDL_PDF_Ret(`data:application/pdf;base64,${res.profileDocuments[res.profileData[0][41]]}`);
        setDL_PDF_WS(`data:application/pdf;base64,${res.profileDocuments[res.profileData[0][42]]}`);
        setRegPDF(`data:application/pdf;base64,${res.profileDocuments[res.profileData[0][40]]}`);
        setGSTPDF(`data:application/pdf;base64,${res.profileDocuments[res.profileData[0][44]]}`);
        setPanPDF(`data:application/pdf;base64,${res.profileDocuments[res.profileData[0][45]]}`);
        setFSSAI_PDF(`data:application/pdf;base64,${res.profileDocuments[res.profileData[0][43]]}`);
        setBankPDF(`data:application/pdf;base64,${res.profileDocuments[res.profileData[0][50] + '_' + res.profileData[0][51]]}`);
        // const file = new File(res.profileDocuments[res.profileData[0][41]], res.profileData[0][41]);
        // // Set the value of the file input element
        // document.getElementById('dlpdfRetailer').files = [file];
      }
      else {
        SetEdit(true)
      }

      fetch("http://35.154.53.53/citiesAPI", {
        method: 'GET',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }).then((respo) => {
        return respo.json()
      }).then((result) => {
        setCities(result.citiesD);
        setStates(result.statesD);
        if (res.status === "success") {
          const cityD = result.citiesD.find((option) => option.value === res.profileData[0][13]);
          setFilterCityPer(cityD);
          const stateD = result.statesD.find((option) => option.value === res.profileData[0][14]);
          setFilterStatePer(stateD)

          const cityDBusi = result.citiesD.find((option) => option.value === res.profileData[0][25]);
          setFilterCityBusi(cityDBusi);
          const stateDBusi = result.statesD.find((option) => option.value === res.profileData[0][26]);
          setFilterStateBusi(stateDBusi)
          setCityPer(res.profileData[0][13]);
          setStatePer(res.profileData[0][14]);
          setCityBusi(res.profileData[0][25]);
          setStateBusi(res.profileData[0][26]);
        }
      });
      setLoader(false);
    });
  }

  const [menuCollapse, setMenuCollapse] = useState(false);
  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else {
      getProfile();
    }
  }, []);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent' id='DigiDashContent' style={{ fontSize: '90%', backgroundColor: 'white', marginInline: '5%', marginTop: '1%' }}>

          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
            {menuCollapse ?
              <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
              :
              <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
            }
          </div>

          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          <div className='ProfileHeader'>
            <div style={{ fontSize: '200%', fontWeight: 'bold' }}>My Profile</div>
            <div style={{ fontSize: '120%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

              <div id='isEdit' name="isEdit" className='editBox' onClick={() => SetEdit(!isEdit)}>
                <img src={isEdit ? require("../../static/DPImages/check.png") : require("../../static/DPImages/unCheck.png")} />
              </div>

              <label htmlFor="isEdit" style={{ cursor: 'pointer' }}>&nbsp;&nbsp;&nbsp;Edit</label> &nbsp;&nbsp;&nbsp;
              <img className='closeBtn' src={require("../../static/images/Icons/close.png")} alt="" onClick={() => { navigate('/DPDashboard') }} />
            </div>
          </div>

          <div style={{ borderBottom: '2px solid gray', paddingBlock: '2%', paddingTop: '0px' }} id="perDetails">
            <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Proprieters Information</div>
            <div className='dpProInputs'>
              <div className='digiLogin' >
                <input className="digiPartLoginInput" type='text' name="fname" id='fname' placeholder='First Name' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][2].split(' ')[0] : ''} onChange={() => removeErrorMessage("fname")} />
              </div>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="lname" id='lname' placeholder='Last Name' disabled={isEdit ? false : true} defaultValue={profileData ? (profileData[0][2].split(' ').slice(1).join(' ')) : ''} onChange={() => removeErrorMessage("lname")} />
              </div>
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin' >
                <input className="digiPartLoginInput" type='text' name="perAddress1" id='perAddress1' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][9] : ''} placeholder='Address Line 1' />
              </div>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="perAddress2" id='perAddress2' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][10] : ''} placeholder='Address Line 2' />
              </div>
            </div>

            <div className='dpProInputs' style={{ alignItems: 'center' }}>
              <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Select
                  options={cities}
                  id="LocationCity1"
                  // defaultValue={filterCityPer !== null ? filterCityPer :''}
                  value={filterCityPer !== null ? filterCityPer : ''}
                  onChange={handleSelectCity}
                  placeholder="Select City"
                  styles={selectStyle}
                  isDisabled={isEdit ? false : true}
                />

                <Select
                  options={states}
                  id="StateID1"
                  // defaultValue={filStateShip}
                  value={filterStatePer !== null ? filterStatePer : ''}
                  onChange={handleSelectState}
                  placeholder="Select State"
                  styles={selectStyle}
                  isDisabled={isEdit ? false : true}
                />
              </div>

              <div className='digiLogin' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <div className='digiLogin' style={{ width: '30%' }}>
                  <input className="digiPartLoginInput" type='text' name="pincode" id='pincode' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][12] : ''} placeholder='Pincode' />&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                Gender : &nbsp;&nbsp;
                <input type='radio' name="gender" id='gender' value="Male" disabled={isEdit ? false : true} defaultChecked={profileData === null ? true : profileData[0][5] === "Male" ? true : false} />
                <label for="male">Male</label> &nbsp;&nbsp;&nbsp;&nbsp;
                <input type='radio' name="gender" value="Female" disabled={isEdit ? false : true} defaultChecked={profileData === null ? false : profileData[0][5] === "Female" ? true : false} />
                <label for="female">Female</label>
              </div>
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="mobile" id='mobile' maxLength={10} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][6] : ''} placeholder='Personal Mobile' onChange={() => removeErrorMessage("mobile")} />
              </div>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='email' name="email" id='email' placeholder='Valid E-mail id' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][11] : ''} onChange={() => removeErrorMessage("email")} />
              </div>
            </div>
          </div>
          <div id="businessDetails" style={{ borderBottom: '2px solid gray', paddingBlock: '2%' }}>
            <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Business Information</div>
            <div className='digiLogin' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <input className="digiPartLoginInput" type='text' name="busiName" id='busiName' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][17] : ''} placeholder='Full Business Name' onChange={() => removeErrorMessage("busiName")} />
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="busiAddress1" id='busiAddress1' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][21] : ''} placeholder='Business Address Line 1' onChange={() => removeErrorMessage("busiAddress1")} />
              </div>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="busiAddress2" id='busiAddress2' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][22] : ''} placeholder='Business Address Line 2' />
              </div>
            </div>

            <div className='dpProInputs' style={{ alignItems: 'center' }}>
              <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between', }}>
                <div className='busiCity' id='busiCity'>
                  <Select
                    options={cities}
                    id="LocationCity2"
                    // defaultValue={filCityShip}
                    value={filterCityBusi !== null ? filterCityBusi : ''}
                    onChange={handleSelectCity2}
                    placeholder="Select City"
                    styles={selectStyle}
                    isDisabled={isEdit ? false : true}
                  />
                </div>

                <div className='busiState' id='busiState'>
                  <Select
                    options={states}
                    id="StateID2"
                    // defaultValue={filStateShip}
                    value={filterStateBusi !== null ? filterStateBusi : ''}
                    onChange={handleSelectState2}
                    placeholder="Select State"
                    styles={selectStyle}
                    isDisabled={isEdit ? false : true}
                  />
                </div>
              </div>

              <div className='digiLogin' style={{ display: 'flex', justifyContent: '' }}>
                <input className="digiPartLoginInput" style={{ width: '30%' }} type='text' name="pincode2" id='pincode2' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][23] : ''} placeholder='Pincode' onChange={() => removeErrorMessage("pincode2")} />&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>

            {/* vendor_id, user_id, name, name_as_aadhar, dob, gender, mobile, whatsapp_number, aadhar_number, address, address2, email, zipcode, city_id, state_id, post_office, country, business_name, legal_name, business_mobile, busi_email, business_address, business_address2, zipcode2, landmark, busi_city_id, busi_state_id, stdCode, landline_number, landline_number2, landline_number3, CTX1, CTX2, CTX3, partner_type_id, is_working, commission, care_of, business_type, registration_date, registration_license_number, drug_license_number, drug_license_numberWS, fssai_number, gst_number, pancard_number, center_jurisdiction, state_jurisdiction, account_holder_name, bank_name, account_number, IFSC_code, upi_name, upi_id, upi_mobile_number, remark, payout_mode_id, confirm, created_at, updated_date */}

            <div className='dpProInputs'>
              <div className='digiLogin1' >
                <input className="digiPartLoginInput" type='text' name="mobile2" id='mobile2' maxLength={10} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][19] : ''} placeholder='Business Mobile' />
              </div>

              <div className='digiLogin1' >
                <input className="digiPartLoginInput" type='text' name="mobile3" id='mobile3' maxLength={10} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][7] : ''} placeholder='Business WhatsApp' />
              </div>

              <div className='digiLogin' >
                <input className="digiPartLoginInput" type='email' name="email2" id='email2' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][20] : ''} placeholder='Valid Business E-mail id' />
              </div>
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '30%' }}>
                  <input className="digiPartLoginInput" type='text' name="stdCode" id='stdCode' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][27] : ''} placeholder='STD' onChange={() => removeErrorMessage("stdCode")} />
                </div>
                <div className='digiLogin' style={{ width: '65%' }}>
                  <input className="digiPartLoginInput" type='text' name="landline1" id='landline1' maxLength={7} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][28] : ''} placeholder='Landline number 1' onChange={() => removeErrorMessage("landline1")} />
                </div>
              </div>

              <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '49%' }}>
                  <input className="digiPartLoginInput" type='text' name="landline2" id='landline2' maxLength={7} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][29] : ''} placeholder='Landline number 2' />
                </div>
                <div className='digiLogin' style={{ width: '49%' }}>
                  <input className="digiPartLoginInput" type='text' name="landline3" id='landline3' maxLength={7} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][30] : ''} placeholder='Landline number 3' />
                </div>
              </div>
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' >
                  <input className="digiPartLoginInput" type='text' name="ctx1" id='ctx1' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][31] : ''} placeholder='CTX 1' />
                </div>
                <div className='digiLogin' >
                  <input className="digiPartLoginInput" type='text' name="ctx2" id='ctx2' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][32] : ''} placeholder='CTX 2' />
                </div>
              </div>
              <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <input className="digiPartLoginInput" type='text' name="ctx3" id='ctx3' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][33] : ''} placeholder='CTX 3' />
              </div>
            </div>
          </div>

          <div id="businessDocuments" style={{ borderBottom: '2px solid gray', paddingBlock: '2%' }}>
            <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Required Documents</div>

            {partnerType && (parseInt(partnerType) === 1 || parseInt(partnerType) === 3) ?
              <div className='dpProInputs'>
                <div id="retail" className='digiLogin'>
                  <div>Retailers Drug License No </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='digiLogin' style={{ width: '45%' }}>
                      <input className="digiPartLoginInput" type='text' name="dlRetailer1" id='dlRetailer1' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][41]).split('___')[0] : ''} placeholder='Drug License No1' onChange={() => removeErrorMessage("dlRetailer1")} />
                    </div>
                    <div className='digiLogin' style={{ width: '45%' }}>
                      <input className="digiPartLoginInput" type='text' name="dlRetailer2" id='dlRetailer2' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][41]).split('___')[1] : ''} placeholder='Drug License No2' onChange={() => removeErrorMessage("dlRetailer2")} />
                    </div>
                  </div>
                </div>

                <div id="retail2" className='digiLogin'>
                  <div>Attached Document </div>
                  <div style={{ display: 'flex' }}>
                    <input className="digiPartLoginInput" type='file' accept='application/pdf' name="dlpdfRetailer" id='dlpdfRetailer'
                      onChange={changeFileHandler} disabled={isEdit ? false : true} placeholder='Attach PDF DL' />
                    <iframe id="dlRetpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][41]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][41]]}` : ''} ></iframe>
                  </div>
                </div>
              </div>
              : ''
            }

            {partnerType && parseInt(partnerType) > 1 ?
              <div className='dpProInputs'>
                <div id="wholesaler" className='digiLogin'>
                  <div>WholeSalers Drug License No </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='digiLogin' style={{ width: '45%' }}>
                      <input className="digiPartLoginInput" type='text' name="dlWholeSaler1" id='dlWholeSaler1' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][42]).split('___')[0] : ''} placeholder='Drug License No1' onChange={() => removeErrorMessage("dlWholeSaler1")} />
                    </div>
                    <div className='digiLogin' style={{ width: '45%' }}>
                      <input className="digiPartLoginInput" type='text' name="dlWholeSaler2" id='dlWholeSaler2' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][42]).split('___')[1] : ''} placeholder='Drug License No2' onChange={() => removeErrorMessage("dlWholeSaler2")} />
                    </div>
                  </div>
                </div>

                <div id="wholesaler2" className='digiLogin'>
                  <div>Attached Document </div>
                  <div style={{ display: 'flex' }}>
                    <input className="digiPartLoginInput" type='file' accept='application/pdf' name="dlpdfWholeSaler" id='dlpdfWholeSaler' disabled={isEdit ? false : true} placeholder='Attach PDF DL'
                      onChange={changeFileHandler} />
                    <iframe id="dlWSpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][42]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][42]]}` : ''} ></iframe>
                  </div>
                </div>
              </div>
              : ''
            }

            <div className='dpProInputs' style={{ marginTop: '2%' }}>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="regLicence" id='regLicence' placeholder='Establishment Certificate Number' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][40] : ''} onChange={() => removeErrorMessage("regLicence")} />
              </div>
              <div className='digiLogin' style={{ display: 'flex' }}>
                <input className="digiPartLoginInput" type='file' accept='application/pdf' name="regLicencepdf" id='regLicencepdf' placeholder='Attach PDF Registration'
                  onChange={changeFileHandler} disabled={isEdit ? false : true} />
                <iframe id="regpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][40]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][40]]}` : ''}></iframe>
              </div>
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="gst" id='gst' placeholder='GST Number' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][44] : ''} onChange={() => removeErrorMessage("gst")} />
              </div>
              <div className='digiLogin' style={{ display: 'flex' }}>
                <input className="digiPartLoginInput" type='file' accept='application/pdf' name="GSTpdf" id='GSTpdf' placeholder='Attach PDF GST'
                  onChange={changeFileHandler} disabled={isEdit ? false : true} />
                <iframe id="gstpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][44]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][44]]}` : ''}></iframe>
              </div>
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="pan" id='pan' placeholder='PAN Number' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][45] : ''} onChange={() => removeErrorMessage("pan")} />
              </div>
              <div className='digiLogin' style={{ display: 'flex' }}>
                <input className="digiPartLoginInput" type='file' accept='application/pdf' name="PANpdf" id='PANpdf' placeholder='Attach PDF PAN'
                  onChange={changeFileHandler} disabled={isEdit ? false : true} />
                <iframe id="panpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][45]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][45]]}` : ''}></iframe>
              </div>
            </div>

            <div className='dpProInputs'>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="fssai" id='fssai' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][43] : ''} placeholder='FSSAI Number' />
              </div>
              <div className='digiLogin' style={{ display: 'flex' }}>
                <input className="digiPartLoginInput" type='file' accept='application/pdf' name="FSSAIpdf" id='FSSAIpdf' placeholder='Attach PDF FSSAI'
                  onChange={changeFileHandler} disabled={isEdit ? false : true} />
                <iframe id="fssaipdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][43]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][43]]}` : ''}></iframe>
              </div>
            </div>

            <div>
              <div>Bank Details</div>
              <div className='dpProInputs'>

                <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className='digiLogin' style={{ width: '45%' }}>
                    <input className="digiPartLoginInput" type='text' name="banckAccount" id='banckAccount' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][50] : ''} placeholder='Bank A/c Number' onChange={() => removeErrorMessage("banckAccount")} />
                  </div>
                  <div className='digiLogin' style={{ width: '45%', }}>
                    <input className="digiPartLoginInput" type='text' name="bankIFSC" id='bankIFSC' placeholder='BANK IFSC' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][51] : ''} onChange={() => removeErrorMessage("bankIFSC")} />
                  </div>
                </div>

                <div className='digiLogin' style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <input className="digiPartLoginInput" type='file' accept='application/pdf' name="BANKpdf" id='BANKpdf' placeholder='Attach PDF Cancelled CHECQUE'
                    onChange={changeFileHandler} disabled={isEdit ? false : true} />
                  <iframe id="bankpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][50] + '_' + profileData[0][51]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][50] + '_' + profileData[0][51]]}` : ''}></iframe>
                </div>
              </div>
            </div>
          </div>
          {isEdit ?
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '2%' }}>
                <input type='checkbox' name="confirm" id="confirm" style={{ marginTop: '.5%' }} /> &nbsp;&nbsp;
                <label for="confirm">By proceeeding, you are agreeing to share your information with SHEET360 Technology Private Limited. It will remain confidential and used for business transaction purposes only.</label>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='digiNavBtn' style={{ width: '20%', textAlign: 'center' }} onClick={() => verifyProfile()}>Save</div>
              </div>
            </>
            :
            ''}

          {/* model */}

          {
            isUpdateModelShow ?
              <div className="successFullyUpdatedProfileModelCon">
                <div className="successFullyUpdatedProfileModel">
                  <div style={{ borderBottom: "1px solid gray", textAlign: "center", width: "80%", marginBottom: 15 }}>
                    <img src={require("../../static/DPImages/sheet360.png")} alt="" />
                    <h1 style={{ color: "#00BAFF", marginBottom: "20px", fontSize: "53px", marginTop: 10 }}>Thank You</h1>
                    <h5 style={{ color: "gray", fontWeight: "normal" }}>Your documents are being verfied.</h5>
                  </div>

                  <h2>Business / Store under verification</h2>
                  <div>
                    <p style={{ marginTop: 15 }}><img src={require("../../static/DPImages/checkBlue.png")} alt="" /> Business details added</p>
                    <p><img src={require("../../static/DPImages/checkBlue.png")} alt="" /> Store Location added</p>
                    <p><img src={require("../../static/DPImages/pendingRed.png")} alt="" /> KYC documents under verification</p>
                  </div>

                  <button className="okBtn" onClick={()=>setIsUpdateModelShow(false)}>Ok</button>
                </div>
              </div> : ""
          }

        </div>
        {
          loader ? <Loader /> : ''
        }



      </div>



    </>
  )
}

export default DigiPartnerProfile