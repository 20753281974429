import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Loader from '../Containers/Loader';

ReactSession.setStoreType("localStorage");

const DigiPartPersonalization = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [isEdit, SetEdit] = useState(false);
  const [personalizeData, setPersonalizeData] = useState(null);
  const [partnerType, setPartnerType] = useState(null);


  const verifyAndSave = () => {
    document.getElementById("errorMessage").innerHTML = "<div>Red marked fields are mandatory</div>";
    var ErrorElements = [];
    var checkElements = ['minOrdAmtLocal', 'delChargLocal', 'minOrdAmtOtherCities', 'delChargOthersCities', 'expDelCharges',]
    for (let i = 0; i < checkElements.length; i++) {
      var ele = document.getElementById(checkElements[i]);
      if (ele.value == "" || isNaN(ele.value)) {
        ErrorElements.push(checkElements[i]);
        ele.classList.add("errorField");
      }
    }

    var delHrs = document.getElementById('stdDelHours');
    if (delHrs.value.search("-") !== -1) {
      var delHrsSp = delHrs.value.split('-');
      if (delHrsSp.length !== 2 || isNaN(delHrsSp[0]) || isNaN(delHrsSp[1])) {
        ErrorElements.push('stdDelHours');
        delHrs.classList.add("errorField");
        document.getElementById("errorMessage").innerHTML += "<br/>Standard Delivery Hours must be '00-00' format"
      }
    }

    var expDelHrs = document.getElementById('expDelHours');
    if (expDelHrs.value.search("-") !== -1) {
      var delHrsSp = expDelHrs.value.split('-');
      if (delHrsSp.length !== 2 || isNaN(delHrsSp[0]) || isNaN(delHrsSp[1])) {
        ErrorElements.push('expDelHours');
        expDelHrs.classList.add("errorField");
        document.getElementById("errorMessage").innerHTML += "<br/>Express Delivery Hours must be '00-00' format"
      }
    }


    if (ErrorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(ErrorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      setLoader(true);
      fetch('http://35.154.53.53/digiPartnerAPI/', {
        mode: 'cors',
        method: 'PUT',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
          personalization: '0',
          user_Id: ReactSession.get('id'),
          minOrdAmtLocal: document.getElementById('minOrdAmtLocal').value,
          delChargLocal: document.getElementById('delChargLocal').value,
          minOrdAmtOtherCities: document.getElementById('minOrdAmtOtherCities').value,
          delChargOthersCities: document.getElementById('delChargOthersCities').value,
          expDelCharges: document.getElementById('expDelCharges').value,
          stdDelHours: document.getElementById('stdDelHours').value,
          expDelHours: document.getElementById('expDelHours').value
        })
      }).then((response) => response.json())
        .then((resp) => {
          if (resp.status === "validityExpired")
          {
            alert("You have no active subscription plan. please subscribe to continue.");
          }
          
          if (resp.status === "success") {
            SetEdit(false);
            alert("Settings finished successfully");
            getPersonalInfo();
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err.message);
        });

    }
  }

  const getPersonalInfo = () => {
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?getProfile=1&user_Id=' + dp_id;
    setLoader(true)
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      if (res.status === "success") {
        setPartnerType(res.partnerType);
        setPersonalizeData(res.profileData);
      }
      else {
        SetEdit(true)
      }
      setLoader(false);
    });
  }


  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else {
      getPersonalInfo();
    }
  }, []);


  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        { loader ? <Loader/> :'' }
        <div className='DigiDashContent' id='DigiDashContent' style={{ backgroundColor: 'white', margin: '20px' }}>
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          <div className='ProfileHeader'>
            <div style={{ fontSize: '200%', fontWeight: 'bold' }}>Digital Partner Personalization</div>
            <div style={{ fontSize: '120%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

              <div id='isEdit' name="isEdit" className='editBox' onClick={() => SetEdit(!isEdit)}>
                <img src={isEdit ? require("../../static/DPImages/check.png") : require("../../static/DPImages/unCheck.png")} />
              </div>

              <label htmlFor="isEdit" style={{ cursor: 'pointer' }}>&nbsp;&nbsp;&nbsp;Edit</label> &nbsp;&nbsp;&nbsp;
              <img className='closeBtn' src={require("../../static/images/Icons/close.png")} alt="" onClick={() => { navigate('/DPDashboard') }} />
            </div>
          </div>

          <div style={{ borderBottom: '2px solid gray', paddingBlock: '2%', paddingTop: '0px' }} id="perDetails">
            <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Minimum Order Amount & Delivery Charges <br />for local</div>
            <div className='dpProInputs'>
              <div className='digiLogin'>
                Delivery Amount : <input className="digiPartLoginInput" type='text' name="minOrdAmtLocal" id='minOrdAmtLocal' disabled={isEdit ? false : true} defaultValue={personalizeData ? personalizeData[0][64] : ''} onChange={() => removeErrorMessage("minOrdAmtLocal")} />
              </div>
              <div className='digiLogin'>
                Delivery Charge : <input className="digiPartLoginInput" type='text' name="delChargLocal" id='delChargLocal' disabled={isEdit ? false : true} defaultValue={personalizeData ? (personalizeData[0][62]) : ''} onChange={() => removeErrorMessage("delChargLocal")} />
              </div>
            </div>
          </div>

          <div style={{ borderBottom: '2px solid gray', paddingBlock: '2%', paddingTop: '0px' }} id="perDetails">
            <div style={{ fontSize: '110%', fontWeight: 'bold' }}>for other cities</div>
            <div className='dpProInputs'>
              <div className='digiLogin' >
                Delivery Amount : <input className="digiPartLoginInput" type='text' name="minOrdAmtOtherCities" id='minOrdAmtOtherCities' disabled={isEdit ? false : true} defaultValue={personalizeData ? personalizeData[0][65] : ''} onChange={() => removeErrorMessage("minOrdAmtOtherCities")} />
              </div>
              <div className='digiLogin'>
                Delivery Charges :<input className="digiPartLoginInput" type='text' name="delChargOthersCities" id='delChargOthersCities' disabled={isEdit ? false : true} defaultValue={personalizeData ? personalizeData[0][63] : ''} onChange={() => removeErrorMessage("delChargOthersCities")} />
              </div>
            </div>
          </div>

          <div style={{ borderBottom: '2px solid gray', paddingBlock: '2%', paddingTop: '0px' }} id="perDetails">
            <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Delivery Hours</div>
            <div className='dpProInputs'>
              <div className='digiLogin'>
                <b>Standard</b> : <input className="digiPartLoginInput" type='text' name="stdDelHours" id='stdDelHours' disabled={isEdit ? false : true} defaultValue={personalizeData ? personalizeData[0][66] : ''} onChange={() => removeErrorMessage("stdDelHours")} />
              </div>
              <div className='digiLogin'>
                <b>Express Delivery</b> : <input className="digiPartLoginInput" type='text' name="expDelHours" id='expDelHours' disabled={isEdit ? false : true} defaultValue={personalizeData ? (personalizeData[0][67]) : ''} onChange={() => removeErrorMessage("expDelHours")} />
              </div>
            </div>
          </div>

          <div style={{ borderBottom: '2px solid gray', paddingBlock: '2%', paddingTop: '0px' }} id="perDetails">
            <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Express Delivery Charges fro local :</div>
            <div className='dpProInputs'>
              <div className='digiLogin'>
                <input className="digiPartLoginInput" type='text' name="expDelCharges" id='expDelCharges' disabled={isEdit ? false : true} defaultValue={personalizeData ? personalizeData[0][68] : ''} onChange={() => removeErrorMessage("expDelCharges")} />
              </div>
            </div>
          </div>

          {isEdit ?
            <>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='digiNavBtn' style={{ width: '20%', textAlign: 'center' }} onClick={() => verifyAndSave()}>Save</div>
              </div>
            </>
            :
            ''}


        </div>
      </div>
    </>
  )
}

export default DigiPartPersonalization
