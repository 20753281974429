import React, { useEffect, useState } from 'react'
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel'
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar'
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import { FiSearch } from "react-icons/fi";
import { ReactSession } from 'react-client-session';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../Containers/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../DatePicker.css';

ReactSession.setStoreType("localStorage");

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const DigiPartPartyWiseSales = () => {

  const [partyWiseSales, setPartyWiseSales] = useState(null);
  const [selectedParty, setSelectedParty] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [partyDetails, setPartyDetails] = useState(null);
  const [partyTotals, setPartyTotals] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [dateFilter, setDateFilter] = useState(false);

  // Handle Printer
  const printUserStockRef = React.useRef();
  const handlePrintUserStock = useReactToPrint({ content: () => printUserStockRef.current, });
  const [partyWiseSalesPrint, setPartyWiseSalesPrint] = useState(null);

  const [filltered, setFilltered] = useState(false);
  const [Qry, setQry] = useState('');

  const [pagesize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [pageParty, setPageParty] = useState(1);
  const [noofRec, setNoOfRec] = useState(null);
  const [noofSales, setNoOfSales] = useState(null);

  const executeQry = () => {
    setFilltered(!filltered);
    setPage(1);
  }


  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  const handleSearchText = (e) => {
    setQry(e.target.value);
    if (e.key === "Enter") {
      executeQry();
    }
  }

  const prevPage = () => {
    if (partyWiseSales === null)
      if (page > 1)
        setPage(page - 1);
      else
        if (pageParty > 1)
          setPageParty(pageParty - 1);
  }

  const nextPage = () => {
    if (partyWiseSales === null)
      if (page <= parseInt(noofRec / pagesize))
        setPage(page + 1);
      else
        alert("No more data");
    else
      if (pageParty <= parseInt(noofSales / pagesize))
        setPageParty(pageParty + 1);
      else
        alert("No more data");
  }

  const topPage = () => {
    if (partyWiseSales === null)
      if (page > 1)
        setPage(1);
      else
        if (pageParty > 1)
          setPageParty(1);
  }

  const lastPage = () => {
    if (partyWiseSales === null)
      if (page < parseInt(noofRec / pagesize))
        setPage(parseInt(noofRec / pagesize) + 1);
      else
        alert("No more data");
    else
      if (pageParty < parseInt(noofSales / pagesize))
        setPageParty(parseInt(noofSales / pagesize) + 1);
      else
        alert("No more data");
  }


  const getPartyWiseSales = (print = null, dtFrom = null, dtTo = null) => {
    setIsLoading(true);
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?user_Id=' + dp_id + '&getPartyDetails=' + selectedParty[0] + '&page=' + pageParty + '&pagesize=' + pagesize;

    if (print !== null)
      var apiURL = 'http://35.154.53.53/digiPartnerAPI?user_Id=' + dp_id + '&getPartyDetails=' + selectedParty[0];

    if (dtFrom !== null && dtTo !== null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;
    
    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else if (resp.status === "accessError") {
        alert("Your current subscription doesnot Party Wise Details, please contact pillsit sales team.")
      }
      else if (resp.status === "success") {
        if (print === "print") {
          setPartyWiseSalesPrint(resp.customerData);
          setNoOfSales(resp.totals[0][0]);
          setPartyTotals(resp.totals[0]);
        }
        else {
          setPartyWiseSales(resp.customerData);
          setNoOfSales(resp.totals[0][0]);
          setPartyTotals(resp.totals[0]);
        }
      }
      setIsLoading(false);
    });
  }

  // useEffect(()=>{
  //   setPageParty(1);
  //   setstartDate(null);
  //   setendDate(null);
  // }, [filltered]);

  const printPartyWiseSales = () => {
    if (startDate !== null && endDate !== null && endDate > startDate) {
      var dtFrom = (startDate.toLocaleDateString()).split('/');
      var dtTo = (endDate.toLocaleDateString()).split('/');
      var dateFrom = dtFrom[2] + '-' + dtFrom[0] + '-' + dtFrom[1];
      var dateTo = dtTo[2] + '-' + dtTo[0] + '-' + dtTo[1];
      getPartyWiseSales("print", dateFrom, dateTo);
    }
    else {
      getPartyWiseSales("print",);
    }
  }

  useEffect(() => {
    setPageParty(1);
  }, [endDate]);

  useEffect(() => {
    if (selectedParty && selectedParty !== null) {
      if (startDate !== null && endDate !== null && endDate > startDate) {
        var dtFrom = (startDate.toLocaleDateString()).split('/');
        var dtTo = (endDate.toLocaleDateString()).split('/');
        var dateFrom = dtFrom[2] + '-' + dtFrom[0] + '-' + dtFrom[1];
        var dateTo = dtTo[2] + '-' + dtTo[0] + '-' + dtTo[1];
        getPartyWiseSales("screen",dateFrom, dateTo);
      }
      else {
        getPartyWiseSales("screen",);
      }
    }
  }, [pageParty, selectedParty, endDate]);


  const GetPartyDetails = (dtFrom = null, dtTo = null) => {
    setIsLoading(true);
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?user_Id=' + dp_id + '&getPartyDetails=0&page=' + page + '&pagesize=' + pagesize + '&Qry=' + Qry;

    if (dtFrom !== null && dtTo !== null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;

    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else if (resp.status === "accessError") {
        alert("Your current subscription doesnot Party Wise Details, please contact pillsit sales team.")
      }
      else if (resp.status === "success") {
        setPartyDetails(resp.customerData);
        setNoOfRec(resp.noofRec);
      }
      setIsLoading(false);
    });
  }


  useEffect(() => {
    setPage(1);
  }, [filltered]);

  useEffect(() => {
    GetPartyDetails();
  }, [page, filltered]);


  useEffect(() => {
    if (partyWiseSalesPrint !== null) {
      handlePrintUserStock();
    }
  }, [partyWiseSalesPrint]);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent' id='DigiDashContent' style={{ backgroundColor: 'white' }}>
          {isLoading ? <Loader /> : null}

          {
            partyWiseSales && partyWiseSales !== null ?
              <div className='digiPartyWiseSalesCon' style={{ width: "80vw" }}>
                <div className="head" style={{ marginTop: 7, justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={require("../../static/DPImages/back.png")} onClick={() => {
                      setPartyWiseSales(null);
                      setSelectedParty(null);
                    }} style={{ width: 30, height: 30, marginRight: 10, cursor: "pointer" }} alt="" />
                    <h3>Partywise Sales Reports</h3>
                  </div>

                  <div className='selectDate' style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <div className='dateCont'>
                      <div>From&nbsp;:&nbsp;</div>
                      <DatePicker selected={startDate} onChange={(date) => {
                        if (endDate && (new Date(date) > endDate)) {
                          alert("Start date must smaller than End date");
                        }
                        else {
                          setstartDate(new Date(date));
                          setDateFilter(true);
                        }
                      }} />
                    </div>

                    <div className='dateCont'>
                      <div>&nbsp;To&nbsp;:&nbsp;</div>
                      <DatePicker selected={endDate}
                        onChange={(date) => {
                          if (startDate && (new Date(date) < startDate)) {
                            alert("End Date must bigger than start date");
                          }
                          else {
                            setDateFilter(true);
                            setendDate(new Date(date));
                          }
                        }}
                      />
                    </div>
                    <img src={require("../../static/DPImages/calendar.png")} style={{ width: 30, height: 30, cursor: "pointer" }} alt="" />
                  </div>
                </div>

                <div className="headSearch headDetails">
                  <div>
                    <h2 style={{ margin: 0, padding: 0 }}>{selectedParty[1]} {" - ("}{selectedParty[0]}{")"} </h2>
                    <p style={{ margin: 0, padding: 0 }}>{selectedParty[2]}, {selectedParty[3]},{selectedParty[8]}-{selectedParty[7]}</p>
                    <p>{selectedParty[9]}</p>
                    <p style={{ margin: 0, padding: 0 }}>Landline : {selectedParty[12]}-{selectedParty[13]}, Mobile : {selectedParty[4]} , {selectedParty[5]} - Email : amit@gmail.com</p>
                    <p>GST NO : {selectedParty[14]} | DL No {selectedParty[15].split('___')[0]} / {selectedParty[15].split('___')[1]}</p>
                  </div>

                  {partyTotals && partyTotals !== null ?
                    <div className='headDetailsRight'>
                      <button>Total Sales : {Number(partyTotals[2]).toFixed(2)}</button>
                      <h2>Total SKUs : {partyTotals[1]}</h2>
                      <button onClick={() => { printPartyWiseSales(); }}>PRINT</button>
                    </div>
                    :
                    null}
                </div>

                <div className="partyWiseSalesTable">
                  <div className="tableHead">
                    <div className="row">
                      <div className="col-3" style={{ fontWeight: "bold" }}>Name</div>
                      <div className="col-sm" style={{ fontWeight: "bold" }}>MRP</div>
                      <div className="col-sm" style={{ fontWeight: "bold" }}>Expiry</div>
                      <div className="col-sm" style={{ fontWeight: "bold" }}>Batch</div>
                      <div className="col-sm" style={{ fontWeight: "bold" }}>Issue</div>
                      <div className="col-sm" style={{ fontWeight: "bold" }}>Free</div>
                      <div className="col-sm" style={{ fontWeight: "bold" }}>Value</div>
                    </div>
                  </div>

                  {/* records */}

                  <div className="partySalesTableReco customScroll">
                    {
                      partyWiseSales && partyWiseSales !== null ?
                        partyWiseSales.map((sales, index) => {
                          return (
                            <div key={index} className="row">
                              <div className="col-3"> {sales[3]} <br /> <p style={{ color: "gray", fontSize: 14 }}>{sales[5]}</p> </div>
                              <div className="col-sm"> {Number(sales[13]).toFixed(2)} </div>
                              <div className="col-sm"> {sales[8]}-{sales[9]} </div>
                              <div className="col-sm"> {sales[10]} </div>
                              <div className="col-sm"> {sales[6]} </div>
                              <div className="col-sm"> {sales[7]} </div>
                              <div className="col-sm"> {sales[19]} </div>
                            </div>
                          )
                        })
                        : null
                    }
                  </div>
                </div>
              </div>
              :
              <div className='digiPartyWiseSalesCon' >
                <div className="head">
                  <h3>Partywise Reports</h3>
                </div>

                <div className="headSearch">
                  {/* <Select value={selectedOption} onChange={handleChange} options={options} placeholder="Select Location" className='selectReta' /> */}
                  <div className="searchBoxCon">
                    <input type="text" placeholder='Search Here...' onChange={handleSearchText}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          executeQry();
                        }
                      }}
                      value={Qry} />
                    <i><FiSearch /></i>

                    {
                      Qry !== "" ?
                        <Tooltip title="Remove Filter">
                          <img onClick={() => {
                            setQry('');
                            setFilltered(!filltered);
                          }} className="closeBtn" style={{ width: '20px', height: '20px' }} src={require('../../static/DPImages/Icons/filterClear.png')} alt="" />
                        </Tooltip>
                        :
                        null
                    }
                  </div>
                </div>
                <p className='totalRetalers'>Available Retailers ({noofRec}) {">>"}</p>

                <div className="avalRetailers customScroll">
                  {
                    partyDetails && partyDetails !== null ?
                      partyDetails.map((details, index) => {
                        return (
                          <div key={index} className="retailer">
                            <div className="left">
                              <h2 style={{ margin: 0, padding: 0 }}>{details[1]} {" - ("}{details[0]}{")"} </h2>
                              <p style={{ margin: 0, padding: 0 }}>{details[2]}, {details[3]},{details[8]}, {details[9]} - {details[7]}</p>
                              <p style={{ margin: 0, padding: 0 }}>{details[4]} , {details[5]}</p>
                            </div>
                            <div className="right">
                              <button onClick={() => {
                                setSelectedParty(details);
                                // setPartyWiseSales("true");
                              }}>Details</button>
                            </div>
                          </div>
                        )
                      })

                      : null
                  }
                </div>
              </div>
          }

          <div className="prodPagination" style={{ padding: '0px', margin: '0px' }}>
            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', margin: "0 auto" }}>
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
              <input className={"pageNavButton"} size='3' type="text" name="pageNo" id="pageNo" placeholder={partyWiseSales === null ? page : pageParty} />&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
            </div>
          </div>
        </div>

        {/* Print Component */}
        <div ref={printUserStockRef} className='printable-document' >
          {
            partyWiseSalesPrint ?
              partyWiseSalesPrint.map((item, index) => {
                return (
                  <div key={index}>
                    {index % 20 === 0 && index > 9 ?
                      <div style={{ marginLeft: '30%' }}>to be continued on next page......</div>
                      : null
                    }

                    {
                      index % 20 === 0 ?
                        <div className='page-break' style={{ width: '100%', marginTop: '6%' }}>
                          {index === 0 ?
                            <div style={{ display: 'block', textAlign: 'center' }}>

                              <div>
                                <h2 style={{ margin: 0, padding: 0 }}>{selectedParty[1]} {" - ("}{selectedParty[0]}{")"} </h2>
                                <p style={{ margin: 0, padding: 0 }}>{selectedParty[2]}, {selectedParty[3]},{selectedParty[8]}-{selectedParty[7]}, {selectedParty[9]}</p>
                                <p style={{ margin: 0, padding: 0 }}>Landline : {selectedParty[12]}-{selectedParty[13]}, Mobile : {selectedParty[4]} , {selectedParty[5]} - Email : amit@gmail.com, GST NO : {selectedParty[14]} | DL No {selectedParty[15].split('___')[0]} / {selectedParty[15].split('___')[1]}</p>
                              </div>

                              {partyTotals && partyTotals !== null ?
                                <div className='headDetailsRight'>
                                  <h4>Total Sales : {Number(partyTotals[2]).toFixed(2)} - Total SKUs : {partyTotals[1]}</h4>
                                </div>
                                : null}
                            </div>
                            : null}

                          <div className="tableHead">
                            <div className="row">
                              <div className="col-3" style={{ fontWeight: "bold" }}>Name</div>
                              <div className="col-sm" style={{ fontWeight: "bold" }}>MRP</div>
                              <div className="col-sm" style={{ fontWeight: "bold" }}>Expiry</div>
                              <div className="col-sm" style={{ fontWeight: "bold" }}>Batch</div>
                              <div className="col-sm" style={{ fontWeight: "bold" }}>Issue</div>
                              <div className="col-sm" style={{ fontWeight: "bold" }}>Free</div>
                              <div className="col-sm" style={{ fontWeight: "bold" }}>Value</div>
                            </div>
                          </div>

                        </div>
                        : null}

                    {/* <div style={{ width: '99%', display: 'flex', backgroundColor: index % 2 === 0 ? 'lightgray' : 'white', justifyContent: 'flex-start' }}>
                    </div> */}
                    <div key={index} className="row">
                      <div className="col-3"> {item[3]} <br /> <p style={{ color: "gray", fontSize: 14 }}>{item[5]}</p> </div>
                      <div className="col-sm"> {Number(item[13]).toFixed(2)} </div>
                      <div className="col-sm"> {item[8]}-{item[9]} </div>
                      <div className="col-sm"> {item[10]} </div>
                      <div className="col-sm"> {item[6]} </div>
                      <div className="col-sm"> {item[7]} </div>
                      <div className="col-sm"> {item[19]} </div>
                    </div>

                  </div>
                )
              }
              )
              :
              null
          }

          {/* <div style={{ marginInline: '.5%', borderBlock: '1px solid gray', width: '99%', display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ width: '40%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', fontWeight: 'bold' }}>Totals</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}></div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}></div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col1}</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col2}</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col3}</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col4}</div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default DigiPartPartyWiseSales