import React from 'react'
import { Link } from 'react-router-dom'

const DigiPartFooter = () => {
  return (
    <>
      {/* fotter */}

      <div className='digiPartHomeFotter'>
        <div className='fotterLeft'>
          <img src={require("../../../static/DPImages/sheet360.png")} />
          <p>
            India's biggest B2B pharamaceutical platform serves pharmacy outlets and distributors, enabling digital ordering, digital payments and offering maximum benefits. Begin ordering through our cutting-edge software to triple your business growth potential.
          </p>

          <hr />

          <b>Our Social Networks</b>
          <div className='socialNetworksLinksIcons'>
            <img src={require("../../../static/DPImages/linkedin.png")} />
            <img src={require("../../../static/DPImages/youtube.png")} />
            <img src={require("../../../static/DPImages/instagram.png")} />
            <img src={require("../../../static/DPImages/twitter.png")} />
          </div>
        </div>
        <div className='fotterCenter'>
          <b>Helpfull Links</b>
          <Link to='/DigitalPartner'>Home</Link>
          <Link>Contact Us</Link>
          <Link to="/termsCon">Terms & Conditions</Link>
          <Link to="/privacyPolicy">Privacy Policy</Link>
          <Link>Business Partnership</Link>
        </div>
        <div className='fotterRight'>
          <b>Support</b>
          <Link>Call <br />+91-9300859938 | +91- 9425373703</Link>
          <Link>Whatsapp <br /> +91- 9898787852</Link>
          <Link>Email <br /> info@sheet360ERP.com</Link>
        </div>
      </div>

      <div className='copyRightFotter'>
        <p>Copyright 2024. All rights reserved by sheet360 Technology Private Limated</p>
      </div>

    </>
  )
}

export default DigiPartFooter