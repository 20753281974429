import React, { useEffect, useState } from 'react';
import DigiPartNavBar from "../DigitalPartner/DigiPartnerContainers/DigiPartNavBar"
import DigiPartnerSidePanel from "../DigitalPartner/DigiPartnerContainers/DigiPartnerSidePanel"
import Loader from './Loader';
import { ReactSession } from 'react-client-session';
import { Button } from 'react-bootstrap';

ReactSession.setStoreType("localStorage");


const Subscriptions = () => {

  const [isLoading, setIsLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("Pharmacy");
  const [allSubscription, setAllSubscription] = useState(null);

  const getSubscription = (subs_id) => {
    console.log("Getting Subscription : ",subs_id);
    var apiURL = 'http://35.154.53.53/digiPartnerAPI/';
    setIsLoading(true);
    fetch(apiURL, {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        getSubscription:subs_id,
        dp_id: ReactSession.get("id"),
      })
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "success") {
        alert("Your subscription is succesfully done");
      }
      else if (resp.status == "repeated")
      {
        alert("Sorry you are already availed this plan, please choose a different one..");
      }
      setIsLoading(false);
    });
  }
  
  const getAllSubscription = () => {
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?getSubscriptions=1&user_Id=' + ReactSession.get("id");
    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "success") {
        setAllSubscription(resp.subscriptions);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getAllSubscription();
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer subsPlanMainCon'>
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>

        <div className='DigiDashContent manageProducts' id='DigiDashContent' style={{ backgroundColor: 'white', marginTop: 20 }}>
          {isLoading ? <Loader /> : null}

          <section id="SubsPlans" className="SubsPlans-content section-padding">
            <div className="container">
              <div className="subsHead">
                <h2>Subscription Plans</h2>

                <div className="btnsChoosePlanes">
                  <button className={selectedCategory === "Pharmacy" ? "active" : ""} onClick={() => { setSelectedCategory("Pharmacy") }}>Pharmacy</button>
                  <button className={selectedCategory === "Distributer" ? "active" : ""} onClick={() => { setSelectedCategory("Distributer") }}>Wholeseller</button>
                  <button className={selectedCategory === "Retail + Distributer" ? "active" : ""} onClick={() => { setSelectedCategory("Retail + Distributer") }}>Both</button>
                </div>

              </div>
              <div className="row subsPlansCon text-center" style={{ marginTop: 30 }}>

              {
                allSubscription && allSubscription !== null ?
                allSubscription.map((subscription) => {
                  return (
                  <>
                  {subscription[1] === selectedCategory ?
                    <>
                      <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                        <div className="SubsPlans_design" style={{ background: subscription[1] === "Pharmacy" ? "linear-gradient(315deg, #378b29 0%, #ffffff 74%)" : subscription[1] === "Retail + Distributer" ? "#e0e0e0" : null }}>
                          <div className="single-SubsPlans">
                            <div className="price-head">
                              {subscription[23] === 0 ? <h2>{subscription[2]}</h2> : <h2 style={{ fontWeight: "bold", fontSize: 30 }}>{subscription[2]}</h2>}
                              {subscription[23] === 0 ? <h1 className='price'>{"INR " + subscription[23] + ".00 "} <span>for {subscription[22]} days</span></h1> : null}
                            </div>
                            <ul className='customScroll' style={{}}>
                              <li>
                                {String(subscription[3]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                }
                                Dashboard
                              </li>
                              
                              <li><img src={require("../../static/DPImages/right.png")} alt="" /> <b>{subscription[4]}</b> System Access</li>
                              
                              <li>
                                {String(subscription[5]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Billing Software
                              </li>
                              
                              <li>
                                {String(subscription[6]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Local Application Visibility
                              </li>
                              
                              <li>
                                {String(subscription[7]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Pan India Application Visibility
                              </li>
                              
                              <li>
                                {String(subscription[8]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                }
                                Local Store Visibility
                              </li>
                              
                              <li>{String(subscription[9]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Local User Orders
                              </li>
                              
                              <li>
                                {String(subscription[10]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Local Pharmacy Orders
                              </li>
                              
                              <li>
                                {String(subscription[11]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Local Order Pick & Delivery
                              </li>
                              
                              <li>
                                {String(subscription[12]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Pan India Store Visibility
                              
                              </li>
                              
                              <li>
                                {String(subscription[13]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Pan India User Orders
                              </li>
                              
                              <li>
                                {String(subscription[14]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Pan India Pharmacy Orders
                              </li>
                              
                              <li>
                                {String(subscription[15]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Pan India Orders Pick & Delivery
                              </li>

                              <li>
                                {String(subscription[16]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Sales & Stock Statements
                              </li>
                              
                              <li>{String(subscription[17]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Expiry Management
                              </li>
                              
                              <li>{String(subscription[18]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Non Movable Product Management
                              </li>
                              
                              <li>
                                {String(subscription[19]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Transactions
                              </li>

                              <li>{String(subscription[20]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Profit & Loss, Ledgers, CA Report
                              </li>
                              
                              <li>{String(subscription[21]) === "1" ?
                                  <img src={require("../../static/DPImages/right.png")} alt="" />
                                  :
                                  <img src={require("../../static/DPImages/cross.png")} alt="" />
                                } Customer Support
                              </li>
                            </ul>
                            <div className="SubsPlans-price">
                            </div>
                            <Button className="btnBlue priceBtn" onClick={()=>{
                              getSubscription(subscription[0]);
                              }} style={{ background: subscription[23] === 0 ? "#129fd3" : null }}> {subscription[23] === 0 ? "Start For Free" : "INR " + subscription[23] + ".00 (for " + subscription[22] + " Days)"} </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  : null}
                  </>
                  )
                })
                : null
              }

              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;