import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom'
import { ReactSession } from 'react-client-session';
import { LuSearch } from 'react-icons/lu';

const ViewAllOrders = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const orderType = queryParameters.get("orderType");

  const [custOrders, setCustOrders] = useState(null);
  const [noofRec, setNoofRec] = useState(0);
  const [page, setPage] = useState(1);;

  const navigate = useNavigate();
  const [pagesize, setPageSize] = useState(25);

  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    // console.log("Revious Clicked :" + page);
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    // console.log("Next Clicked ...");
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }

  const searchOrder = () => {
    var searchText = document.getElementById("sertext");

    if (searchText.value.length > 1) {
      setTimeout(
        function () {
          // fetch("http://35.154.53.53/selectProduct?sertext=" + searchText.value, {
          //     method: 'GET',
          //     headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          // }).then((response) => {
          //     return response.json();
          // }).then((resp) => {
          //     createRoot(ProductContainer).render(<ListProducts myLists={resp} />);
          // });
        }, 2000)
    }
  }

  const getAllOrders = () => {

    fetch("http://35.154.53.53/searchProAPI?orderType=" + orderType + "&page=" + page + "&pagesize=" + pagesize, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      document.getElementById('pageNo').placeholder = page;
      setNoofRec(res.noofRec);
      setCustOrders(res.ordersPend);
    });
    return false;
  }

  useEffect(() => {
    if (!ReactSession.get('adminpillsit')) {
      navigate('/Admin');
    }
    else {
      getAllOrders();
    }
  }, [page]);

  return (
    <div className='Orders'>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          <div className='pendOrders' style={{ width: '100%', zIndex: '1', position: "unset" }}>
            <Row style={{ flexWrap: "nowrap", paddingTop: "10px", marginRight: 14, padding: "10px 0px", marginTop: 10 }}>
              <Col style={{ width: "35%" }} xl={8} sm={6} xs={6}>
                <h3 style={{ fontSize: 30, fontWeight: 600, marginLeft: 20 }}>Orders List</h3>
              </Col>
              <Col style={{ position: "relative", paddingLeft: 0, paddingRight: 0, marginBottom: 5, display: "flex", alignItems: "center", background: "#E8E8E8", height: "fit-content", marginRight: "11%", borderRadius: 10, float: "right" }} md={5} lg={4} xl={4} sm={6} xs={6}>
                <input placeholder='Search by Category Name...' style={{ width: '75%', background: "#E8E8E8", padding: "10px", flex: 1, border: 0, outline: 0, borderRadius: 10 }} type='text' name="sertext" id="sertext" onChange={() => { searchOrder() }} />
                <i style={{ position: "absolute", fontSize: 20, display: "flex", alignItems: "center", justifyContent: "center", right: 0, top: 0, width: 70, minHeight: "100%", background: "#000", color: "#fff", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}><LuSearch /></i>
              </Col>
              <div style={{ display: "flex", alignItems: "center", gap: 10, marginLeft: 20 }}>
                <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "6%" }} />
                <span style={{ margin: 0, padding: 0 }}>-</span>
                <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "6%" }} />
                <img src={require("../../static/DPImages/calendar.png")} style={{ width: 25, height: 25, cursor: "pointer" }} alt="" />
              </div>
            </Row>

            <Row className="getDataHeader" style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
              <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                User Details
              </Col>
              <Col md={2} lg={2} xl={2} sm={6} xs={6}>
                Order ID
              </Col>
              <Col md={1} lg={1} xl={1} sm={6} xs={6}>
                Quantity
              </Col>
              <Col md={2} lg={2} xl={2} sm={6} xs={6}>
                Amount
              </Col>
              <Col md={2} lg={2} xl={2} sm={6} xs={6}>
                Date
              </Col>
              <Col md={2} lg={2} xl={2} sm={6} xs={6}>
                Action
              </Col>
            </Row>
          </div>
          <div className='allPenOrders customScroll' id='pendOrders' style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
            {custOrders ?
              custOrders.map((order, index) => {
                var ordDate = order[4] !== null ? (order[4]).substring(0, 10).split("-") : ['', '', ''];

                return <>
                  <Row className='orderRows'>
                    <Col style={{ marginLeft: 0 }} md={3} lg={3} xl={3} sm={6} xs={6}>
                      <p style={{ marginTop: '-.1rem', marginBottom: '0px', color: "#000", fontSize: "16px" }}>{order[0]}</p>
                      <p style={{ marginTop: '-.1rem', marginBottom: '0px' }}>{order[1]}</p>
                      <p style={{ marginTop: '-.1rem', marginBottom: '0px' }}>{order[2]}</p>
                    </Col>
                    <Col style={{ textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6}>
                      {order[3]}
                    </Col>
                    <Col style={{ textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6}>
                      {order[6]}
                    </Col>
                    <Col style={{ textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6}>
                      {(order[7]).toFixed(2)}
                    </Col>
                    <Col style={{ textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                      {ordDate[2]}/{ordDate[1]}/{ordDate[0]}
                    </Col>
                    <Col style={{ textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                      <div className="OrderPagination" style={{ marginBottom: '0px', marginTop: '.5rem', fontSize: '115%' }}><button onClick={() => { navigate('/DispOrderDetailsAdmin?orderId=' + order[3]) }}>View Order</button></div>
                    </Col>
                  </Row>
                </>
              })
              : ''
            }
          </div>
          <div className="OrderPagination prodPagination" style={{ fontSize: '115%', background: "#fff", marginTop: '.3rem', marginBottom: '.3rem', width: "96%", margin: "0px auto", marginLeft: 18, padding: "10px 0px" }}>
            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', margin: "0 auto", height: "60px" }}>
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
              <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default ViewAllOrders