import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import { components } from 'react-select';
import Loader from '../Containers/Loader';
import defProdImage from '../../static/DPImages/productImg.png';
import Parser from 'html-react-parser';
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import ReactImageMagnify from 'react-image-magnify';

ReactSession.setStoreType("localStorage");

const SearchMedVenAvl = () => {
  const navigate = useNavigate();
  const [serCat, setSerCat] = useState(0);
  const [loader, setLoader] = useState(false);

  const [orderCompleteMessage, setOrderCompleteMessage] = useState("");
  const [avalVendors, setAvalVendors] = useState(0);
  const [isSubstitutesPageShow, setIsSubstitutesPageShow] = useState(false);

  // model
  const [showModel, setShowModel] = useState(false);

  const [fillVendorWiseProdAvl, setFillVendorWiseProdAvl] = useState(null);
  const [vendorWiseProdImages, setVendorWiseProdImages] = useState({ "": "" });
  const [vendorWiseProdAvl, setVendorWiseProdAvl] = useState(null);
  const [availableVendor, setAvailableVendor] = useState(null);
  const [fillAvailableVendor, setFillAvailableVendor] = useState(null);
  const [digiCartItems, setDigiCartItems] = useState(null);
  const [cartOrderComp, setCartOrderComp] = useState(null);
  const [totals, setTotals] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [QRCode, setQRCode] = useState(null);
  const [trendProducts, setTrendProducts] = useState(null);
  const [trendFiles, setTrendFiles] = useState(null);


  const [productsData, setProductsData] = useState(null);

  const [isSaltInformationPageShow, setIsSaltInformationPageShow] = useState(false);

  const [stockCities, setStockCities] = useState([{ key: 0, value: 0, label: 'None' },]);
  const [selectedCityStock, setCityStock] = useState("all");
  const handleSelectCity = e => {
    if (serCat === 0) {
      var sertext = document.getElementById("searchVenProd").value;
      if (sertext !== null && sertext.length > 2 && String(e.label).toLowerCase !== "all") {
        const filteredData = availableVendor.filter(userDa => userDa[1]?.toLowerCase().includes(sertext.toLowerCase()) && userDa[2]?.toLowerCase().includes(String(e.label).toLowerCase()));
        setFillAvailableVendor(filteredData);
      }
      else
        if (e.value >= 0) {
          const filteredData = availableVendor.filter(userDa => userDa[2]?.toLowerCase().includes(String(e.label).toLowerCase()));
          setFillAvailableVendor(filteredData);
        }
        else {
          const filteredData = availableVendor.filter(userDa => userDa[1]?.toLowerCase().includes(sertext.toLowerCase()));
          // setFillAvailableVendor(availableVendor);
          setFillAvailableVendor(filteredData);
        }
    }
    setCityStock(e.label);
  }

  const customStyles = {

    options: (baseStyles, state) => ({
      ...baseStyles,
      width: '6vw',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '.8vw',
      background: "white",
      border: '1px solid gray',
      minHeight: '1vw',
      lineHeight: '.5vw',

      "@media (max-width: 512px)": {
        fontSize: '3vw',
        lineHeight: '3vw',
      }

      // padding:'1vw',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      border: '1px solid black',
      borderRadius: '0px',
      fontSize: '1vw',
      minHeight: '.8vw',

      "@media (max-width: 512px)": {
        height: '6vw',
        fontSize: '3vw',
      }

      // width: '7vw',
      // paddingTop: '-20px',
      // marginTop: '.4rem',
    }),

    input: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // paddingTop:'2vw',
      // marginTop:'2vw',
      // paddingBlockEnd:'10px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      // marginTop: '-.1vw',

      "@media (max-width: 512px)": {
        marginTop: '-6%',
      }
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      marginTop: -5,
      width: 36,  // Set the desired width
      height: 36, // Set the desired height
      // Add any other styles you want for the dropdown indicator
      "@media (max-width: 512px)": {
        marginTop: '-10',
      }
    }),

  };


  const CustomMenu = props => {
    const maxHeight = 100; // Set your desired maximum height
    return (
      <components.Menu {...props}>
        <div style={{ maxHeight, overflowY: 'auto' }}>
          {props.children}
        </div>
      </components.Menu>
    );
  };


  const toggleDiv = (event) => {
    let allElems = document.querySelectorAll("#stockist_comp");
    let allElemsArr = Array.from(allElems);
    
    // console.log(allElemsArr)
    allElemsArr.forEach((e, i) => {
      e.style.height = "60px";
    })

    event.target.previousElementSibling.style.height = 'auto';
    if (event.target.nextElementSibling != null) {
      event.target.nextElementSibling.classList.toggle("hide");
    }
    if (event.target.id === "less") {
      event.target.previousElementSibling.classList.toggle("hide");
      // event.target.previousElementSibling.previousElementSibling.style.height = serCat === 0 ? '60px' : '30px';
    }
    event.target.classList.toggle("hide");
  }


  function clearSearch() {
    var ser = document.getElementById("searchVenProd");
    if (ser !== null) {
      ser.value = '';
    }
    document.getElementById("cleanSearch").classList.replace("show", "hide");
    document.getElementById("searchProList").classList.replace("show", "hide");
  }

  function populateSearch() {
    var searchText = document.getElementById("searchVenProd");
    var ProductContainer = document.getElementById("searchProList");
    document.getElementById("cleanSearch").classList.replace("hide", "show");
    if (searchText.value.length > 2) {
      var serVenMedURL = "http://35.154.53.53/selectProduct?sertext=" + searchText.value
      setTimeout(
        function () {
          ProductContainer.classList.replace("hide", "show");
          fetch(serVenMedURL, {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            if (resp.status === "validityExpired")
            {
              alert("You have no active subscription plan. please subscribe to continue.");
            }
            else
            {
              setProductsData(resp.data);
            }
          });
        }, 2000)
    }
    else {
      ProductContainer.classList.replace("show", "hide");
    }
  }

  const getQRCode = () => {
    setLoader(true);
    var apiURL = "http://35.154.53.53/digiPartnerAPI?getQRCode=1&user_Id=" + ReactSession.get("id");
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }

      if (res.status === "success") {
        setQRCode(res.qrCode);
        document.getElementById('Dialogue').classList.remove("hide");
      }
      else {
        // console.log("Already Exist");
        continueWithOrder();
      }
      setLoader(false);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  const continueWithOrder = () => {
    document.getElementById('Dialogue').classList.add("hide");
    setQRCode(null);
    createOrder();
  }

  const createOrder = () => {
    setLoader(true);
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        digiPartCreateOrder: 1,
        dp_id: ReactSession.get("id"),
        cartTotals: totals,
        // digiOrderItems: digiCartItems,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "validityExpired")
        {
          alert("You have no active subscription plan. please subscribe to continue.");
        }

        if (data.status === 'success') {
          setLoader(false);
          setShowModel(true);
          document.getElementById("successMessage").innerHTML = orderCompleteMessage;
          window.scrollTo(0, 0);
        }
        else {
          setLoader(false);
          alert("Error Placing Orders");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const deleteCartItem = (cartItemId) => {
    setLoader(true);
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'DELETE',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        digiPartCartItemId: cartItemId,
        user_Id: ReactSession.get("id"),
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "validityExpired")
        {
          alert("You have no active subscription plan. please subscribe to continue.");
        }
          
        if (data.status === 'success') {
          getDigiCartItems();
          setLoader(false);
        }
        else {
          setLoader(false);
          alert("Error Deleting Ledgers");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const getDigiCartItems = () => {
    setLoader(true);
    var apiURL = "http://35.154.53.53/digiPartnerAPI?digiPartCartItems=1&user_Id=" + ReactSession.get("id");
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }

      if (res.status === "success") {
        // console.log("Trend Prod Length :", res.trendingProducts.length);
        setTrendFiles(res.trendfiles);
        setTrendProducts(res.trendingProducts);
        if (res.digiPartCartItems.length > 0) {
          setDigiCartItems(res.digiPartCartItems);
          setCartOrderComp(res.Companies);
          setTotals([res.MRP, res.PTR, res.QTY]);
        }
        else {
          setDigiCartItems(null);
        }

        var ordSucDetMSG = ""
        for (var i = 0; i < res.Companies.length; i++) {
          ordSucDetMSG += (res.Companies[i].split('___')[0] + ' - ');
          var tot = 0;
          var itmCo = 0;
          for (var j = 0; j < res.digiPartCartItems.length; j++) {
            if (res.Companies[i].split('___')[1] === res.digiPartCartItems[j][11]) {
              itmCo += 1;
              tot += (parseFloat(res.digiPartCartItems[j][7]) * parseFloat(res.digiPartCartItems[j][9]))
            }
          }
          ordSucDetMSG += (String(Number(tot).toFixed(2)) + " (" + String(itmCo) + " Items) </br>");
        }
        setOrderCompleteMessage(ordSucDetMSG);
      }
      setLoader(false);
    }).catch((error) => {
      console.error('Error:', error);
      setLoader(false);
    });
  }


  const [prodImage, setProdImage] = useState(null);
  const [prodGallery, setProdGallery] = useState(null);
  const [galleryData, setGalleryData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [prodData, setProdData] = useState(null);
  const [prodRatings, setProdRatings] = useState(null);
  const [prodSubstitute, setProdSubstitute] = useState(null);

  //Call API to tetreive product details
  const getProductDetails = (searchItem = null) => {
    
    fetch("http://35.154.53.53/selectProduct?pro_id=" + searchItem + "&all=1", {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      if (res.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else
      {
        setProdImage(res.image);
        setProdGallery(res.gallery);
        setGalleryData(res.galleryData);
        setCategories(res.categories);
        setProdData(res.data);
        setProdRatings(res.ratings);
        setProdSubstitute(res.substitutes);
      }
    });
  }


  const getVendorWiseProd = (searchItem = null) => {
    // console.log("getVendorWiseProd executed");
    var apiURL = ''
    if (serCat === 0) { apiURL = 'http://35.154.53.53/digiPartnerAPI?vendProdAvail=0&user_Id=' + ReactSession.get("id"); }
    else { apiURL = 'http://35.154.53.53/digiPartnerAPI?vendProdAvail=1&searchItem=' + searchItem + "&user_Id=" + ReactSession.get("id"); }

    if (selectedCityStock != null)
      apiURL += "&stockCity=" + selectedCityStock;

    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }

      if (resp.vendProdData.length > 0) {
        if (serCat === 0) {
          setAvailableVendor(resp.vendProdData);
          setFillAvailableVendor(resp.vendProdData);
        }
        else
          setVendorWiseProdAvl(resp.vendProdData);

        document.getElementById("availability").innerHTML = "Available Vendors (" + String(resp.vendProdData.length) + ") >>";
        setAvalVendors(resp.vendProdData.length)
      }
      else {
        setAvailableVendor(null);
        setVendorWiseProdAvl(null);
        document.getElementById("availability").innerHTML = "No suitable match found...!";
      }
      getProductDetails(searchItem);
      setLoader(false);
    }).catch((err) => {
      setLoader(false);
      console.log(err.message);
    });
  }


  const addToDPCarts = (cartItem, qty) => {
    setLoader(true);
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        digiCart: 1,
        dp_id: ReactSession.get("id"),
        prod_id: cartItem[1],
        cartStockID: cartItem[20],
        quantity: qty,
        distType: cartItem[2] === "batch_no" ? 1 : 0,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "validityExpired")
        {
          alert("You have no active subscription plan. please subscribe to continue.");
        }

        if (data.status === 'success') {
          getDigiCartItems();
        }
        else
          alert("Error Creating Voucher");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const searchProdVendorWise = (selVend) => {
    setSelectedVendor(selVend);
    // console.log("searchProdVendorWise Executing : ");
    // setSerCat(1);
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?vendProdAvail=3&user_Id=' + ReactSession.get("id") + '&distType=' + selVend[12] + '&distID=' + selVend[0];
    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }

      // console.log("Length : ", resp.vendProdData.length);
      if (resp.vendProdData.length > 0) {
        const prod = resp.vendProdData.filter(pro => pro[1] !== null)
        // console.log("Filterd :", prod.length);
        setVendorWiseProdAvl(prod);
        setFillVendorWiseProdAvl(prod);
        setVendorWiseProdImages(resp.images);
        document.getElementById("availability").innerHTML = "Available Products (" + String(prod.length) + ") >>";
      }
      else {
        // console.log("Else Running");
        setAvailableVendor(null);
        setFillVendorWiseProdAvl(null);
        setVendorWiseProdAvl(null);
        document.getElementById("availability").innerHTML = "No suitable match found...!";
      }
      setLoader(false);
    }).catch((err) => {
      setLoader(false);
      console.log(err.message);
    });
  }


  const getStockistCities = () => {
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?getStockistCities=1&user_Id=' + ReactSession.get("id");
    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else
      {
        setStockCities(res.stockCities);
      }
      setLoader(false);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }

  
  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          getDigiCartItems();
          getVendorWiseProd();
          getStockistCities();
        }

  }, [serCat]);


  const [isProductImagesModalShow, setIsProductImagesModalShow] = useState(false);
  const [productSelectedImage, setProductSelectedImage] = useState("https://png.pngtree.com/element_pic/00/16/06/085757dcf4ce490.jpg");

  const tempData = [
    "https://img.lovepik.com/free-png/20211106/lovepik-skin-care-products-png-image_400420780_wh1200.png",
    "https://static.vecteezy.com/system/resources/previews/018/742/214/non_2x/3d-minimal-product-delivery-parcels-transportation-goods-distribution-cargos-preparation-for-sending-cardbox-with-a-wing-3d-rendering-illustration-free-png.png",
    "https://atlas-content-cdn.pixelsquid.com/assets_v2/270/2704723876906014401/previews/G03-200x200.jpg",
    "https://png.pngtree.com/element_pic/00/16/06/085757dcf4ce490.jpg",
    "https://png.pngtree.com/element_pic/00/16/06/085757dcf4ce490.jpg",
    "https://png.pngtree.com/element_pic/00/16/06/085757dcf4ce490.jpg",
  ]


  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >

        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>

        <div className='DigiDashContent' id='DigiDashContent' style={{ backgroundColor: 'white' }}>
          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
            {menuCollapse ?
              <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
              :
              <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
            }
          </div>

          {/* <div style={{ width: '90%', marginTop: '1%', display: 'flex', justifyContent:'flex-start' }}>
            <div style={{ width: '10%' }}>
              <img style={{ width: '30%' }} src={require('../../static/images/Icons/Notifications.png')} alt="" />
            </div>
            <div style={{ width: '50%', fontSize: '130%', fontStyle: 'italic', fontWeight: 'bold' }}>
              Looking for....
            </div>
            <div style={{ width: '40%', display: 'flex', justifyContent: 'flex-end', }}>
              <img style={{ width: '30%' }} src={require('../../static/DPImages/Icons/Sheet360.png')} alt="" />
            </div>
          </div> */}

          <div className='searchVendHeader'>
            <div className='searchVendHeaderCol1'>
              <div style={{ display: 'flex' }}>
                {
                  selectedVendor && selectedVendor !== null ?
                    <>
                      {/* marginBottom:selectedVendor[1].length >= 20 ? "10px" :" 0px",marginTop:selectedVendor[1].length >= 20 ? "10px" :" 0px",padding:selectedVendor[1].length >= 20 ? "20px 0px" :" 0px" */}
                      <div className='dpDashSearchProductSelected' style={{ marginBottom: selectedVendor[1].length >= 20 ? "10px" : " 0px", marginTop: selectedVendor[1].length >= 20 ? "10px" : " 0px" }}>
                        <span className='dpDashSearchProductSelectedText' style={{}}>{selectedVendor[1]} | {selectedVendor[2]}</span>
                        <Tooltip title="Remove">
                          <button className='ProdCol' style={{ marginTop: '0' }} onClick={() => {
                            setSelectedVendor(null)
                            setSerCat(0);
                          }} >
                            <i className="ri-close-line" style={{ color: "red", fontSize: "25px", fontWeight: "bold" }}></i>
                            {/* <img src={require('../../static/images/Icons/close.png')} alt="" /> */}
                          </button>
                        </Tooltip>
                      </div>
                    </>
                    :
                    <>
                      <div onClick={() => { setSerCat(0); document.getElementById('searchVenProd').value = ''; }} className='serMedVenAvlRadio'>
                        <div className='searchRadioBTN' style={{ backgroundColor: serCat === 0 ? "rgb(44, 156, 247)" : "gray" }}></div>
                        <div style={{ marginInline: '2%' }}>Vendor</div>
                      </div>

                      <div onClick={() => { setSerCat(1); document.getElementById('searchVenProd').value = '' }} className='serMedVenAvlRadio'>
                        <div className='searchRadioBTN' style={{ marginInline: '2%', backgroundColor: serCat === 1 ? "rgb(44, 156, 247)" : "gray" }}></div>
                        <div>Products</div>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '40%' }}>
                        <img style={{ width: '8%', height: '65%' }} src={require('../../static/images/Icons/location1.png')} alt="" />
                        <div style={{ width: '100%', marginInline: '3%' }}>
                          {/* <div style={{ fontSize: '80%' }}>Location</div> */}
                          <Select options={stockCities} id="cat_idDD"
                            components={{ Menu: CustomMenu }}
                            menuPortalTarget={document.body}
                            styles={customStyles}
                            onChange={handleSelectCity}
                          />
                        </div>
                        {/* <div> <img style={{width:'35%'}} src ={require('../../static/images/Icons/down_arrow.png')} alt=""/></div> */}
                      </div>
                    </>
                }
              </div>
            </div>

            <div className='searchVendHeaderCol2'>
              <div className='serMedVenAvlRadio'>
                <div>My Cart</div>
              </div>
            </div>
          </div>

          <div className='searchSelectProduct'>
            <div className='searchSelectProductCol1'>
              <div className="DPsearch" style={{ justifyContent: 'space-between' }} >
                <input type="text" placeholder={serCat === 0 ? "Search Vendors by Name...!" : "Search Products by Name...!"} name="searchVenProd" id="searchVenProd" autoComplete="off"
                  onKeyDown={(e) => {
                    if (selectedVendor !== null) {
                      const filData = vendorWiseProdAvl?.filter(data =>
                        data[10]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                        data[11]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                        data[12]?.toLowerCase().includes(e.target.value?.toLowerCase())
                      );
                      setFillVendorWiseProdAvl(filData);
                    }
                    else {
                      if (serCat === 0) {
                        var sertext = document.getElementById("searchVenProd").value;
                        if (sertext !== null && (selectedCityStock === null || selectedCityStock?.toLowerCase() === "all")) {
                          const filteredData = availableVendor?.filter(userDa =>
                            userDa[1]?.toLowerCase().includes(sertext.toLowerCase())
                          );
                          setFillAvailableVendor(filteredData);
                        }
                        else
                          if (sertext !== null) {
                            const filteredData = availableVendor.filter(userDa =>
                              userDa[1]?.toLowerCase().includes(sertext.toLowerCase()) &&
                              userDa[2]?.toLowerCase().includes(selectedCityStock?.toLowerCase())
                            );
                            setFillAvailableVendor(filteredData);
                          }
                      }
                      else {
                        populateSearch();
                        if (e.key === "Enter" || e.key === "ArrowDown") {
                          const searchPopup = document.getElementById("searchProList");
                          if (searchPopup.firstChild) {
                            searchPopup.firstChild.focus();
                          }
                        }
                      }
                    }
                  }}
                />

                <div className="cleanSearch hide" id="cleanSearch" onClick={clearSearch}><img src={require("../../static/images/Icons/close.png")} alt="" /></div>

                <div className="searchProList divshadow hide" id="searchProList">
                  {
                    productsData ?
                      productsData.map((product) =>
                        <div className='searchResModal topPartition' tabIndex={1} style={{ padding: '0px' }}
                          onClick={() => {
                            getVendorWiseProd(product[0]);
                            document.getElementById("searchProList").classList.replace("show", "hide");
                          }}

                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              getVendorWiseProd(product[0]);
                              document.getElementById("searchProList").classList.replace("show", "hide");
                            }
                            else
                              if (e.key === "ArrowUp") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.previousElementSibling) {
                                  focusedInput.previousElementSibling.focus();
                                }
                              }
                              else
                                if (e.key === "ArrowDown") {
                                  const focusedInput = document.activeElement;
                                  if (focusedInput.nextElementSibling) {
                                    focusedInput.nextElementSibling.focus();
                                  }
                                }
                          }}>
                          <div style={{ display: 'block', paddingInline: '.5%' }}>
                            <div style={{ fontSize: '1rem', lineHeight: '1.25rem' }} >{product[1]}</div>
                            <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{product[2]}</div>
                            <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{product[8]}</div>
                            <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} > MRP : {product[5]} </div>
                          </div>
                        </div>
                      )
                      : ''
                  }
                </div>
                <button style={{ backgroundColor: "black" }} type="submit"><i className="fa fa-search"></i></button>
              </div>

              {
                vendorWiseProdAvl && serCat === 1 && selectedVendor === null ?
                  <div style={{ width: '100%', borderBottom: '2px solid gray', paddingBlock: '1%', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                      <div style={{ fontSize: '130%', fontWeight: 'bold' }}>{vendorWiseProdAvl[0][10]}</div>
                      <div style={{ fontSize: '13px', color: "gray" }}>{vendorWiseProdAvl[0][11]} | {vendorWiseProdAvl[0][12]}</div>
                      <div style={{ fontSize: '13px', color: "gray" }}>Estimated MRP : {vendorWiseProdAvl[0][6]} | Estimated PTR : N/A</div>
                    </div>
                    <div className='searchMEdVanProdHeadRight' style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-end", textAlign: "right" }}>

                      {
                        prodData[0][14] ?
                          <h3 style={{ fontSize: 12, color: "#2C9CF7", marginRight: -15 }} onClick={() => {
                            setIsSubstitutesPageShow(false);
                            setIsSaltInformationPageShow(true);
                          }}>
                            Salt Information <br /> <p style={{ color: "gray" }} >{prodData[0][14]?.length <= 35 ? `${prodData[0][14]}` : `${prodData[0][14]?.substring(0, 33)}...`} </p>
                          </h3> : ""
                      }

                      <div style={{ display: "flex", alignItems: "center" }}>
                        {
                          prodSubstitute.length > 0 ?
                            <>
                              {
                                isSubstitutesPageShow === false ?
                                  <button style={{ marginTop: -20 }} onClick={() => setIsSubstitutesPageShow(true)}>{prodSubstitute.length} Substitutes</button>
                                  : ""
                              }
                              {
                                isSubstitutesPageShow ?
                                  <>
                                    <button onClick={() => setIsSubstitutesPageShow(false)} id='avalVendorsBtn' style={{ marginTop: -20, marginLeft: 10 }}>Available Vendors {"(" + avalVendors + ")"}</button>   </>
                                  : ""
                              }

                            </>
                            : ""
                        }
                        {
                          isSaltInformationPageShow ?
                            <button onClick={() => setIsSaltInformationPageShow(false)} id='avalVendorsBtn' style={{ marginTop: -20, marginLeft: 10 }}>Available Vendors {"(" + avalVendors + ")"}</button>
                            : ""
                        }
                      </div>
                    </div>

                  </div>
                  : ''
              }

              {/* salt information */}

              {/* <div style={{ marginTop: '1%', fontSize: '80%' }} id="availability">Available {serCat === 0 ? 'Vendors ' : 'Products '} {' >>'}</div> */}
              <div style={{ marginTop: '1%', fontSize: '80%' }} id="availability">{serCat === 0 ? 'Vendors ' : 'Products'} {' >>'}</div>
              {isSubstitutesPageShow ?
                <>
                  <div className="substitites customScroll">
                    {prodSubstitute !== null ?
                      prodSubstitute.map((substitute) => {
                        return (
                          <div>
                            <div>
                              <h2 style={{ margin: 0, padding: 0 }}>{substitute[1]}</h2>
                              <p style={{ margin: 0, padding: 0 }}>{substitute[3]} | {substitute[2]}</p>
                              <p style={{ margin: 0, padding: 0 }}>Estimated MRP : {Number(substitute[4]).toFixed(2)} | Estimated PTR : {Number(substitute[5]).toFixed(2)}</p>
                            </div>
                          </div>
                        )
                      })
                      : ''
                    }
                  </div>
                </>
                :
                isSaltInformationPageShow === false ?
                  <div className='DGVendProdAvl customScroll'>
                    {
                      selectedVendor !== null ?
                        <>
                          {fillVendorWiseProdAvl ?
                            fillVendorWiseProdAvl.map((vendorsProd, index) => {
                              var qty = 1;
                              var base64String = vendorWiseProdImages[vendorsProd[22]] === "None" ? "" : vendorWiseProdImages[vendorsProd[22]];

                              return (
                                <div style={{ padding: '1%', borderBottom: '1px solid gray', display: "flex" }}>
                                  <div className="searchImage">
                                    <img className='proImg' id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                                      alt="products" onError={(e) => { e.target.src = defProdImage }} />
                                  </div>
                                  <div style={{ width: "100%" }}>
                                    <div className='selectedVendor'>
                                      <div className='selectedVendorTitle' style={{ lineHeight: "18px", marginBottom: "10px", height: "70%", marginTop: "5px" }}>{vendorsProd[10]} <br />
                                        <span style={{ fontSize: "13px", fontWeight: "400" }}>{vendorsProd[11]}</span>  <br />
                                        <span style={{ fontSize: "13px", fontWeight: "400", marginTop: "-10px" }}>{vendorsProd[12]}</span>
                                      </div>
                                      <div className='selectedVendorInStock'>Stock : {parseInt(vendorsProd[3]) < 1 ? "In Stock" : String(vendorsProd[3]).padStart(2, '0')} {parseInt(vendorsProd[3]) > 0 ? vendorsProd[3] < 2 ? "Unit" : "Units" : ""}</div>
                                    </div>

                                    <div className='searVendProdList'>
                                      <div style={{ marginBottom: "5px" }}>MRP : {vendorsProd[4]} &nbsp;| &nbsp; PTR : {vendorsProd[21]} &nbsp;| &nbsp; Expiry : {String(vendorsProd[5]) !== '0' ? <>{vendorsProd[5]}/{vendorsProd[6]}</> : 'N/A'} </div>
                                      <div className='searVendProdListCol2'>

                                        <div className='addQtyBtn' onClick={(e) => {
                                          var divEl = e.target.nextElementSibling;
                                          divEl.innerText = parseInt(divEl.innerText) > 1 ? parseInt(divEl.innerText) - 1 : 1;
                                        }}> - </div>

                                        <div className='addQtyBtn' id="digiPartCartItemQty"> {qty} </div>

                                        <div className='addQtyBtn' onClick={(e) => {
                                          var divEl = e.target.previousElementSibling;
                                          divEl.innerText = parseInt(divEl.innerText) + 1;
                                        }}> + </div>

                                        <div className="btnPrimary1" onClick={(e) => {
                                          var qt = e.target.previousElementSibling.previousElementSibling.innerText;
                                          // var i_qty = document.getElementById("digiPartCartItemQty").value;
                                          addToDPCarts(vendorsProd, qt);
                                        }}>ADD</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                            :
                            ''
                          }
                        </>
                        :
                        serCat === 1 ?
                          <>
                            {/* Searched vendor product Data */}
                            {/* dp_id0, prod_Id1, batch_no2, stock_qty3, pro_mrp4, exp_month5, exp_year6, name7, mobile8, email9, pro_name10, unit11, 
                    pr.company12, CityName13, business_name14, mobile15, whatsapp_number16, ctx1_17, ctx2_18, ctx3_19 */}
                            {
                              vendorWiseProdAvl ?
                                vendorWiseProdAvl.map((vendorsProd, index) => {
                                  var qty = 1;
                                  return (
                                    <div style={{ padding: '1%', borderBottom: '1px solid gray' }}>
                                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ fontSize: '110%', fontWeight: 'bold' }}>{vendorsProd[14]}</div>
                                      </div>
                                      <div style={{ marginTop: "-6px", marginBottom: "8px" }}>{vendorsProd[13]}</div>
                                      <div className='digiPartNerSearchedProductCon'>
                                        <div style={{ fontSize: "13px", textTransform: "uppercase" }}>
                                          <span style={{ color: "green", fontSize: "13px", lineHeight: "1" }}>Stock :
                                            {parseInt(vendorsProd[3]) < 1 ? "In Stock" : String(vendorsProd[3]).padStart(2, '0')}
                                            {parseInt(vendorsProd[3]) > 0 ? vendorsProd[3] < 2 ? "Unit" : "Units" : ""}</span>
                                          &nbsp; |  &nbsp;
                                          <span style={{ color: "#fc7526" }}>Scheme as applicable</span> <br />
                                          MRP : {vendorsProd[4]} &nbsp; | &nbsp; PTR : N/A &nbsp; | &nbsp;
                                          Expiry : {String(vendorsProd[5]) !== '0' ? <>{vendorsProd[5]}/{vendorsProd[6]}</> : 'N/A'}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                          <div className='addQtyBtn' onClick={(e) => {
                                            var divEl = e.target.nextElementSibling;
                                            divEl.innerText = parseInt(divEl.innerText) - 1;
                                          }}> - </div>

                                          <div className='addQtyBtn' id="digiPartCartItemQty"> {qty} </div>

                                          <div className='addQtyBtn' onClick={(e) => {
                                            var divEl = e.target.previousElementSibling;
                                            divEl.innerText = parseInt(divEl.innerText) + 1;
                                          }}> + </div>

                                          <div className="btnPrimary1" onClick={(e) => {
                                            var qt = e.target.previousElementSibling.previousElementSibling.innerText;
                                            // var i_qty = document.getElementById("digiPartCartItemQty").value;
                                            addToDPCarts(vendorsProd, qt);
                                          }}>ADD</div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                                :
                                trendProducts && trendProducts !== null ?
                                  trendProducts.map((trendingProd, index) => {
                                    var qty = 1;
                                    var base64String = trendFiles[trendingProd[7]];
                                    return (
                                      <>
                                        {index === 0 ?
                                          <div className='trendProdHead'>Trending Products</div>
                                          : ''
                                        }
                                        <div style={{ padding: '1%', borderBottom: '1px solid gray', display: "flex", color: "gray" }}>
                                          <div className="searchImage">
                                            <img className='proImg' id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                                              alt="products" onClick={() => { setIsProductImagesModalShow(true) }} onError={(e) => { e.target.src = defProdImage }} />
                                          </div>
                                          <div style={{ width: "100%" }}>
                                            <div className='selectedVendor'>
                                              <div className='selectedVendorTitle' style={{ lineHeight: "18px", marginBottom: "10px", height: "70%", marginTop: "5px" }}>
                                                <span style={{ color: "#000", margin: 0, padding: 0, fontSize: 17 }}>{trendingProd[2]}</span> <br />
                                                <span style={{ fontSize: "13px", fontWeight: "400" }}>{trendingProd[4]}</span>  <br />
                                                <span style={{ fontSize: "13px", fontWeight: "400", marginTop: "-10px" }}>{trendingProd[3]}</span>
                                              </div>
                                            </div>

                                            <div className='searVendProdList'>
                                              <div style={{ marginBottom: "5px", fontSize: 13 }}>MRP : {trendingProd[5]} &nbsp;| &nbsp; PTR : {trendingProd[6]}</div>
                                              <div className='searVendProdListCol2'>
                                                {/* Add remove buttons here */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })
                                  :
                                  ''
                            }

                          </>
                          : serCat === 0 ?
                            <>
                              {/* Array.from(new Set(originalArray)) */}
                              {/* user_id, business_name, CityName, name, landline_number, business_mobile, ctx1, ctx2, ctx3, prod_Id, company */}

                              {
                                fillAvailableVendor ?
                                  fillAvailableVendor.map((vendorsProd, index) => {
                                    var companies = (Array.from(new Set((String(vendorsProd[10]).split(','))))).sort();
                                    return (
                                      <div className='seacrhItemList'>
                                        <div className='seacrhItemListCol1'>
                                          <div style={{ fontSize: '80%', color: 'rgb(78, 77, 77)' }}>
                                            <div style={{ display: 'flex' }}>
                                              <div className='searchVendName'>{vendorsProd[1]} | {vendorsProd[2]}
                                                {
                                                  String(vendorsProd[13]) === "1" ?
                                                    <>
                                                      &nbsp;
                                                      <img id="serVendVerified1" src={require('../../static/images/Icons/success.png')} alt='' />
                                                    </>
                                                    : <div> </div>
                                                }

                                              </div>
                                            </div>

                                            {
                                              String(vendorsProd[24]) === '1' ?
                                                <div>Owner Name : Mr./Mrs. {vendorsProd[3]}</div>
                                                : ''
                                            }
                                            <div>Business Address : {vendorsProd[14] ? vendorsProd[14] : ''} {vendorsProd[15] ? ', ' + vendorsProd[15] : ''} {vendorsProd[2] ? ', ' + vendorsProd[2] : ''} {vendorsProd[16] ? ', ' + vendorsProd[16] : ''} {vendorsProd[17] ? ', PinCode: ' + vendorsProd[17] : ''} </div>
                                            <div>Drug License Number : {vendorsProd[18] ? String(vendorsProd[18]).split('___')[0] + '  |  ' + String(vendorsProd[18]).split('___')[1] : ''} {vendorsProd[19] ? ', ' + String(vendorsProd[19]).split('___')[0] + '  |  ' + String(vendorsProd[19]).split('___')[1] : ''} </div>
                                            {
                                              vendorsProd[20] ?
                                                <div>GST Number : {vendorsProd[20]} </div>
                                                : ''
                                            }

                                            {vendorsProd[4] !== null && String(vendorsProd[4]) !== '0' ?
                                              <div style={{ fontSize: '90%' }}>Landline :0{vendorsProd[21] + '-' + vendorsProd[4]} {vendorsProd[22] && String(vendorsProd[22]) !== '0' ? ', ' + vendorsProd[22] : ''} {vendorsProd[23] && String(vendorsProd[23]) !== '0' ? ', ' + vendorsProd[23] : ''}</div>
                                              : ''
                                            }
                                            <div style={{ display: 'flex' }}>
                                              <div style={{ fontSize: '90%' }}>Contact : {vendorsProd[5]}</div>
                                              <div style={{ fontSize: '90%' }}>&nbsp;&nbsp;{(vendorsProd[6] !== null && String(vendorsProd[6]) !== '0') || (vendorsProd[7] !== null && String(vendorsProd[7]) !== '0') ? " | CNTX :" : ''}
                                                {vendorsProd[6]} {vendorsProd[7] != null && String(vendorsProd[7]) !== '0' ?
                                                  <> | {vendorsProd[7]} </>
                                                  : ''}</div>
                                            </div>
                                          </div>
                                          {
                                            vendorsProd[10] !== null ?
                                              <>
                                                <div style={{ fontSize: '100%', marginTop: '1%' }} >Available Companies</div>
                                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                  <div id="stockist_comp" style={{ height: '60px', overflow: 'hidden' }}>
                                                    {
                                                      companies.map((company, index) => {
                                                        return <div style={{ fontSize: '80%', color: 'rgb(78, 77, 77)' }}>{company}</div>
                                                      })
                                                    }
                                                  </div>
                                                  {
                                                    companies.length > 3 ?
                                                      <div onClick={(e) => toggleDiv(e)} id="more" className='more_less'>
                                                        ...More
                                                      </div>
                                                      : ''
                                                  }

                                                  <div onClick={(e) => toggleDiv(e)} id="less" className='more_less hide'>
                                                    ...Less
                                                  </div>
                                                </div>
                                              </>
                                              :
                                              <>
                                                <div style={{ fontSize: '100%', marginTop: '1%' }} >Available Companies</div>
                                                <div style={{ fontSize: '100%' }} >N/A</div>
                                              </>
                                          }
                                        </div>
                                        <div className='seacrhItemListCol2'>
                                          {
                                            String(vendorsProd[13]) === "1" ?
                                              <div className='searchVendVerified'>
                                                <img id="serVendVerified" style={{ width: "2vw" }} src={require('../../static/images/Icons/success.png')} alt='' />
                                                <div style={{ float: 'left', color: 'white', fontSize: '80%', paddingBlock: '3px', paddingInline: '10px', backgroundColor: 'rgb(29, 139, 29)', borderRadius: '15px' }}>Verified</div>
                                              </div>
                                              : <div> </div>
                                          }
                                          {
                                            vendorsProd[10] !== null ?
                                              <div className='dpVendSerPro' onClick={() => { searchProdVendorWise(vendorsProd); }} >Search Products {'>>'}</div>
                                              : ""
                                          }
                                        </div>
                                      </div>
                                    )
                                  })
                                  :
                                  ''
                              }
                            </>
                            :
                            ''
                    }
                  </div>
                  :
                  <>
                    <div className="saltInformation customScroll" style={{ marginTop: 10 }}>
                      {prodData[0][15] !== null ? Parser(prodData[0][15]) : prodData[0][5] !== null ? prodData[0][5] : ''}
                    </div>
                  </>

              }

            </div>
            <div className='searchSelectProductCol2'>
              <div className='digiPartnerCarts digiCartTop'>
                {
                  digiCartItems !== null ?
                    <>
                      ESTIMATED PTR VALUE :{totals != null ? Number(totals[1]).toFixed(2) : ''} &nbsp;&nbsp;&nbsp;
                      <span style={{ fontSize: '70%', backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '15px' }}>Clear</span>
                    </>
                    :
                    <div>
                    </div>
                }
              </div>

              <div className='digiPartnerCarts customScroll' style={{ position: "relative", minHeight: '20vw', overflowY: 'scroll', overflowX: 'hidden' }}>
                {
                  digiCartItems && digiCartItems !== null ?
                    digiCartItems.map((data) => {
                      return (
                        <div className='cartColStyle'>
                          <div style={{ textAlign: 'left' }}>
                            <div style={{ fontSize: '80%', color: 'black' }}>{data[3]}</div>
                            <div style={{ fontSize: '70%' }}>{data[4]} | <b>Qty : {data[9]}</b></div>
                          </div>
                          <Tooltip title="Remove">
                            <button className='ProdCol' onClick={() => deleteCartItem(data[0])} >
                              <img src={require('../../static/images/Icons/close.png')} alt="" />
                            </button>
                          </Tooltip>
                        </div>
                      )
                    })
                    :
                    <div className='' style={{ minHeight: '20vw', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <img src={require("../../static/DPImages/blanckCart.png")} alt="" style={{ width: "30%" }} />
                      <p style={{ fontSize: "17px" }}>Your Cart is empty !</p>

                      <div className='btnPrimary2' style={{ backgroundColor: 'gray', position: "absolute", width: "100%", bottom: "3%", cursor: 'pointer' }}>Place Order</div>
                    </div>
                }

              </div>

              {
                digiCartItems !== null && digiCartItems.length > 0 ?
                  <>
                    <div className='digiPartnerCarts' style={{ backgroundColor: 'white' }}>
                      <div style={{ textAlign: 'left', fontSize: '75%' }}>
                        Orders will be placed with {cartOrderComp?.length} distributers:
                      </div>
                      {
                        <div style={{ display: 'flex', alignItems: 'flex-end', fontWeight: 'normal' }}>
                          <div id="stockist_comp" style={{ fontSize: '70%', height: '30px', overflow: 'hidden', textAlign: 'left' }}>
                            {
                              cartOrderComp?.map((company, index) => {
                                return <span className='digiPartnerCartsCompa'>{index + 1 }. &nbsp; {company.split('___')[0]}</span>
                              })
                            }
                          </div>

                          <div onClick={(e) => toggleDiv(e)} id="more" className='more_less'>
                            ...More
                          </div>

                          <div onClick={(e) => toggleDiv(e)} id="less" className='more_less hide'>
                            ...Less
                          </div>
                        </div>
                      }
                    </div>
                  </>
                  :
                  ""
              }

              <div className='digiPartnerCarts' style={{ background: "#fff" }}>
                {
                  digiCartItems !== null ?
                    <>
                      <div className='cartColStyle cartColStyleFirst' style={{ fontWeight: 'bold' }}>
                        <div>ESTIMATED MRP</div>
                        <div>{totals != null ? Number(totals[0]).toFixed(2) : ''}</div>
                      </div>

                      <div className='cartColStyle' style={{ fontWeight: 'bold' }}>
                        <div>ESTIMATED PTR</div>
                        <div>{totals != null ? Number(totals[1]).toFixed(2) : ''}</div>
                      </div>

                      <>
                        <div className='btnPrimary2' onClick={() => { getQRCode(); }} >Place Order</div>

                        <div className="placeOrderModelContainer" style={{ visibility: showModel ? "visible" : "hidden" }}>
                          <div className="placeOrderModel shadow-xl">
                            <i className="ri-close-line" onClick={() => {
                              setShowModel(false);
                              getDigiCartItems();
                            }} style={{ position: "absolute", right: "1%", top: "2%", fontSize: 25, cursor: "pointer" }}></i>
                            <img src={require("../../static/DPImages/placeOrder.png")} alt="" />
                            <h3 style={{ margin: "0px" }}>Order Place successfully</h3>
                            <p style={{ color: "gray" }}>Your Orders details have been send to your distibrutors</p>
                            <div style={{ borderTop: '2px solid gray' }} id="successMessage">
                              {/* {orderCompleteMessage} */}
                            </div>
                            <Link className='btnPrimary2' style={{ outline: 0, border: 0, width: "50%", textDecoration: "none", color: "#fff" }} to="/DigiPartneRetailOrders">View Order Details</Link>
                          </div>
                        </div>


                      </>
                    </>
                    :
                    <>
                      {/* <div className='btnPrimary2' style={{ backgroundColor: 'gray', cursor: 'auto' }}>Place Order</div> */}
                    </>
                }

                <div style={{ textAlign: 'left', fontSize: '70%', color: 'gray', fontWeight: "normal" }}>
                  Disclaimer <br />
                  Prices are indicative and may change after billing. Sheet360 is a technology platfrom to connect sellers and buyers and is not involved in sales of any product.
                  Offer for sale on the products and services are provided/sold by the sellers only. For more details read <span style={{ color: 'rgb(44, 156, 247)' }}>Terms & Conditions.</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        {loader ? <Loader /> : ''}
      </div>

      <div className='Dialogue hide' id='Dialogue'>
        <div className='DialogueMSG'>
          <img src={require("../../static/DPImages/Logo.png")} alt="" />
          <hr />
          <div >
            <div className="details">

              <h3>Use whatsApp for your order</h3>

              <div className='flex'>
                <div>
                  <p style={{ marginTop: "40px" }}>1. Open whatsapp on your phone</p>
                  <p>2. Tab menu : on android , or  setting on iphone</p>
                  <p>3. Tab link devices and than link a device</p>
                  <p>4. Point your phone at this screen to capture the QR code</p>

                  <p style={{ marginTop: "30px", color: "#00C7E4" }} className='linkWithPhone'>Link with phone number</p>
                  <Link to="/ContactUs" style={{ textDecoration: "none", color: "#00C7E4", textAlign: "center" }}>Need help ?</Link>
                  <span onClick={() => {
                    continueWithOrder();
                  }}>Continue X</span>
                </div>

                <div className="qrCode">
                  {QRCode && QRCode != null ?
                    <img src={QRCode} alt="" />
                    : ''
                  }  </div>
              </div>
            </div>

          </div>



        </div>
      </div>
      {/* isProductImagesModalShow */}
      <Modal className='productImagesModal' show={isProductImagesModalShow} onHide={() => {
        setIsProductImagesModalShow(false);
      }} centered size="xl" backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <h3 style={{ fontSize: 25, color: "#000" }}>Product Images</h3>
            <span onClick={() => { setIsProductImagesModalShow(false) }} style={{ cursor: "pointer", fontSize: 25 }}><MdClose /></span>
          </div>

          <div className="d-flex align-items-start justify-content-between" style={{ gap: "10px" }}>

            <div className="images d-flex align-items-center flex-wrap">
              {
                tempData ? tempData.map((e, i) => {
                  return (
                    <>
                      <img onClick={(e) => { setProductSelectedImage(e.target.classList[1]) }} className={`productImage-${i + 1} ${e} mb-2`} src={e} alt="" />
                    </>
                  )
                }) : ""
              }
            </div>

            <div className="image magnify-container" style={{ marginBottom: "50px", marginRight: "20px" }}>
              {/* <img className='mainImage' src={productSelectedImage} alt="" /> */}
              <ReactImageMagnify {...{
                smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: productSelectedImage,
                  
                  className: 'mainImageClass' // Add your custom class here
                },
                largeImage: {
                  src: productSelectedImage,
                  width: 1200,
                  height: 1800
                },
                enlargedImageContainerDimensions: {
                  width: '100%',
                  height: '100%'
                },
                enlargedImageContainerClassName: 'large-image-container',
              }} className="mainImage"/>
               
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SearchMedVenAvl