import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DashNavbar from '../DashContainers/DashNavbar';
import DashPanel from '../DashContainers/DashPanel';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Loader from '../Containers/Loader';

const UserReportAdmin = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const pendVeri = queryParameters.get("pendVeri", null);

  const [loader, setLoader] = useState(false);

  const [isEdit, SetEdit] = useState(false);
  const [cities, setCities] = useState([{ key: 0, value: 0, label: 'None' },]);
  const [states, setStates] = useState([{ key: 0, value: 0, label: 'None' },]);
  const [partnerType, setPartnerType] = useState(null);


  ReactSession.setStoreType("localStorage");
  const navigate = useNavigate();

  const selectStyle = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      background: "white",
      border: '1px solid gray',
      lineHeight: '.5rem',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      paddingTop: '-20px',
      height: '20px',
      marginTop: '.4rem',
      minHeight: '30px',
      border: selectedCityPer !== null ? '1px solid gray' : '2px solid orangered',
    }),

    input: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop: '-5px',
    }),
  }

  const [queryText, setQueryText] = useState(null);
  const [userData, setUserData] = useState(null);
  const [dpProfileModal, setDpProfileModal] = useState(false);

  const [profileData, setProfileData] = useState(null);
  const [profileDocuments, setProfileDocuments] = useState(null);

  const dpProfileModalClose = () => setDpProfileModal(false);
  const dpProfileModalOpen = (dp_id) => {
    getProfile(dp_id);
    setDpProfileModal(true);
  }


  const [selectedCityPer, setCityPer] = useState(cities.label);
  const handleSelectCity = e => {
    setCityPer(e.value);
    const optionToSelect = cities.find((option) => option.value === e.value);
    setFilterCityPer(optionToSelect);
  }
  const [filterCityPer, setFilterCityPer] = useState(null);

  const [selectedStatePer, setStatePer] = useState(states.label);
  const handleSelectState = e => {
    setStatePer(e.value);
    const optionToSelect = states.find((option) => option.value === e.value);
    setFilterStatePer(optionToSelect);
  }
  const [filterStatePer, setFilterStatePer] = useState(null);


  const [selectedCityBusi, setCityBusi] = useState(cities.label);
  const handleSelectCity2 = e => {
    removeErrorMessage("busiState");
    setCityBusi(e.value);
    const optionToSelect = cities.find((option) => option.value === e.value);
    setFilterCityBusi(optionToSelect);
  }
  const [filterCityBusi, setFilterCityBusi] = useState(null);

  const [selectedStateBusi, setStateBusi] = useState(states.label);
  const handleSelectState2 = e => {
    removeErrorMessage("busiState");
    setStateBusi(e.value);
    const optionToSelect = states.find((option) => option.value === e.value);
    setFilterStateBusi(optionToSelect);
  }
  const [filterStateBusi, setFilterStateBusi] = useState(null);


  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  // dlpdfRetailer dlpdfWholeSaler regLicencepdf GSTpdf PANpdf FSSAIpdf BANKpdf
  const [dlpdfRetailer, setDL_PDF_Ret] = useState(null);
  const [dlpdfWholeSaler, setDL_PDF_WS] = useState(null);
  const [regLicencepdf, setRegPDF] = useState(null);
  const [GSTpdf, setGSTPDF] = useState(null);
  const [PANpdf, setPanPDF] = useState(null);
  const [FSSAIpdf, setFSSAI_PDF] = useState(null);
  const [BANKpdf, setBankPDF] = useState(null);

  const changeFileHandler = (event) => {
    removeErrorMessage(event.target.id);
    const file = event.target.files[0];

    // const preview = document.getElementById('pdfPreview');
    // // Ensure a file is selected
    // if (input.files.length > 0) {
    //   const file = input.files[0];

    //   // Check if the selected file is a PDF
    //   if (file.type === 'application/pdf') {
    //   // Use the URL.createObjectURL to create a temporary URL for the selected file
    //   const pdfUrl = URL.createObjectURL(file);

    //   // Set the source of the iframe to the temporary URL
    //   preview.src = pdfUrl;
    //   } else {
    //   alert('Please select a valid PDF file.');
    //   // Clear the file input to allow the user to choose another file
    //   input.value = '';
    //   }
    // }


    if (!file.type.match('application/pdf')) {
      alert("Please upload only PDF..");
      event.target.value = null;
    }
    else {
      var reader = new FileReader();
      reader.onload = function () {
        if (event.target.id === "dlpdfRetailer") {
          setDL_PDF_Ret(event.target.files[0]);
          var output = document.getElementById('dlRetpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "dlpdfWholeSaler") {
          setDL_PDF_WS(event.target.files[0]);
          var output = document.getElementById('dlWSpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "regLicencepdf") {
          setRegPDF(event.target.files[0]);
          var output = document.getElementById('regpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "GSTpdf") {
          setGSTPDF(event.target.files[0]);
          var output = document.getElementById('gstpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "PANpdf") {
          setPanPDF(event.target.files[0]);
          var output = document.getElementById('panpdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "FSSAIpdf") {
          setFSSAI_PDF(event.target.files[0]);
          var output = document.getElementById('fssaipdfPreview');
          output.src = reader.result;
        }
        else if (event.target.id === "BANKpdf") {
          setBankPDF(event.target.files[0]);
          var output = document.getElementById('bankpdfPreview');
          output.src = reader.result;
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  const updateProfile = () => {
    let formData = new FormData();  //formdata object

    // fname lname gender perAddress1 perAddress2 pincode mobile email busiName busiAddress1 busiAddress2 pincode2 mobile2 email2 stdCode 
    // landline1 landline2 landline3 ctx1 ctx2 ctx3 dlRetailer1 dlRetailer2  dlWholeSaler1 dlWholeSaler2 regLicence
    // gst pan fssai banckAccount bankIFSC dlpdfRetailer dlpdfWholeSaler regLicencepdf GSTpdf PANpdf FSSAIpdf BANKpdf

    // alert("Updating....");

    formData.append('updateProf', 1);
    formData.append('dlpdfRetailer', dlpdfRetailer);
    if (parseInt(partnerType) > 1) {
      formData.append('dlpdfWholeSaler', dlpdfWholeSaler);
      formData.append('dlWholeSaler1', document.getElementById('dlWholeSaler1').value);
      formData.append('dlWholeSaler2', document.getElementById('dlWholeSaler2').value);
    }

    formData.append('regLicencepdf', regLicencepdf);
    formData.append('GSTpdf', GSTpdf);
    formData.append('PANpdf', PANpdf);
    formData.append('FSSAIpdf', FSSAIpdf);
    formData.append('BANKpdf', BANKpdf);
    formData.append('dp_id', ReactSession.get("id_eu"));
    formData.append('name', document.getElementById('fname').value + ' ' + document.getElementById('lname').value);
    formData.append('gender', document.getElementById('gender').value);
    formData.append('perAddress1', document.getElementById('perAddress1').value);
    formData.append('perAddress2', document.getElementById('perAddress2').value);
    formData.append('selectedCityPer', selectedCityPer);
    formData.append('selectedStatePer', selectedStatePer);
    formData.append('pincode', document.getElementById('pincode').value);
    formData.append('mobile', document.getElementById('mobile').value);
    formData.append('email', document.getElementById('email').value);
    formData.append('busiName', document.getElementById('busiName').value);
    formData.append('busiAddress1', document.getElementById('busiAddress1').value);
    formData.append('busiAddress2', document.getElementById('busiAddress2').value);
    formData.append('selectedCityBusi', selectedCityBusi);
    formData.append('selectedStateBusi', selectedStateBusi);
    formData.append('pincode2', document.getElementById('pincode2').value);
    formData.append('mobile2', document.getElementById('mobile2').value);
    formData.append('email2', document.getElementById('email2').value);
    formData.append('stdCode', document.getElementById('stdCode').value);
    formData.append('landline1', document.getElementById('landline1').value);
    formData.append('landline2', document.getElementById('landline2').value);
    formData.append('landline3', document.getElementById('landline3').value);
    formData.append('ctx1', document.getElementById('ctx1').value);
    formData.append('ctx2', document.getElementById('ctx3').value);
    formData.append('ctx3', document.getElementById('ctx3').value);
    formData.append('dlRetailer1', document.getElementById('dlRetailer1')?.value);
    formData.append('dlRetailer2', document.getElementById('dlRetailer2')?.value);
    formData.append('regLicence', document.getElementById('regLicence').value);
    formData.append('gst', document.getElementById('gst').value);
    formData.append('pan', document.getElementById('pan').value);
    formData.append('fssai', document.getElementById('fssai')?.value);
    formData.append('banckAccount', document.getElementById('banckAccount')?.value);
    formData.append('bankIFSC', document.getElementById('bankIFSC')?.value);
    formData.append('confirm', document.getElementById('confirm').checked ? "Yes" : "No");

    setLoader(true);
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          alert("Profile Successfully Updated\nPending for verification");
          navigate('/Admin');
        }
        else {
          alert("Error updating profile :", result.status);
        }
        setLoader(false);
      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  const verifyProfile = () => {
    var errorElements = [];
    var neccessaryFields = [];
    // neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
    //   "dlWholeSaler1", "dlWholeSaler2", "gst", "regLicence", "pan", "banckAccount", "bankIFSC", "dlpdfRetailer", "dlpdfWholeSaler", "regLicencepdf", "GSTpdf", "PANpdf", "BANKpdf",
    // ]

    if (parseInt(partnerType) === 1) {
      if (profileDocuments !== null)
        neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
          "gst", "pan", "banckAccount", "bankIFSC"
        ]
      else
        neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
          "gst", "pan", "banckAccount", "bankIFSC", "dlpdfRetailer", "GSTpdf", "PANpdf", "BANKpdf",
        ]
    }
    else
      if (parseInt(partnerType) === 2) {
        if (profileDocuments !== null)
          neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1",
            "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC",
          ]
        else
          neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1",
            "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC", "dlpdfWholeSaler", "GSTpdf", "PANpdf", "BANKpdf",
          ]
      }
      else
        if (parseInt(partnerType) === 3) {
          if (profileDocuments !== null)
            neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
              "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC"
            ]
          else
            neccessaryFields = ["fname", "lname", "mobile", "email", "busiName", "busiAddress1", "pincode2", "stdCode", "landline1", "dlRetailer1", "dlRetailer2",
              "dlWholeSaler1", "dlWholeSaler2", "gst", "pan", "banckAccount", "bankIFSC", "dlpdfRetailer", "dlpdfWholeSaler", "GSTpdf", "PANpdf", "BANKpdf",
            ]
        }

    for (let i = 0; i < neccessaryFields.length; i++) {
      if (document.getElementById(neccessaryFields[i]).value === "") {
        errorElements.push(neccessaryFields[i]);
        document.getElementById(neccessaryFields[i]).classList.add("errorField");
      }
    }

    if (selectedCityBusi === null) {
      errorElements.push("busiCity");
    }

    if (selectedStateBusi === null) {
      errorElements.push("busiState");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      if (!document.getElementById('confirm').checked) {
        alert("Please tick checkbox to confirm");
        document.getElementById('confirm').focus();
      }
      else {
        updateProfile();
      }
    }
  }


  const getProfile = (dp_id) => {
    // var dp_id = ReactSession.get("id_eu");
    setLoader(true);
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?getProfile=1&user_Id=' + dp_id;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      setPartnerType(res.partnerType);
      setProfileData(res.profileData);
      setProfileDocuments(res.profileDocuments);
      setDL_PDF_Ret(res.profileDocuments[res.profileData[0][41]]);
      setDL_PDF_WS(res.profileDocuments[res.profileData[0][42]]);
      setRegPDF(res.profileDocuments[res.profileData[0][40]]);
      setGSTPDF(res.profileDocuments[res.profileData[0][44]]);
      setPanPDF(res.profileDocuments[res.profileData[0][45]]);
      setFSSAI_PDF(res.profileDocuments[res.profileData[0][43]]);
      setBankPDF(res.profileDocuments[res.profileData[0][50] + '_' + res.profileData[0][51]]);

      fetch("http://35.154.53.53/citiesAPI", {
        method: 'GET',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }).then((respo) => {
        return respo.json()
      }).then((result) => {
        setCities(result.citiesD);
        setStates(result.statesD);
        const cityD = result.citiesD.find((option) => option.value === res.profileData[0][13]);
        setFilterCityPer(cityD);
        const stateD = result.statesD.find((option) => option.value === res.profileData[0][14]);
        setFilterStatePer(stateD)

        const cityDBusi = result.citiesD.find((option) => option.value === res.profileData[0][25]);
        setFilterCityBusi(cityDBusi);
        const stateDBusi = result.statesD.find((option) => option.value === res.profileData[0][26]);
        setFilterStateBusi(stateDBusi)
      });
      setLoader(false);
    });

    // fetch("http://35.154.53.53/citiesAPI", {
    //   method: 'GET',
    //   headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    // }).then((response) => {
    //   return response.json()
    // }).then((res) => {
    //   setCities(res.citiesD);
    //   setStates(res.statesD);
    // });
  }


  const approveClient = (userId) => {
    setLoader(true);
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        grantApproval: '1',
        userId: userId,
      })
    }).then((response) => response.json())
      .then((data) => {
        // navigate('/UserReportAdmin?pendVeri=1');
        setLoader(false);
        getUsers();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const [userFillData, setUserFillData] = useState(null);
  const filterUsersData = () => {
    var sertext = document.getElementById("searchTxt").value;
    if (sertext !== null) {
      const filteredData = userData.filter(userDa =>
        userDa[1]?.toLowerCase().includes(sertext.toLowerCase()) ||
        userDa[2]?.toLowerCase().includes(sertext.toLowerCase()) ||
        userDa[3]?.toLowerCase().includes(sertext.toLowerCase())
      );
      setUserFillData(filteredData);
    }
  }

  const executeQry = () => {
    var dtFrom = document.getElementById("dateFrom");
    var dtTo = document.getElementById("dateTo");
    if (String(dtFrom.value).length < 8) {
      alert("Please enter a valid date :");
      dtFrom.focus();
    }
    else
      if (String(dtTo.value).length < 8) {
        alert("Please enter a valid date :");
        dtTo.focus();
      }
      else {
        //   console.log("Executing query");
        var qry = " u.created_at >= '" + dtFrom.value + "' and u.created_at <= '" + dtTo.value + "'";
        if (String(pendVeri) === '1')
          qry += " and is_verified <> 1"

        var apiURL = 'http://35.154.53.53/digiPartnerAPI?userState=2&user_Id=' + ReactSession.get('id') + '&qry=' + qry;
        fetch(apiURL, {
          method: 'GET',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
        }).then((response) => {
          return response.json();
        }).then((resp) => {
          setUserData(resp.userData);
          setUserFillData(resp.userData);
          var dtFrom = document.getElementById("dateFrom");
          var dtTo = document.getElementById("dateTo");
          dtFrom.value = '';
          dtTo.value = '';
        });

        //   setQueryText(qry);
        //   if (queryText !== null)
        //   {
        //   getUsers();
        //   }
      }
  }

  const getUsers = () => {
    setLoader(true);
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?userState=2&user_Id=' + ReactSession.get('id_eu');
    if (String(pendVeri) === '1') { apiURL += '&qry= u.is_verified <> 1'; }

    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      setUserData(resp.userData);
      setUserFillData(resp.userData);
      var dtFrom = document.getElementById("dateFrom");
      var dtTo = document.getElementById("dateTo");
      dtFrom.value = '';
      dtTo.value = '';
      setLoader(false)
    });
  }

  useEffect(() => {
    if (!ReactSession.get('adminpillsit')) {
      alert("You are not logged in.")
      navigate('/Admin');
    }
    else {
      getUsers();
    };
  }, [pendVeri]);

  return (
    <>
      <DashNavbar />
      <div className='dashContainer' style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div className='dashPanel'>
          <DashPanel />
        </div>
        <div className='dashContents' style={{ backgroundColor: 'white' }}>
          {/* <div className='pendOrders'>Hello</div> */}
          <div style={{ margin: '.5%', width: '98%', fontSize: '120%', display: 'flex', justifyContent: 'space-between', borderBottom: '3px solid rgb(61, 197, 238)' }}>
            <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBlock: '.5vw' }}>
              <div style={{ fontWeight: 'bold', fontSize: '120%', width: '30%' }} >
                User Report
              </div>
              <div style={{ width: '65%', marginLeft: '10%' }}> <input style={{ width: '120%' }} type='text' name='searchTxt' id='searchTxt' onChange={filterUsersData} className='form-control form-control-sm' placeholder='Search by Name/Email/Mobile :' /></div>

            </div>
            <div style={{ display: 'flex', fontSize: '80%', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div>&nbsp;&nbsp;Date From&nbsp;&nbsp;</div>
              <div><input type='date' name='dateFrom' id='dateFrom' className='form-control form-control-sm' /></div>
              <div>&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;</div>
              <div><input type='date' name='dateTo' id='dateTo' className='form-control form-control-sm' /></div>
              <div>&nbsp;&nbsp;&nbsp;</div>
              <div className='digiNavBtn2' onClick={() => executeQry()}>Go</div>
              <div className='digiNavBtn2' >Print</div>
            </div>
          </div>

          <div style={{ marginInline: '.5%', borderBlock: '1px solid rgb(61, 197, 238)', width: '99%', display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ width: '6%', borderInline: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>S.NO</div>
            <div style={{ width: '22%', borderRight: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%' }}>Name/Mobile/Email</div>
            <div style={{ width: '8%', borderRight: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Gender</div>
            <div style={{ width: '8%', borderRight: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Wallet</div>
            <div style={{ width: '10%', borderRight: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Registration</div>
            <div style={{ width: '10%', borderRight: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }} >Role</div>
            <div style={{ width: '28%', borderRight: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Last Order {'(Amount/Date)'}</div>
            <div style={{ width: '8%', borderRight: '1px solid rgb(61, 197, 238)', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Action</div>
            <div style={{ width: '.5rem', borderRight: '0px', borderBlock: '0px' }}></div>
          </div>

          <div className='stockDataPanel customScroll'>
            {
              userFillData ?
                userFillData.map((item, index) => {
                  var regDate = Date(String(item[11]).split('T')[0]);
                  var orders = item[21]?.split(',');
                  return (
                    <div style={{ marginInline: '.5%', minHeight: '10vh', width: '99%', display: 'flex', justifyContent: 'flex-start', borderBlock: '1px solid rgb(61, 197, 238)' }}>
                      <div style={{ width: '6%', borderInline: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{index + 1}</div>
                      <div style={{ width: '22%', borderRight: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%' }}>
                        <div>{item[22] !== '0' ? item[22] : item[1]}</div>
                        <div style={{ fontSize: '80%' }}>{item[23] !== '0' ? item[23] : item[2]}</div>
                        <div style={{ fontSize: '80%' }}>{item[24] !== '0' ? item[24] : item[3]}</div>
                      </div>
                      <div style={{ width: '8%', borderRight: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{item[5]}</div>
                      <div style={{ width: '8%', borderRight: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}> 0 </div>
                      <div style={{ width: '10%', borderRight: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{String(item[11]).split('T')[0]}<br />{String(item[11]).split('T')[1]}</div>
                      <div style={{ width: '10%', borderRight: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{item[20] === 0 ? "User" : item[20] === 1 ? "Retailer" : item[20] === 2 ? "Whole Seller" : 'Retailer + Whole Seller'}</div>
                      <div style={{ width: '28%', borderRight: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>
                        {
                          orders?.length > 0 ?
                            orders.map((order, index) => {
                              return (
                                index < 3 ?
                                  <>
                                    {Number(order.split('=>')[0]).toFixed(2)}&nbsp;&nbsp; |&nbsp;&nbsp;{new Date(String(order.split('=>')[1]).substring(0, 10)).toDateString()}<br />
                                  </> : ''
                              )
                            })
                            : ''
                        }
                      </div>
                      <div style={{ display: 'flex', width: '8%', borderRight: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        {
                          item[20] !== 0 ?
                            item[4] !== 1 ?
                              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <div className='digiNavBtnRed' onClick={() => dpProfileModalOpen(item[0])}>
                                  Details
                                </div>
                                <div className='digiNavBtnRed' onClick={() => { approveClient(item[0]) }}>
                                  Approve
                                </div>
                              </div>
                              :
                              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <div className='digiNavBtnGreen' onClick={() => dpProfileModalOpen(item[0])}>
                                  Details
                                </div>
                                <div className='digiNavBtnGreen'>
                                  Approved
                                </div>
                              </div>
                            : ''
                        }
                      </div>
                    </div>
                  )
                }
                )
                :
                ''
            }
          </div>
        </div>
      </div>

      {/* DP Profile MODAL */}
      <Modal show={dpProfileModal} onHide={dpProfileModalClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>{profileData ? profileData[0][17] : ''} Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {loader ? <Loader /> : ''}
          <div style={{ fontSize: '120%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <input type='checkbox' style={{ cursor: 'pointer' }} name="isEdit" id="isEdit" onChange={() => SetEdit(!isEdit)} />
            <label for="isEdit" style={{ cursor: 'pointer' }}>&nbsp;&nbsp;&nbsp;Edit</label> &nbsp;&nbsp;&nbsp;
            {/* <img className='closeBtn' src={require("../../static/images/Icons/close.png")} alt="" onClick={dpProfileModalClose}/> */}
          </div>
          <div className='DigiDashContent customScroll' id='DigiDashContent' style={{ overflowY: 'scroll', width: '98%', fontSize: '98%', backgroundColor: 'white', marginInline: '1%', marginTop: '1%' }}>
            <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
            {/* <div style={{ fontSize: '200%', fontWeight: 'bold', }}>{ profileData ? profileData[0][17] :''}</div> */}

            <div style={{ borderBottom: '2px solid gray', paddingBlock: '2%', paddingTop: '0px' }} id="perDetails">
              <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Proprieters Information</div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='text' name="fname" id='fname' placeholder='First Name' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][2].split(' ')[0] : ''} onChange={() => removeErrorMessage("fname")} />
                </div>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='text' name="lname" id='lname' placeholder='Last Name' disabled={isEdit ? false : true} defaultValue={profileData ? (profileData[0][2].split(' ').slice(1).join(' ')) : ''} onChange={() => removeErrorMessage("lname")} />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='text' name="perAddress1" id='perAddress1' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][9] : ''} placeholder='Address Line 1' />
                </div>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='text' name="perAddress2" id='perAddress2' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][10] : ''} placeholder='Address Line 2' />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                  <Select
                    options={cities}
                    id="LocationCity1"
                    // defaultValue={filCityShip}
                    value={filterCityPer !== null ? filterCityPer : ''}
                    onChange={handleSelectCity}
                    placeholder="Select City"
                    styles={selectStyle}
                    isDisabled={isEdit ? false : true}
                  />

                  <Select
                    options={states}
                    id="StateID1"
                    // defaultValue={filStateShip}
                    value={filterStatePer !== null ? filterStatePer : ''}
                    onChange={handleSelectState}
                    placeholder="Select State"
                    styles={selectStyle}
                    isDisabled={isEdit ? false : true}
                  />
                </div>

                <div style={{ width: '45%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <div className='digiLogin' style={{ width: '30%' }}>
                    <input className="digiPartLoginInput" type='text' name="pincode" id='pincode' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][12] : ''} placeholder='Pincode' />&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  Gender : &nbsp;&nbsp;
                  <input type='radio' name="gender" id='gender' value="Male" disabled={isEdit ? false : true} defaultChecked={profileData === null ? true : profileData[0][5] === "Male" ? true : false} />
                  <label for="male">Male</label> &nbsp;&nbsp;&nbsp;&nbsp;
                  <input type='radio' name="gender" value="Female" disabled={isEdit ? false : true} defaultChecked={profileData === null ? false : profileData[0][5] === "Female" ? true : false} />
                  <label for="female">Female</label>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '45%' }}>
                  <input className="digiPartLoginInput" type='text' name="mobile" id='mobile' maxLength={10} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][6] : ''} placeholder='Personal Mobile' onChange={() => removeErrorMessage("mobile")} />
                </div>
                <div className='digiLogin' style={{ width: '45%' }}>
                  <input className="digiPartLoginInput" type='email' name="email" id='email' placeholder='Valid E-mail id' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][11] : ''} onChange={() => removeErrorMessage("email")} />
                </div>
              </div>
            </div>
            <div id="businessDetails" style={{ borderBottom: '2px solid gray', paddingBlock: '2%' }}>
              <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Business Information</div>
              <div className='digiLogin' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <input className="digiPartLoginInput" type='text' name="busiName" id='busiName' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][17] : ''} placeholder='Full Business Name' onChange={() => removeErrorMessage("busiName")} />
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='text' name="busiAddress1" id='busiAddress1' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][21] : ''} placeholder='Business Address Line 1' onChange={() => removeErrorMessage("busiAddress1")} />
                </div>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='text' name="busiAddress2" id='busiAddress2' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][22] : ''} placeholder='Business Address Line 2' />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '45%', display: 'flex', justifyContent: 'space-between', }}>
                  <div className='busiCity' id='busiCity'>
                    <Select
                      options={cities}
                      id="LocationCity2"
                      // defaultValue={filCityShip}
                      value={filterCityBusi !== null ? filterCityBusi : ''}
                      onChange={handleSelectCity2}
                      placeholder="Select City"
                      styles={selectStyle}
                      isDisabled={isEdit ? false : true}
                    />
                  </div>

                  <div className='busiState' id='busiState'>
                    <Select
                      options={states}
                      id="StateID2"
                      // defaultValue={filStateShip}
                      value={filterStateBusi !== null ? filterStateBusi : ''}
                      onChange={handleSelectState2}
                      placeholder="Select State"
                      styles={selectStyle}
                      isDisabled={isEdit ? false : true}
                    />
                  </div>
                </div>

                <div className='digiLogin' style={{ width: '45%', display: 'flex', justifyContent: '' }}>
                  <input className="digiPartLoginInput" style={{ width: '30%' }} type='text' name="pincode2" id='pincode2' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][23] : ''} placeholder='Pincode' onChange={() => removeErrorMessage("pincode2")} />&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              {/* vendor_id, user_id, name, name_as_aadhar, dob, gender, mobile, whatsapp_number, aadhar_number, address, address2, email, zipcode, city_id, state_id, post_office, country, business_name, legal_name, business_mobile, busi_email, business_address, business_address2, zipcode2, landmark, busi_city_id, busi_state_id, stdCode, landline_number, landline_number2, landline_number3, CTX1, CTX2, CTX3, partner_type_id, is_working, commission, care_of, business_type, registration_date, registration_license_number, drug_license_number, drug_license_numberWS, fssai_number, gst_number, pancard_number, center_jurisdiction, state_jurisdiction, account_holder_name, bank_name, account_number, IFSC_code, upi_name, upi_id, upi_mobile_number, remark, payout_mode_id, confirm, created_at, updated_date */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='text' name="mobile2" id='mobile2' maxLength={10} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][19] : ''} placeholder='Business Mobile' />
                </div>
                <div className='digiLogin' style={{ width: '45%' }} >
                  <input className="digiPartLoginInput" type='email' name="email2" id='email2' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][20] : ''} placeholder='Valid Business E-mail id' />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='digiLogin' style={{ width: '30%' }}>
                    <input className="digiPartLoginInput" type='text' name="stdCode" id='stdCode' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][27] : ''} placeholder='STD' onChange={() => removeErrorMessage("stdCode")} />
                  </div>
                  <div className='digiLogin' style={{ width: '65%' }}>
                    <input className="digiPartLoginInput" type='text' name="landline1" id='landline1' maxLength={7} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][28] : ''} placeholder='Landline number 1' onChange={() => removeErrorMessage("landline1")} />
                  </div>
                </div>

                <div style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='digiLogin' style={{ width: '49%' }}>
                    <input className="digiPartLoginInput" type='text' name="landline2" id='landline2' maxLength={7} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][29] : ''} placeholder='Landline number 2' />
                  </div>
                  <div className='digiLogin' style={{ width: '49%' }}>
                    <input className="digiPartLoginInput" type='text' name="landline3" id='landline3' maxLength={7} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][30] : ''} placeholder='Landline number 3' />
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='digiLogin' style={{ width: '49%' }}>
                    <input className="digiPartLoginInput" type='text' name="ctx1" id='ctx1' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][31] : ''} placeholder='CTX 1' />
                  </div>
                  <div className='digiLogin' style={{ width: '49%' }}>
                    <input className="digiPartLoginInput" type='text' name="ctx2" id='ctx2' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][32] : ''} placeholder='CTX 2' />
                  </div>
                </div>
                <div className='digiLogin' style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                  <input className="digiPartLoginInput" style={{ width: '49%' }} type='text' name="ctx3" id='ctx3' maxLength={6} disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][33] : ''} placeholder='CTX 3' />
                </div>
              </div>
            </div>

            <div id="businessDocuments" style={{ borderBottom: '2px solid gray', paddingBlock: '2%' }}>
              <div style={{ fontSize: '110%', fontWeight: 'bold' }}>Required Documents</div>

              {partnerType && (parseInt(partnerType) === 1 || parseInt(partnerType) === 3) ?
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div id="retail" style={{ width: '45%' }}>
                    <div>Retailers Drug License No </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className='digiLogin' style={{ width: '45%' }}>
                        <input className="digiPartLoginInput" type='text' name="dlRetailer1" id='dlRetailer1' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][41]).split('___')[0] : ''} placeholder='Drug License No1' onChange={() => removeErrorMessage("dlRetailer1")} />
                      </div>
                      <div className='digiLogin' style={{ width: '45%' }}>
                        <input className="digiPartLoginInput" type='text' name="dlRetailer2" id='dlRetailer2' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][41]).split('___')[1] : ''} placeholder='Drug License No2' onChange={() => removeErrorMessage("dlRetailer2")} />
                      </div>
                    </div>
                  </div>
                  <div id="retail2" style={{ width: '45%' }}>
                    <div>Attached Document </div>
                    <div className='digiLogin' style={{ display: 'flex' }}>
                      <input className="digiPartLoginInput" type='file' accept='application/pdf' name="dlpdfRetailer" id='dlpdfRetailer'
                        onChange={changeFileHandler} disabled={isEdit ? false : true} placeholder='Attach PDF DL' />
                      <iframe id="dlRetpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][41]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][41]]}` : ''} ></iframe>
                    </div>
                  </div>
                </div>
                : ''
              }

              {partnerType && parseInt(partnerType) > 1 ?
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div id="wholesaler" style={{ width: '45%' }}>
                    <div>WholeSalers Drug License No </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className='digiLogin' style={{ width: '45%' }}>
                        <input className="digiPartLoginInput" type='text' name="dlWholeSaler1" id='dlWholeSaler1' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][42]).split('___')[0] : ''} placeholder='Drug License No1' onChange={() => removeErrorMessage("dlWholeSaler1")} />
                      </div>
                      <div className='digiLogin' style={{ width: '45%' }}>
                        <input className="digiPartLoginInput" type='text' name="dlWholeSaler2" id='dlWholeSaler2' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][42]).split('___')[1] : ''} placeholder='Drug License No2' onChange={() => removeErrorMessage("dlWholeSaler2")} />
                      </div>
                    </div>
                  </div>
                  <div id="wholesaler2" style={{ width: '45%' }}>
                    <div>Attached Document </div>
                    <div className='digiLogin' style={{ display: 'flex' }}>
                      <input className="digiPartLoginInput" type='file' accept='application/pdf' name="dlpdfWholeSaler" id='dlpdfWholeSaler' disabled={isEdit ? false : true} placeholder='Attach PDF DL'
                        onChange={changeFileHandler} />
                      <iframe id="dlWSpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][42]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][42]]}` : ''} ></iframe>
                    </div>
                  </div>
                </div>
                : ''
              }
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div id="retail" style={{ width: '45%' }}>
          <div>Retailers Drug License No </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='digiLogin' style={{ width: '45%' }}>
            <input className="digiPartLoginInput" type='text' name="dlRetailer1" id='dlRetailer1' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][41]).split('___')[0] : ''} placeholder='Drug License No1' onChange={() => removeErrorMessage("dlRetailer1")} />
          </div>
          <div className='digiLogin' style={{ width: '45%' }}>
            <input className="digiPartLoginInput" type='text' name="dlRetailer2" id='dlRetailer2' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][41]).split('___')[1] : ''} placeholder='Drug License No2' onChange={() => removeErrorMessage("dlRetailer2")} />
          </div>
          </div>
        </div>
        <div id="retail2" style={{ width: '45%' }}>
          <div>Attached Document </div>
          <div className='digiLogin' style={{ display: 'flex' }}>
          <input className="digiPartLoginInput" type='file' accept='application/pdf' name="dlpdfRetailer" id='dlpdfRetailer'
            onChange={changeFileHandler} disabled={isEdit ? false : true} placeholder='Attach PDF DL' />
          <iframe id="dlRetpdfPreview" width="60" height="50" src = { profileDocuments && profileDocuments[profileData[0][41]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][41]]}` : ''} ></iframe>
          </div>
        </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div id="wholesaler" style={{ width: '45%' }}>
          <div>WholeSalers Drug License No </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='digiLogin' style={{ width: '45%' }}>
            <input className="digiPartLoginInput" type='text' name="dlWholeSaler1" id='dlWholeSaler1' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][42]).split('___')[0] : ''} placeholder='Drug License No1' onChange={() => removeErrorMessage("dlWholeSaler1")} />
          </div>
          <div className='digiLogin' style={{ width: '45%' }}>
            <input className="digiPartLoginInput" type='text' name="dlWholeSaler2" id='dlWholeSaler2' disabled={isEdit ? false : true} defaultValue={profileData ? String(profileData[0][42]).split('___')[1] : ''} placeholder='Drug License No2' onChange={() => removeErrorMessage("dlWholeSaler2")} />
          </div>
          </div>
        </div>
        <div id="wholesaler2" style={{ width: '45%' }}>
          <div>Attached Document </div>
          <div className='digiLogin' style={{ display: 'flex' }}>
          <input className="digiPartLoginInput" type='file' accept='application/pdf' name="dlpdfWholeSaler" id='dlpdfWholeSaler' disabled={isEdit ? false : true} placeholder='Attach PDF DL'
            onChange={changeFileHandler} />
          <iframe id="dlWSpdfPreview" width="60" height="50" src = { profileDocuments && profileDocuments[profileData[0][42]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][42]]}` : ''} ></iframe>
          </div>
        </div>
        </div> */}

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                <div className='digiLogin' style={{ width: '45%' }}>
                  <input className="digiPartLoginInput" type='text' name="regLicence" id='regLicence' placeholder='Establishment Certificate Number' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][40] : ''} onChange={() => removeErrorMessage("regLicence")} />
                </div>
                <div className='digiLogin' style={{ width: '45%', display: 'flex' }}>
                  <input className="digiPartLoginInput" type='file' accept='application/pdf' name="regLicencepdf" id='regLicencepdf' placeholder='Attach PDF Registration'
                    onChange={changeFileHandler} disabled={isEdit ? false : true} />
                  <iframe id="regpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][40]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][40]]}` : ''}></iframe>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                <div className='digiLogin' style={{ width: '45%' }}>
                  <input className="digiPartLoginInput" type='text' name="gst" id='gst' placeholder='GST Number' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][44] : ''} onChange={() => removeErrorMessage("gst")} />
                </div>
                <div className='digiLogin' style={{ width: '45%', display: 'flex' }}>
                  <input className="digiPartLoginInput" type='file' accept='application/pdf' name="GSTpdf" id='GSTpdf' placeholder='Attach PDF GST'
                    onChange={changeFileHandler} disabled={isEdit ? false : true} />
                  <iframe id="gstpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][44]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][44]]}` : ''}></iframe>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '45%' }}>
                  <input className="digiPartLoginInput" type='text' name="pan" id='pan' placeholder='PAN Number' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][45] : ''} onChange={() => removeErrorMessage("pan")} />
                </div>
                <div className='digiLogin' style={{ width: '45%', display: 'flex' }}>
                  <input className="digiPartLoginInput" type='file' accept='application/pdf' name="PANpdf" id='PANpdf' placeholder='Attach PDF PAN'
                    onChange={changeFileHandler} disabled={isEdit ? false : true} />
                  <iframe id="panpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][45]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][45]]}` : ''}></iframe>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='digiLogin' style={{ width: '45%' }}>
                  <input className="digiPartLoginInput" type='text' name="fssai" id='fssai' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][43] : ''} placeholder='FSSAI Number' />
                </div>
                <div className='digiLogin' style={{ width: '45%', display: 'flex' }}>
                  <input className="digiPartLoginInput" type='file' accept='application/pdf' name="FSSAIpdf" id='FSSAIpdf' placeholder='Attach PDF FSSAI'
                    onChange={changeFileHandler} disabled={isEdit ? false : true} />
                  <iframe id="fssaipdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][43]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][43]]}` : ''}></iframe>
                </div>
              </div>

              <div>
                <div>Bank Details</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                  <div style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                    <div className='digiLogin' style={{ width: '45%' }}>
                      <input className="digiPartLoginInput" type='text' name="banckAccount" id='banckAccount' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][50] : ''} placeholder='Bank A/c Number' onChange={() => removeErrorMessage("banckAccount")} />
                    </div>
                    <div className='digiLogin' style={{ width: '45%', }}>
                      <input className="digiPartLoginInput" type='text' name="bankIFSC" id='bankIFSC' placeholder='BANK IFSC' disabled={isEdit ? false : true} defaultValue={profileData ? profileData[0][51] : ''} onChange={() => removeErrorMessage("bankIFSC")} />
                    </div>
                  </div>

                  <div className='digiLogin' style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                    <input className="digiPartLoginInput" type='file' accept='application/pdf' name="BANKpdf" id='BANKpdf' placeholder='Attach PDF Cancelled CHECQUE'
                      onChange={changeFileHandler} disabled={isEdit ? false : true} />
                    <iframe id="bankpdfPreview" width="60" height="50" src={profileDocuments && profileDocuments[profileData[0][50] + '_' + profileData[0][51]] !== "None" ? `data:application/pdf;base64,${profileDocuments[profileData[0][50] + '_' + profileData[0][51]]}` : ''}></iframe>
                  </div>
                </div>
              </div>
            </div>

            {isEdit ?
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '2%' }}>
                  <input type='checkbox' name="confirm" id="confirm" style={{ marginTop: '.5%' }} /> &nbsp;&nbsp;
                  <label for="confirm">By proceeeding, you are agreeing to share your information with SHEET360 Technology Private Limited. It will remain confidential and used for business transaction purposes only.</label>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className='digiNavBtn' style={{ width: '20%', textAlign: 'center' }} onClick={() => verifyProfile()}>Save</div>
                </div>
              </>
              :
              ''}

            {/* <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-start', marginTop:'2%'}}>
      <input type='checkbox' name="confirm" id="confirm" style={{marginTop:'.5%'}}/> &nbsp;&nbsp;
      <label for="confirm">By proceeeding, you are agreeing to share your information with SHEET360 Technology Private Limited. It will remain confidential and used for business transaction purposes only.</label>
      </div> */}
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className='digiNavBtn' style={{ width: '20%', textAlign: 'center' }} onClick={() => verifyProfile()}>Save</div>
      </div> */}
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default UserReportAdmin