import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import defProdImage from '../../static/images/Icons/defproducts.png';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { ReactSession } from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import { LuSearch } from 'react-icons/lu';

const ManageCoupons = () => {
  const navigate = useNavigate();

  // States
  const [listRec, setListRec] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [defCate, setDefCate] = useState(null);
  const [couponData, setCouponData] = useState(null);
  const [couponImages, setCouponImages] = useState(null);

  const [seleCate, setSeleCate] = useState();
  const handleSelect = (e) => {
    setSeleCate(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  const [cateOptions, setCateOptions] = useState([{ value: '', label: 'Select Category' },]);
  const [cityOptions, setCityOptions] = useState([{ value: '', label: 'None' }]);
  const [selectedCity, setCity] = useState();
  const [filCity, setFilterCity] = useState();
  const [page, setPage] = useState(1);;
  const [pagesize, setPageSize] = useState(25);
  const [noofRec, setNoofRec] = useState(0);

  const handleSelectCity = e => {
    setCity(Array.isArray(e) ? e.map(x => x.value) : []);
  }


  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  // Add Coupon Modal Declaration
  const [addCoupons, setShowAddCoupons] = useState(false);
  const AddCouponsClose = () => setShowAddCoupons(false);
  const AddCouponsShow = (coupToEdit = null, couponImg = null) => {
    if (coupToEdit !== null) {
      setListRec(coupToEdit);

      if (couponImg != null)
        setCurrImage(`data:image/jpeg;base64,${couponImg}`);
      else
        setCurrImage(null);
      // setListRec(coupToEdit); 

      var catList = String(coupToEdit[5]).split(",");
      var defCats = [];
      var filCatID = "";
      for (let i = 0; i < catList.length; i++) {
        filCatID = cateOptions.filter(cate => {
          return String(cate.value) === String(catList[i]);
        });
        defCats.push(filCatID[0]);
      }
      setDefCate(defCats);
      setSeleCate(coupToEdit[5]);

      var cityList = String(coupToEdit[8]).split(",");
      var defCities = [];
      var filCitiesID = "";
      for (let i = 0; i < cityList.length; i++) {
        filCitiesID = cityOptions.filter(city => {
          return String(city.value) === String(cityList[i]);
        });
        defCities.push(filCitiesID[0]);
        // console.log(filCitiesID[0]);
      }
      setFilterCity(defCities);
      setCity(coupToEdit[8]);
    }
    else {
      setCurrImage(null);
      setSelectedFile(null);
      setListRec(null);
      setDefCate(null);
      setSeleCate(null);
      setFilterCity(null);
      setCity(null);
    }
    setShowAddCoupons(true);
    // console.log(coupToEdit);
  }


  // Page Navigattion Methods
  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    // console.log("Revious Clicked :" + page);
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    // console.log("Next Clicked ...");
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }

  // Utility Methods
  const handleSubmit = () => {
    // event.preventDefault();

    var errorElements = [];
    if (document.getElementById('coupName').value === "")
      errorElements.push("coupName");

    if (document.getElementById('coupDispName').value === "")
      errorElements.push("coupDispName");

    if (document.getElementById('coupDiscount').value === "")
      errorElements.push("coupDiscount");
    else if (isNaN(document.getElementById('coupDiscount').value)) {
      document.getElementById("errorMessage").innerHTML = "Discount must be numeric";
      errorElements.push("coupDiscount");
    }

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
    }
    else {
      var responseMsg = "One coupon added successfully"
      // co_code, co_name, co_disp_name, co_discount, co_image, cat_Id, min_amt, max_amt, city_applied, IsActive, is_multi_use, created_at ,updated_at ;
      let formData = new FormData();    //formdata object
      if (listRec != null) {
        formData.append('co_code', listRec[0]);
        formData.append('fileName', listRec[4])
        responseMsg = "One coupon updated successfully"
      }
      // co_code fileName File coupName coupDispName coupDiscount categories cities isActiveCoupon isMultiUse minlimit maxlimit
      formData.append('File', selectedFile);
      formData.append('coupName', document.getElementById('coupName').value);
      formData.append('coupDispName', document.getElementById('coupDispName').value);
      formData.append('coupDiscount', document.getElementById('coupDiscount').value);
      formData.append('categories', seleCate);
      formData.append('cities', selectedCity);
      formData.append('isActiveCoupon', document.getElementById('isActiveCoupon').checked ? "1" : "0");
      formData.append('isMultiUse', document.getElementById('isMultiUse').checked ? "1" : "0");
      formData.append('minlimit', document.getElementById('minlimit').value);
      formData.append('maxlimit', document.getElementById('maxlimit').value);
      formData.append('termsConditions', document.getElementById('termsConditions').value);


      fetch('http://35.154.53.53/manageCouponsAPI/', {
        mode: 'cors',
        method: 'POST',
        body: formData,
      }).then((response) => response.json())
        .then((result) => {
          alert(responseMsg);
          AddCouponsClose();
          // GetAllCoupons();
        }).catch((error) => {
          console.error('Error:', error);
        });
    }
  }


  const [selectedFile, setSelectedFile] = useState(null);
  const changeHandler = (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('preview');
      output.src = reader.result;
    };
    reader.readAsDataURL(file);
    setSelectedFile(file);
  };


  const searchOrder = () => {
    GetAllCoupons();
  }

  const deleteRecord = (coCode) => {
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      fetch('http://35.154.53.53/manageCouponsAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          co_code: coCode,
        })
      }).then((response) => {
        response.json();
        // GetAllCoupons();
      })
        .then((data) => {
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }

  const GetAllCoupons = () => {
    var stxt = document.getElementById('sertext').value;
    fetch("http://35.154.53.53/manageCouponsAPI?page=" + page + "&pagesize=" + pagesize + "&SearText=" + stxt, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      setCouponImages(resp.files);
      setCateOptions(resp.proCateD);
      setCityOptions(resp.citiesD);
      setCouponData(resp.data);

      setNoofRec(resp.noofRec);
      document.getElementById('pageNo').placeholder = page;
    });
  }

  useEffect(() => {
    if (!ReactSession.get('adminpillsit')) {
      navigate('/Admin');
    }
    else {
      GetAllCoupons();
    }
  }, [page]);

  return (
    <div>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          <div className='pendOrders' style={{position:"unset",width:"100%",paddingTop:10}}>
            <Row style={{flexWrap:"nowrap",display:"flex",alignItems:"center",width:"100%"}}>
              <Col style={{width:"65%"}} xl={8} sm={6} xs={6}>
                <h3 style={{fontSize:30,fontWeight:600,marginLeft:20}}>Manage Coupons</h3>
              </Col>
              <Col style={{ position:"relative",paddingLeft: 0, paddingRight: 0, marginBottom:5,display:"flex",alignItems:"center",background:"#E8E8E8",height:"fit-content",marginRight:50,borderRadius:10 }} md={5} lg={4} xl={4} sm={6} xs={6}>
                <input placeholder='Search Here...' style={{ width: '75%',background:"#E8E8E8",border:"none",padding:"10px",flex:1,border:0,outline:0,borderRadius:10 }} type='text' name="sertext" id="sertext" defaultValue={''} onKeyDown={(e) => { if (e.key === "Enter") { searchOrder(); } }} />
                <i style={{position:"absolute",fontSize:20,display:"flex",alignItems:"center",justifyContent:"center",right:0,top:0,width:70,minHeight:"100%",background:"#000",color:"#fff",borderTopRightRadius:10,borderBottomRightRadius:10}}><LuSearch /></i>
              </Col>
            </Row>
            {/* <Row className="OrderPagination" style={{ fontSize: '115%' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={12} lg={12} xl={12} sm={12} xs={12}>
                <button className="pageNav" onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
                <button className="pageNav" onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
                <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
                <button className="pageNav " onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
                <button className="pageNav" onClick={() => lastPage()} >Bottom</button>
              </Col>
            </Row> */}
            <Row className="getDataHeader" style={{width:"96%",margin:"0px auto",marginLeft:18}}>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={2} lg={2} xl={2} sm={6} xs={6}>
                ID : Coupon Name
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={2} lg={2} xl={2} sm={6} xs={6}>
                Display Name
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Logo
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Discount
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Categories
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={2} lg={2} xl={2} sm={6} xs={6} >
                Min/Max Amt
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Cities
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={1} lg={1} xl={1} sm={6} xs={6}>
                IsActive
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Action
              </Col>
            </Row>
          </div>
          <div className='allPenOrders couponContainer' id='couponContainer' style={{ marginTop: '0rem', zIndex: '0' }}>
            {couponData ?
              couponData.map((coupon, index) => {
                var base64Image = couponImages[coupon[4]];
                const catsUnique = [...new Set(coupon[14].split(','))].join(", ");
                const cityUnique = [...new Set(coupon[15].split(','))].join(", ");
                return <>
                  <Row className='pendOrderRows' style={{ marginTop: '.3rem',width:"96%",margin:"0px auto",marginLeft:18}}>

                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6}>
                      {coupon[0]} {': '} {coupon[1]}
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center",color:"#000",fontWeight:700 }} md={2} lg={2} xl={2} sm={6} xs={6}>
                      {coupon[2]}
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                      <img src={`data:image/jpeg;base64,${base64Image}`} style={{ width: '100%' }} onError={(e) => { e.target.src = defProdImage }} alt="" />
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                      {coupon[3]}
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                      {catsUnique}
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                      {coupon[6]} {'/'} {coupon[7]}
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                      {cityUnique}
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6}>
                      {String(coupon[9]) === '1' ?
                        <input type='checkbox' name='isActiveCat' id='isActiveCat' disabled defaultChecked />
                        :
                        <input type='checkbox' name='isActiveCat' id='isActiveCat' disabled />
                      }
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                      <img style={{width:25,height:25,cursor:"pointer"}} className="catActionBtn2" onClick={() => { AddCouponsShow(coupon, couponImages[coupon[4]]) }} src={require('../../static/images/edit.png')} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <img style={{width:25,height:25,cursor:"pointer"}} className="catActionBtn2" onClick={() => deleteRecord(coupon[0])} src={require('../../static/images/trash.png')} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                    </Col>
                  </Row>
                  
                </>
              })
              : ''
            }
          </div>
          <div className='addNewCat' style={{border:0,outline:0}} onClick={() => { AddCouponsShow() }}>
            <img style={{width:"70px",height:"70px",borderRadius:"50%"}} src={require('../../static/AdminPanelImages/add.gif')} alt="" />
          </div>
          <div className='copyRight'>
            Pillsit Copyright &copy; 2023:  All Right Reserved
          </div>
        </div>
      </div>

      {/* Add/Edit Coupon Modal */}
      {/* co_code, co_name, co_disp_name , co_discount, co_image , cat_Id , min_amt ,max_amt , city_applied , IsActive, created_at ,updated_at, categories, cities ; */}

      <Modal show={addCoupons} onHide={AddCouponsClose} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>{listRec != null ? "Editing " : "Add New"} Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          <Form onSubmit={handleSubmit} >
            <Row style={{ paddingLeft: 20, paddingRight: 20, marginTop: -10 }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2,fontSize:14,color:"gray" }} lg={4} md={4} xl={4} sm={6} xs={12}>
                Coupon <br />
                <input type="text" onKeyPress={() => { removeErrorMessage("coupName") }}
                  defaultValue={listRec != null ? listRec[1] : ""}
                  name="coupName" id="coupName" placeholder='Coupon Name' style={{ width: '100%',padding:5 }} />
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2,fontSize:14,color:"gray" }} lg={4} md={4} xl={4} sm={6} xs={12}>
                Display Name <br />
                <input type="text" onKeyPress={() => { removeErrorMessage("coupDispName") }}
                  defaultValue={listRec != null ? listRec[2] : ""}
                  name="coupDispName" id="coupDispName" placeholder='Coupon Display Name' style={{ width: '100%',padding:5 }} />
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2,fontSize:14,color:"gray" }} lg={4} md={4} xl={4} sm={6} xs={12}>
                Discount %<br />
                <input type="text" onKeyPress={() => { removeErrorMessage("coupDiscount") }}
                  defaultValue={listRec != null ? listRec[3] : ""}
                  name="coupDiscount" id="coupDiscount" placeholder='Discount Percentage' style={{ width: '100%',padding:5 }} />
              </Col>
            </Row>

            <Row style={{ marginTop: 5, paddingLeft: 20, paddingRight: 20,fontSize:14,color:"gray" }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} md={3} lg={3} xl={3} sm={6} xs={12}>
                Is Active < br />
                {listRec !== null ? String(listRec[9]) === '1' ?
                  <input type='checkbox' name='isActiveCoupon' id='isActiveCoupon' defaultChecked />
                  :
                  <input type='checkbox' name='isActiveCoupon' id='isActiveCoupon' />
                  :
                  <input type='checkbox' name='isActiveCoupon' id='isActiveCoupon' />
                }
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2,fontSize:14,color:"gray" }} md={3} lg={3} xl={3} sm={6} xs={12}>
                Is Multi use < br />
                {listRec !== null ? String(listRec[10]) === '1' ?
                  <input type='checkbox' name='isMultiUse' id='isMultiUse' defaultChecked />
                  :
                  <input type='checkbox' name='isMultiUse' id='isMultiUse' />
                  :
                  <input type='checkbox' name='isMultiUse' id='isMultiUse' />
                }
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2, marginTop: 5, maxHeight: '2rem',fontSize:14,color:"gray" }} lg={6} md={6} xl={6} sm={6} xs={12}>
                <label for="newCatImage" className='selectPinBTN'>Select a picture</label>
                <span >&nbsp;&nbsp;&nbsp;&nbsp; </span><img style={{ minHeight: 50, minWidth: 50 }} id="preview" src={currImage != null ? currImage : ''} alt="" />
                <input type="file" name="newCatImage" id="newCatImage" onChange={changeHandler} accept="image/*"
                  style={{ visibility: 'hidden' }} />
              </Col>
            </Row>

            <Row style={{ borderBottomColor: 'gray', borderBottomWidth: 2, borderBottomStyle: 'solid', marginLeft: 10, marginRight: 10,fontSize:14,color:"gray" }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={11} lg={11} xl={11} sm={11} xs={11}>
                Criterian
              </Col>
            </Row>
            <Row style={{ paddingLeft: 20, paddingRight: 20, marginTop: -10,fontSize:14,color:"gray" }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} lg={12} md={12} xl={12} sm={12} xs={12}>
                <div className="addProddropDown">
                  Categories <Select isMulti options={cateOptions} id="cat_idDD"
                    defaultValue={defCate} onChange={handleSelect} placeholder="Select Category" />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingLeft: 20, paddingRight: 20, marginTop: -10,fontSize:14,color:"gray" }} >
              <Col style={{ paddingLeft: 2, paddingRight: 2, }} lg={12} md={12} xl={12} sm={12} xs={12}>
                <div className="addProddropDown">
                  Cities <Select isMulti options={cityOptions} id="cityApplicable" defaultValue={filCity}
                    onChange={handleSelectCity} placeholder="Select City" />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20,fontSize:14,color:"gray" }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} md={4} lg={4} xl={4} sm={12} xs={12}>
                <div>Billing range</div>
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2,fontSize:14,color:"gray" }} md={4} lg={4} xl={4} sm={12} xs={12}>
                <label>Min value&nbsp;&nbsp;</label>
                <input name="minlimit" id="minlimit" type="text" size="4" maxLength="10"
                style={{padding:5}}
                  //  onKeyPress={() => { alert("")}}
                  defaultValue={listRec != null ? listRec[6] : ""} placeholder="Min limit" />
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2,fontSize:14,color:"gray" }} md={4} lg={4} xl={4} sm={12} xs={12}>
                <label>Max value&nbsp;&nbsp;</label>
                <input name="maxlimit" id="maxlimit" type="text" size="4" maxLength="10"
                style={{padding:5}}
                  //  onKeyPress={() => { alert("")}}
                  defaultValue={listRec != null ? listRec[7] : ""} placeholder="Max limit" />
              </Col>
            </Row>

            <Row style={{ marginTop: 10, marginBottom: '.3rem', paddingLeft: 20, paddingRight: 20 }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} lg={12} md={12} xl={12} sm={12} xs={12} >
                <textarea name="termsCond" id="termsConditions" maxLength={1000} style={{ resize: 'none', width: '100%',padding:10 }} placeholder='Terms & conditions'>{listRec != null ? listRec[11] : ""}</textarea>
              </Col>
            </Row>

            <Row style={{ marginTop: 10, marginBottom: '.3rem', textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2,width:"100%" }} lg={11} md={11} xl={11} sm={11} xs={11} >
                <button type='submit' style={{ width: '10rem',borderRadius:8,background:"#00BAFF",border:0,outline:0 }} className='btn btn-primary btn-sm'>{listRec != null ? "Update " : "Add "}</button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default ManageCoupons