import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Select from 'react-select';

ReactSession.setStoreType("localStorage");

const ProfitAndLoss = () => {
  const navigate = useNavigate();

  const ledgerItem = useRef(0);
  const [ledgerAccounts, setLedgerAccounts] = useState(null);
  const [debitTotal, setDebitTotal] = useState(0);
  const [creditTotal, setCreditTotal] =  useState(0);
  const [ledgerGroupDetails, setLedgerGroupDetails] = useState(null);
  const [ledgerDetails, setLedgerDetails] = useState(null);

  const debitSide = ['10', '12', '13', '14', '18', '23', '25'];
  const creditSide = ['11', '16', '17', '19', '27', '28'];
  var ledgerTotal = 0;

  var ledDebitTot = 0;
  var ledCreditTot =0

  const groupBy = () =>{
    // const data = [
    //   ['A', 10, 5],
    //   ['B', 20, 10],
    //   ['A', 30, 15],
    //   ['B', 40, 20],
    //   // ... more data
    // ];
    
    // // Grouping by the first element and summing the second and third elements in each sub-array
    // console.log("Before group :",data);
    // const groupedData = data.reduce((acc, arr) => {
    //   const key = arr[0];
    //   if (!acc[key]) {
    //     acc[key] = { sumColumn2: 0, sumColumn3: 0, data: [] };
    //   }
    //   acc[key].sumColumn2 += arr[1];
    //   acc[key].sumColumn3 += arr[2];
    //   acc[key].data.push(arr);
    //   return acc;
    // }, {});
    
    const groupedData = ledgerAccounts.reduce((acc, arr) => {
      const key = arr[7];
      if (!acc[key]) {
        acc[key] = { amtDr: 0, amtCr: 0, data: [] };
      }      
      acc[key].amtDr += arr[4];
      acc[key].amtCr += arr[5];
      acc[key].data.push(arr);
      return acc;
    }, {});
    // console.log(groupedData);
  } 

  const getLedgerDetails = (ledgerID) =>{
    ledDebitTot = 0;
    ledCreditTot = 0;
    fetch("http://35.154.53.53/digiPartnerAPI?ledgerID="+ledgerID+"&user_Id=" + ReactSession.get("id"), {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((resp) => {
      return resp.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else
      {
        setLedgerDetails(resp.ledgerDetails);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  const getLedgerGroupDetails = (ledgerGroupID) =>{
    fetch("http://35.154.53.53/digiPartnerAPI?ledgerGroupID="+ledgerGroupID+"&user_Id=" + ReactSession.get("id"), {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((resp) => {
      return resp.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else
      {
        setLedgerGroupDetails(resp.ledgerGroupDetails);
        ledgerTotal = 0;
        ledgerItem.current.focus() ;
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  const generateProfitAndLoss = () =>{
    // ledgerAccount=1

    fetch("http://35.154.53.53/digiPartnerAPI?ledgerAccount=1&user_Id=" + ReactSession.get("id"), {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((resp) => {
      return resp.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else
      {
        setDebitTotal(resp.PnLDebit);
        setCreditTotal(resp.PnLCredit);
        setLedgerAccounts(resp.ledgerAccounts);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) =>{
    if (value)
      {
        document.getElementById('DigiDashPanel').style.display = 'block';
        document.getElementById('dpSidePanelBTN').style.left='75vw';
      }
    else
    {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left='0px';
    }
    setMenuCollapse(value);
  }
  
  useEffect(()=>{
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          generateProfitAndLoss();
        }
  }, []);

  
  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer'>
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>

        <div className='DigiDashContent' id='DigiDashContent' style={{ paddingInline: '2%', backgroundColor: 'white' }}>
          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={()=>CollapseMenu(!menuCollapse)}>
            { menuCollapse ? 
            <img src={require('../../static/images/Icons/left-arrow.png')} alt=''/>
            :
            <img src={require('../../static/images/Icons/right-arrow.png')} alt=''/>
            }
          </div>
        
          { 
            ledgerDetails && ledgerDetails !== null ? 
            <>
              {/* Ledger Debit Credit Account */}
              <div className='VoucherHeader' style={{display:'block', textAlign:'center', marginBottom:'0px'}}>
              <div onClick={()=>setLedgerDetails(null)}>Back</div>
              <div style={{fontSize:'2.5vw', background:'lightgrey', textAlign:'center'}}>{ledgerDetails ? ledgerDetails[0][6] : ''} </div>
                <div style={{fontSize:'80%'}}>
                  For the year ended 31st March
                </div>
              </div>
              
              {/* Header */}
              <div style={{display:'flex', fontSize:'90%', fontWeight:'bold', backgroundColor:'lightgrey', borderBottom:'1px solid gray'}}>
                <div style={{width:'50%', display:'flex', justifyContent:'space-between', paddingInline:'5px', borderLeft:'1px solid gray'}}>
                  <div style={{width:'20%', textAlign:'center'}}>Date</div>
                  <div style={{width:'60%', textAlign:'center', borderLeft:'1px solid gray'}}>Particulars</div>
                  <div style={{width:'20%', textAlign:'center', borderLeft:'1px solid gray'}}>Amount</div>
                </div>

                <div style={{width:'50%' , display:'flex', justifyContent:'space-between', paddingInline:'5px', borderInline:'1px solid gray'}}>
                  <div style={{width:'20%', textAlign:'center'}}>Date</div>
                  <div style={{width:'60%', textAlign:'center', borderLeft:'1px solid gray'}}>Particulars</div>
                  <div style={{width:'20%', textAlign:'center', borderLeft:'1px solid gray'}}>Amount</div>
                </div>
              </div>

              {/* Particulars */}
              <div style={{display:'flex',fontSize:'80%', borderBottom:'1px solid gray'}}>
                {/* Debit Section */}
                <div id = "ledDebit" style={{ width:'50%', paddingInline:'5px', borderInline:'1px solid gray' , borderCollapse:'collapse'}}>
                  { ledgerDetails && ledgerDetails != null ?
                    ledgerDetails.map((ledDet, index)=>{
                      if (ledDet[11] === 'To')
                        ledDebitTot = ledDebitTot + parseFloat(ledDet[10]) - parseFloat(ledDet[9]);
                      else
                        ledCreditTot = ledCreditTot + parseFloat(ledDet[9]) - parseFloat(ledDet[10]);

                      if (ledDet[11] === 'To')
                        return (
                          <div className='accountHead' tabIndex={index} ref={ledgerItem} 
                            // onClick={()=>getLedgerGroupDetails(ledHead[6])} 
                            onKeyDown={(e)=>{
                            if (e.key === "ArrowUp") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.previousElementSibling) {
                                focusedInput.previousElementSibling.focus();
                              }
                            }
                            else
                              if (e.key === "ArrowDown") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.nextElementSibling) {
                                  focusedInput.nextElementSibling.focus();
                                }
                              }
                              else
                              if (e.key === "ArrowRight"){
                                var rightElement = document.getElementById("ledCredit");
                                rightElement?.firstChild.focus();
                              }
                              else
                              if (e.key === "Enter")
                              {
                                // getLedgerGroupDetails(ledHead[6]);
                              }
                          }}>
                            <div style={{width:'20%'}}>{ledDet[4]}</div>
                            <div style={{width:'60%', borderLeft:'1px solid gray'}}>To {ledDet[8]}</div>
                            <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(ledDet[10]) - parseFloat(ledDet[9])}</div>
                          </div>  
                        )
                      else
                        return false
                    })
                  :''
                  }

                  { Number(ledDebitTot) < Number(ledCreditTot) ?

                    <div style={{ display:'flex', justifyContent:'space-between'}}>
                      <div style={{width:'20%'}}></div>
                      <div style={{width:'60%', borderLeft:'1px solid gray'}}>Balance c/d</div>
                      <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(ledCreditTot) - parseFloat(ledDebitTot)}</div>
                    </div>
                  :''
                  }
                </div>

                {/* Credit Section */}
                <div id = "ledCredit" style={{ width:'50%', paddingInline:'5px', borderInline:'1px solid gray', borderCollapse:'collapse' }}>
                { ledgerDetails && ledgerDetails != null ?
                    ledgerDetails.map((ledDet, index)=>{
                      if (ledDet[11] === 'By')
                        return (
                          <div className='accountHead' tabIndex={index} ref={ledgerItem} 
                            // onClick={()=>getLedgerGroupDetails(ledHead[6])} 
                            onKeyDown={(e)=>{
                            if (e.key === "ArrowUp") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.previousElementSibling) {
                                focusedInput.previousElementSibling.focus();
                              }
                            }
                            else
                              if (e.key === "ArrowDown") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.nextElementSibling) {
                                  focusedInput.nextElementSibling.focus();
                                }
                              }
                              else
                              if (e.key === "ArrowLeft"){
                                var leftElement = document.getElementById("ledDebit");
                                leftElement?.firstChild.focus();
                              }
                              else
                              if (e.key === "Enter")
                              {
                                // getLedgerGroupDetails(ledHead[6]);
                              }
                          }}>
                            <div style={{width:'20%'}}>{ledDet[4]}</div>
                            <div style={{width:'60%', borderLeft:'1px solid gray'}}>To {ledDet[8]}</div>
                            <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(ledDet[9]) - parseFloat(ledDet[10])}</div>
                          </div>  
                        )
                      else
                        return false
                    })
                  :''
                  }

                  { Number(ledDebitTot) > Number(ledCreditTot) ?
                  <div style={{ display:'flex', justifyContent:'space-between'}}>
                    <div style={{width:'20%'}}></div>
                    <div style={{width:'60%', borderLeft:'1px solid gray'}}>Balance c/d</div>
                    <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(ledDebitTot) - parseFloat(ledCreditTot)}</div>
                  </div>
                  :''
                  }

                </div>
              </div>

              {/* Ledger Debit Credit Totals */}
              <div style={{display:'flex', fontSize:'90%', fontWeight:'bold', backgroundColor:'lightgrey', borderBottom:'1px solid gray'}}>
                <div style={{width:'50%', display:'flex', justifyContent:'space-between', paddingInline:'5px', borderLeft:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Totals</div>
                  <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>
                    { Number(ledDebitTot) > Number(ledCreditTot) ?
                      ledDebitTot
                    : ledCreditTot
                    }
                  </div>
                </div>

                <div style={{width:'50%' , display:'flex', justifyContent:'space-between', paddingInline:'5px', borderInline:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Totals</div>
                  <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>
                  { Number(ledDebitTot) > Number(ledCreditTot) ?
                      ledDebitTot
                    : ledCreditTot
                    }
                  </div>
                </div>
              </div>
            </>
            :
            ledgerGroupDetails === null ? 
            <>
              <div className='VoucherHeader' style={{display:'block', textAlign:'center', marginBottom:'0px'}}>
                <div>
                  Profit & Loss A/c
                </div>
                <div style={{fontSize:'80%'}}>
                  For the year ended 31st March
                </div>
              </div>
              
              {/* Header */}
              <div style={{display:'flex', fontSize:'90%', fontWeight:'bold', backgroundColor:'lightgrey', borderBottom:'1px solid gray'}}>
                <div style={{width:'50%', display:'flex', justifyContent:'space-between', paddingInline:'5px', borderLeft:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Particulars</div>
                  <div style={{width:'20%', textAlign:'center', borderLeft:'1px solid gray'}}>Amount</div>
                </div>

                <div style={{width:'50%' , display:'flex', justifyContent:'space-between', paddingInline:'5px', borderInline:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Particulars</div>
                  <div style={{width:'20%', textAlign:'center', borderLeft:'1px solid gray'}}>Amount</div>
                </div>
              </div>

              {/* Particulars */}
              <div style={{display:'flex',fontSize:'80%', borderBottom:'1px solid gray'}}>
                {/* Debit Section */}
                <div id = "pnlDebit" style={{ width:'50%', paddingInline:'5px', borderInline:'1px solid gray' , borderCollapse:'collapse'}}>
                  { ledgerAccounts && ledgerAccounts != null ?
                    ledgerAccounts.map((ledHead, index)=>{
                      
                      if (String(ledHead[6]) === '10' || String(ledHead[6]) ===  '12' || String(ledHead[6]) ===  '13' || String(ledHead[6]) ===  '14' || String(ledHead[6]) === '18' || String(ledHead[6]) ===  '23' || String(ledHead[6]) ===  '25')
                        return (
                          <div className='accountHead' tabIndex={index} ref={ledgerItem} onClick={()=>getLedgerGroupDetails(ledHead[6])} 
                          onKeyDown={(e)=>{
                            if (e.key === "ArrowUp") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.previousElementSibling) {
                                focusedInput.previousElementSibling.focus();
                              }
                            }
                            else
                              if (e.key === "ArrowDown") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.nextElementSibling) {
                                  focusedInput.nextElementSibling.focus();
                                }
                              }
                              else
                              if (e.key === "ArrowRight"){
                                var rightElement = document.getElementById("pnlCredit");
                                rightElement?.firstChild.focus();
                              }
                              else
                              if (e.key === "Enter")
                              {
                                getLedgerGroupDetails(ledHead[6]);
                              }
                          }}>
                            <div style={{width:'80%'}}>To {ledHead[7]}</div>
                            <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(ledHead[4]) - parseFloat(ledHead[5])}</div>
                          </div>  
                        )
                      else
                        return false
                    })
                  :''
                  }

                  { Number(debitTotal) < Number(creditTotal) ?

                    <div className='accountHead' style={{ display:'flex', justifyContent:'space-between'}}>
                      <div style={{width:'80%'}}>To Net Profit</div>
                      <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(creditTotal) - parseFloat(debitTotal)}</div>
                    </div>
                  :''
                  }


                </div>

                {/* Credit Section */}
                <div id = "pnlCredit" style={{ width:'50%', paddingInline:'5px', borderInline:'1px solid gray', borderCollapse:'collapse' }}>
                  { ledgerAccounts && ledgerAccounts != null ?
                    ledgerAccounts.map((ledHead, index)=>{
                      
                      if (String(ledHead[6]) === '11' || String(ledHead[6]) ===  '16' || String(ledHead[6]) === '17' || String(ledHead[6]) === '19'  || String(ledHead[6]) === '27' || String(ledHead[6]) ===  '28')
                        return (
                          <div className='accountHead' tabIndex={index} onClick={()=>getLedgerGroupDetails(ledHead[6])} onKeyDown={(e)=>{
                            if (e.key === "ArrowUp") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.previousElementSibling) {
                                focusedInput.previousElementSibling.focus();
                              }
                            }
                            else
                              if (e.key === "ArrowDown") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.nextElementSibling) {
                                  focusedInput.nextElementSibling.focus();
                                }
                              }
                              else
                              if (e.key === "ArrowLeft"){
                                var leftElement = document.getElementById("pnlDebit");
                                leftElement?.firstChild.focus();
                              }
                              else
                              if (e.key === "Enter")
                              {
                                getLedgerGroupDetails(ledHead[6]);
                              }
                          }}>
                            <div style={{width:'80%'}}>By {ledHead[7]}</div>
                            <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(ledHead[5]) - parseFloat(ledHead[4])}</div>
                          </div>  
                        )
                      else
                        return false
                    })
                  :''
                  }

                  { Number(debitTotal) > Number(creditTotal) ?

                    <div className='accountHead' style={{ display:'flex', justifyContent:'space-between'}}>
                      <div style={{width:'80%'}}>By Net Loss </div>
                      <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>{parseFloat(debitTotal) - parseFloat(creditTotal)}</div>
                    </div>
                    :''
                    }

                </div>
              </div>

              {/* Profit And Loss Totals */}
              <div style={{display:'flex', fontSize:'90%', fontWeight:'bold', backgroundColor:'lightgrey', borderBottom:'1px solid gray'}}>
                <div style={{width:'50%', display:'flex', justifyContent:'space-between', paddingInline:'5px', borderLeft:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Totals</div>
                  <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>
                  { Number(debitTotal) > Number(creditTotal) ?
                  debitTotal
                  : creditTotal
                  }</div>
                </div>

                <div style={{width:'50%' , display:'flex', justifyContent:'space-between', paddingInline:'5px', borderInline:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Totals</div>
                  <div style={{width:'20%', textAlign:'right', borderLeft:'1px solid gray'}}>
                  { Number(debitTotal) > Number(creditTotal) ?
                  debitTotal
                  : creditTotal
                  }
                  </div>
                </div>
              </div>
            </>
            :
            <div style={{margin:'1.5vw', width:'80vw'}}>
              <div onClick={()=>setLedgerGroupDetails(null)}>Back</div>
              <div style={{fontSize:'2.5vw', background:'lightgrey', textAlign:'center'}}>{ledgerGroupDetails ? ledgerGroupDetails[0][7] : ''} </div>
              
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', backgroundColor:'lightgrey', paddingInline:'5px', border:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Particulars</div>
                  <div style={{width:'20%', textAlign:'center', borderLeft:'1px solid gray'}}>Amount</div>
                </div>
                
                {
                  ledgerGroupDetails  && ledgerGroupDetails !== null ? 
                  ledgerGroupDetails.map((ledgerGroup) =>
                  {
                    if (String(ledgerGroup[6]) === '10' || String(ledgerGroup[6]) ===  '12' || String(ledgerGroup[6]) ===  '13' || String(ledgerGroup[6]) ===  '14' || 
                          String(ledgerGroup[6]) === '18' || String(ledgerGroup[6]) ===  '23' || String(ledgerGroup[6]) ===  '25')
                      ledgerTotal = ledgerTotal + (parseFloat(ledgerGroup[10]) - parseFloat(ledgerGroup[11]));
                    else
                      ledgerTotal = ledgerTotal + (parseFloat(ledgerGroup[11]) - parseFloat(ledgerGroup[10]));
                    return(
                      <div className='accountHead' style={{borderInline:'1px solid gray', borderCollapse:'collapse'}}
                      onKeyDown={(e)=>{
                        if (e.key === "ArrowUp") {
                          const focusedInput = document.activeElement;
                          if (focusedInput.previousElementSibling) {
                            focusedInput.previousElementSibling.focus();
                          }
                        }
                        else
                          if (e.key === "ArrowDown") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.nextElementSibling) {
                              focusedInput.nextElementSibling.focus();
                            }
                          }
                          else
                          if (e.key === "Enter")
                          {
                            getLedgerDetails(ledgerGroup[5]);
                          }
                      }} tabIndex={0} autoFocus onClick={()=>getLedgerDetails(ledgerGroup[5])}>
                        <div style={{width:'80%', textAlign:'center'}}>{ledgerGroup[9]}</div>
                        <div style={{width:'20%', textAlign:'center' , borderLeft:'1px solid gray'}}>
                          {
                            String(ledgerGroup[6]) === '10' || String(ledgerGroup[6]) ===  '12' || String(ledgerGroup[6]) ===  '13' || String(ledgerGroup[6]) ===  '14' || 
                            String(ledgerGroup[6]) === '18' || String(ledgerGroup[6]) ===  '23' || String(ledgerGroup[6]) ===  '25' ?
                              Number(ledgerGroup[10] - ledgerGroup[11]).toFixed(2)
                            : Number(ledgerGroup[11] - ledgerGroup[10]).toFixed(2)
                          }
                          </div>
                      </div>
                      )
                  })
                  : ''
                }
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', backgroundColor:'lightgrey', paddingInline:'5px', border:'1px solid gray'}}>
                  <div style={{width:'80%', textAlign:'center'}}>Totals</div>
                  <div style={{width:'20%', textAlign:'center', borderLeft:'1px solid gray'}}>{ledgerTotal}</div>
                </div>
                
            </div>
          }

        </div>
      </div>
    </>
  )
}

export default ProfitAndLoss