import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import { BrowserRouter } from 'react-router-dom';

import Home from './MyRoutes/Home';
import PillsSearch from './MyRoutes/PillsSearch';
import UploadPrescription from './MyRoutes/UploadPrescription';
import MyCart from './MyRoutes/MyCart';
import Admin from './MyRoutes/Admin';
import Products from './MyRoutes/Products';
import DisplayProduct from './MyRoutes/DisplayProduct';
import ShippingAdd from './MyRoutes/ShippingAdd';
import OrderSummary from './MyRoutes/OrderSummary';
import MyOrders from './MyRoutes/MyOrders';
import PrescriptionOrder from './MyRoutes/PrescriptionOrder';
import ViewAllOrders from './MyRoutes/ViewAllOrders';
import DispOrderDetailsAdmin from './MyRoutes/DispOrderDetailsAdmin';
import ManageCategories from './MyRoutes/ManageCategories';
import ProductGallery from './MyRoutes/ProductGallery';
import ManageSaltGroups from './MyRoutes/ManageSaltGroups';
import ManageComp from './MyRoutes/ManageComp';
import ManageCoupons from './MyRoutes/ManageCoupons';
import CategoryDiscount from './MyRoutes/CategoryDiscount';
import DigitalPartnerIndex from './DigitalPartner/DigitalPartnerIndex';
import DPDashboard from './DigitalPartner/DPDashboard';
import DPSalesStockState from './DigitalPartner/DPSalesStockState';
import UserReport from './DigitalPartner/UserReport';
import UserReportAdmin from './MyRoutes/UserReportAdmin';
import SearchMedVenAvl from './DigitalPartner/SearchMedVenAvl';
import DigiPartnerProfile from './DigitalPartner/DigiPartnerProfile';
import ManageStockist from './MyRoutes/ManageStockist';
import VoucherEntry from './DigitalPartner/VoucherEntry';
import CreateLedger from './DigitalPartner/CreateLedger';
import ProfitAndLoss from './DigitalPartner/ProfitAndLoss';
import DigiPartnerPharmacyOrders from './DigitalPartner/DigiPartnerPharmacyOrders';
import DigiPartneRetailOrders from './DigitalPartner/DigiPartneRetailOrders';
import WhatsAppMSG from './WhatsAppMSG';
import ContactUs from './MyRoutes/ContactUs';
import SalesStockReport from './DigitalPartner/SalesStockReport';
import AboutUs from './MyRoutes/AboutUs';
import TermsConditions from './MyRoutes/TermsConditions';
import PrivacyPolicy from './MyRoutes/PrivacyPolicy';
import Pharmacies from './DashContainers/Pharmacies';
import Distributors from './DashContainers/Distributors';
import Groceries from './DashContainers/Groceries';
import DigiPartPartyWiseSales from './DigitalPartner/DigiPartPartyWiseSales';
import Subscriptions from './Containers/Subscriptions';
import ManageProducts from './DigitalPartner/ManageProducts';
import SalesChallan from './DigitalPartner/SalesChallan';
import ManageCatWiseDisco from './DigitalPartner/ManageCatWiseDisco';
import NoInternet from './MyRoutes/NoInternet';
import ProductWisePnLSummary from './DigitalPartner/ProductWisePnLSummary';
import OutstandingReports from './DigitalPartner/OutstandingReports';
import AboutUSContent from './admin/AboutUSContent';
import ForwardedOrderDetails from './DigitalPartner/ForwardedOrderDetails';
import CityWisePayments from './admin/pages/CityWisePayments';
import ProductAvailability from './admin/pages/ProductAvailability';
import DigiPartPersonalization from './DigitalPartner/DigiPartPersonalization';
import ManageSubscriptionPlans from './MyRoutes/ManageSubscriptionPlans';
import DPNonMovableProducts from './DigitalPartner/DPNonMovableProducts';
import NearExpiryProducts from './DigitalPartner/NearExpiryProducts';


// import DashNavbar from './DashContainers/DashNavbar';
// import DashPanel from './DashContainers/DashPanel';
// import DashHome from './DashContainers/DashHome';


export default function Router() {
  return (
      // <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Home />}></Route>
          <Route path='/aboutUs' exact element={<AboutUs />}></Route>
          <Route path='/termsCon' exact element={<TermsConditions />}></Route>
          <Route path='/privacyPolicy' exact element={<PrivacyPolicy />}></Route>
          <Route path='/PillsSearch' element={<PillsSearch />}></Route>
          <Route path='/UploadPrescription' element={<UploadPrescription />}></Route>
          <Route path='/MyCart' element ={<MyCart />}></Route>
          <Route path='/Admin' element ={<Admin />}></Route>
          <Route path='/Products' element ={<Products />}></Route>
          <Route path='/DisplayProduct' element ={<DisplayProduct />}></Route>
          <Route path='/ShippingAdd' element ={<ShippingAdd />}></Route>
          <Route path='/OrderSummary' element ={<OrderSummary />}></Route>
          <Route path='/MyOrders' element ={<MyOrders />}></Route>
          <Route path='/PrescriptionOrder' element = {<PrescriptionOrder />}></Route>
          <Route path='/ViewAllOrders' element = {<ViewAllOrders/>}></Route>
          <Route path='/DispOrderDetailsAdmin' element = {<DispOrderDetailsAdmin/>}></Route>
          <Route path='/ManageCategories' element = {<ManageCategories/>}></Route>
          <Route path='/ProductGallery' element = {<ProductGallery/>}></Route>
          <Route path='/ManageSaltGroups' element = {<ManageSaltGroups/>}></Route>
          <Route path='/ManageComp' element = {<ManageComp/>}></Route>
          <Route path='/ManageCoupons' element = {<ManageCoupons/>}></Route>
          <Route path='/CategoryDiscount' element = {<CategoryDiscount/>}></Route>
          <Route path='/DigitalPartner' element = {<DigitalPartnerIndex/>}></Route>
          <Route path='/DPDashboard' element = {<DPDashboard/>}></Route>
          <Route path='/DPSalesStockState' element = {<DPSalesStockState/>}></Route>
          <Route path='/UserReport' element = {<UserReport />}></Route>
          <Route path='/UserReportAdmin' element = {<UserReportAdmin />}></Route>
          <Route path='/SearchMedVenAvl' element = {<SearchMedVenAvl />}></Route>
          <Route path='/DigiPartnerProfile' element = {<DigiPartnerProfile />}></Route>
          <Route path='/ManageStockist' element = {<ManageStockist />}></Route>
          <Route path='/VoucherEntry' element = {<VoucherEntry />}></Route>
          <Route path='/CreateLedger' element = {<CreateLedger />}></Route>
          <Route path='/ProfitAndLoss' element = {<ProfitAndLoss />}></Route>
          <Route path='/DigiPartnerPharmacyOrders' element = {<DigiPartnerPharmacyOrders />}></Route>
          <Route path='/DigiPartneRetailOrders' element = {<DigiPartneRetailOrders />}></Route>
          <Route path='/WhatsAppMSG' element = {<WhatsAppMSG />}></Route>
          <Route path='/ContactUs' element = {<ContactUs />}></Route>
          <Route path='/SalesStockReport' element = {<SalesStockReport />} ></Route>

      {/* digi part */}

          <Route path='/pharmacies' exact element={<Pharmacies />}></Route>
          <Route path='/distributors' exact element={<Distributors />}></Route>
          <Route path='/groceries' exact element={<Groceries />}></Route>
          
          <Route path='/partyWiseSalesReport' exact element={<DigiPartPartyWiseSales />}></Route>
          <Route path='/Subscriptions' exact element={<Subscriptions />}></Route>
          <Route path='/dpDashManageProducts' exact element={<ManageProducts />}></Route>
          <Route path='/dpDashManageCateWiseDisco' exact element={<ManageCatWiseDisco />}></Route>
          <Route path='/SalesChallan' exact element={<SalesChallan/>}></Route>
          <Route path='/noInternet' exact element={<NoInternet />}></Route>
          
          <Route path='/ForwardedOrderDetails' exact element={<ForwardedOrderDetails />}></Route>
          <Route path='/ProductWisePnLSummary' exact element={<ProductWisePnLSummary />}></Route>
          <Route path='/outStandingReports' exact element={<OutstandingReports />}></Route>
          <Route path='/DigiPartPersonalization' exact element={<DigiPartPersonalization />}></Route>
          <Route path='/DigitalPartnerNonMovableProducts' exact element = {<DPNonMovableProducts />}></Route>
          <Route path='/NearExpiryExpiredProducts' exact element = {<NearExpiryProducts/>}></Route>

          {/* admin */}

          <Route path='/addAboutUsContent' exact element={<AboutUSContent type="about" />}></Route>
          <Route path='/privacyPolicyContent' exact element={<AboutUSContent type="privacy" />}></Route>
          <Route path='/faqsContent' exact element={<AboutUSContent type="faqs" />}></Route>
          <Route path='/termsAndConditionContent' exact element={<AboutUSContent type="terms" />}></Route>
          <Route path='/citywisePayments' exact element={<CityWisePayments />}></Route>
          <Route path='/productAvailability' exact element={<ProductAvailability />}></Route>
          <Route path='/ManageSubscriptionPlans' exact element={<ManageSubscriptionPlans/>}></Route>

          {/* <Route path='/Dash' element = {<DashNavbar />} ></Route>
          <Route path='/DashPanel' element = {<DashPanel />} ></Route>
          <Route path='/DashHome' element = {<DashHome />} ></Route>
          <Route path="*" element={<NoPage />} /> */}
        </Routes>
      // </BrowserRouter>
  )
}