import React, { useEffect, useState } from 'react'
import defProdImage from '../../static/images/Banner3.jpeg';
import { Link } from 'react-router-dom';


const MyCatalogue = () => {
  var startCat = 0;
  const [ categoriesData, setCategoriesData ] = useState(null);
  const [ categoryImages, setCategoryImages ] = useState(null);
   
  // Slide Control Methods
  const prevSlide = () => {
    var catArr = ["CatItems1","CatItems2","CatItems3","CatItems4","CatItems5"];
    var cats = document.getElementsByClassName('catsDiv');
    var startPos = startCat -1;
    if (startPos < 0)
      startPos = cats.length -1;
    for(let i =0 ; i < catArr.length;i++)
    {
      document.getElementById(catArr[i]).innerHTML = cats[startPos].innerHTML;
      if (startPos === (cats.length -1))
      startPos = 0;
      else
      startPos +=1;
    }
    if (startCat === 0)
    startCat = cats.length-1;
    else
    startCat -=1;
    // console.log("Position :",startCat);
  }

  const nextSlide = () => {
    var catArr = ["CatItems1","CatItems2","CatItems3","CatItems4","CatItems5"];
    var cats = document.getElementsByClassName('catsDiv');
    var startPos = startCat;
    for(let i =0 ; i < catArr.length;i++)
    {
      if (startPos === (cats.length -1))
      startPos = 0;
      else
      startPos +=1;
      document.getElementById(catArr[i]).innerHTML = cats[startPos].innerHTML;
    }
    if (startCat === (cats.length-1))
    startCat = 0;
    else
    startCat +=1;
  }

  const GetAllCategories = () => {
    fetch("http://35.154.53.53/manageCategoryAPI?showCat=1", {
        mode: 'cors',
        method: 'GET',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
        return response.json();
    }).then((resp) => {
        setCategoryImages(resp.files);
        setCategoriesData(resp.data);
        var catArr = ["CatItems1","CatItems2","CatItems3","CatItems4","CatItems5"];
        for( let i =0 ; i < catArr.length; i++)
        {
          var base64Image = resp.files[resp.data[i][2]];
          var innrhtml = "<a href = '/ProductGallery?dispCat="+resp.data[i][0]+"&catName="+resp.data[i][1]+"'> <img className='catImage' width = '100%' height='90%' alt=''/>";
          innrhtml += "<p>"+resp.data[i][1]+"</p> </a>";
          document.getElementById(catArr[i]).innerHTML = innrhtml;
          document.getElementById(catArr[i]).firstChild.childNodes[0].nextSibling.src = `data:image/jpeg;base64,${base64Image}`;
        }
    });
  }


  useEffect(() => {
      GetAllCategories();
  }, []);

  return (
    <>
      <h4 className='text-center pt-3' style={{color:"gray"}}>Product Categories</h4>
      <div className='Catalogue slide_control'>
        <div className="prevBtn" onClick={() => { prevSlide() }}></div>
        <div className="nextBtn" onClick={() => { nextSlide() }}></div>
        <div id = "categoryContainer">
          {
            categoriesData ?
            categoriesData.map((category, index)=>{
              var base64Image = categoryImages[category[2]];
              return <div key={index} className="catsDiv hide">
                  <Link to={"/ProductGallery?dispCat="+category[0]+"&catName="+category[1]}>
                  <img className='catImage' width='100%' src={`data:image/jpeg;base64,${base64Image}`} onError={(e) => { e.target.src = defProdImage }} alt=''/>
                  <p>{category[1]}</p>
                  </Link>
                
              </div>
            }) 
            :''
          }
        </div>
        
        <div className="CatItems1" id ="CatItems1"></div>

        <div className="CatItems2" id ="CatItems2"></div>

        <div className="CatItems3" id ="CatItems3"></div>

        <div className="CatItems4" id ="CatItems4"></div>

        <div className="CatItems5" id ="CatItems5"> </div>
      </div>
    </>
  )
}

export default MyCatalogue