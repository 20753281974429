import React, { useEffect, useState } from 'react'
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
// import { createRoot } from 'react-dom/client';
import cart from '../../SharedItems';
import { ReactSession } from 'react-client-session';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Parser from 'html-react-parser';

const DisplayProduct = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const pro_id = queryParameters.get("pro_id");
  let DelDate = new Date();
  DelDate.setDate(DelDate.getDate() + 7);
  // var options = { day:'long', day: 'numeric', month: 'numeric',  year: 'numeric' };
  // var deliveryDate = {date: DelDate.toLocaleDateString('en-GB',options)};
  // var deliveryDate = {date: new Date().toLocaleString()};
  var deliveryDate = { date: DelDate.toLocaleDateString('en-GB') };
  const [prodImage, setProdImage] = useState(null);
  const [prodGallery, setProdGallery] = useState(null);
  const [galleryData, setGalleryData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [prodData, setProdData] = useState(null);
  const [prodRatings, setProdRatings] = useState([0, 0, 0]);

  //Method to add product in cart 
  function addtoCart(ProId) {
    // headers = {'Content-type': 'application/json; charset=UTF-8'}
    fetch('http://35.154.53.53/selectProduct/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        proId: ProId,
        userId: ReactSession.get('id_eu'),
      })
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('CartCount').classList.replace('hide', 'show');
        cart.value = data.cart;
        document.getElementById("CartCount").innerText = cart.value;
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const swapImage = (event) => {
    var sourceImg = event.target.src;
    var targetImage = document.getElementById('FullImage');
    var tmpSRC = targetImage.src;
    targetImage.src = sourceImg;
    event.target.src = tmpSRC;
  }


  //Call API to tetreive product details
  const getProductDetails = () => {

    fetch("http://35.154.53.53/selectProduct?pro_id=" + pro_id, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      setProdImage(res.image);
      setProdGallery(res.gallery);
      setGalleryData(res.galleryData);
      setCategories(res.categories);
      setProdData(res.data);
      setProdRatings(res.ratings);
    });
  }

  useEffect(() => {
    getProductDetails();
  }, []);

  return (
    <>
      <Logo />
      <NavSearchBar />
      <div id="prodDetails">Display Product ID {pro_id} </div>
      {
        prodData ?
          prodData.map((product) => {
            const images = galleryData.map((galldata,index) => {
              var base64Image = prodGallery[galldata[2]];
              return <div onClick={swapImage} id= {"galImage"+index}>
                <img src={`data:image/jpeg;base64,${base64Image}`} className="prodImage" alt="" />
              </div>
            });

            var mainImage = prodImage[product[9]];
            
            return (
              <div>
                <p className='prodInfo'>Home&gt;{categories}&gt;{product[2]}</p>
                <Row>
                  <Col lg={6} md={6} xl={6} sm={12} xs={12} >
                    <div className='prodImageGallery'>
                      <div className="imageGallery customScroll" id="imageGallery">
                        {images}
                      </div>
                      <div className="FullImage">
                        <img  id="FullImage" src={`data:image/jpeg;base64,${mainImage}`} style={{ textAlign: "center", justifyContent: "center", maxWidth: '100%', maxHeight: '50vh' }} alt="" />
                      </div>
                    </div>
                    <div className='productOverview'>
                      <h5 style={{ lineHeight: '.7rem', marginTop: '.5rem' }}>Overview</h5>
                      <div id="overview"></div>
                      {product[15] !== null ? Parser(product[15]) : ""}
                    </div>
                  </Col>

                  {/* pro_id, cat_id, pro_name, company, unit, pro_detail, pro_price, pro_amount, pro_discount, pro_img, IsBuyable, 
                  out_of_stock, IsReqPrescription, GroupID, GroupName, uses */}
                  <Col lg={6} md={6} xl={6} sm={12} xs={12} >
                    <div className='prodImageGallery'>
                      <h4 style={{ lineHeight: '.7rem', marginTop: '.5rem' }}>{product[2]}</h4>
                      <p className='prodInfo' style={{ marginTop: '1rem' }}>{product[3]}</p>
                      <p className='prodInfo'>{product[4]}</p>
                      {
                        product[10] === 0 ?
                          <>
                            <p className='prodInfo'>{Number(parseInt(prodRatings[0][2]) / parseInt(prodRatings[0][1])).toFixed(2)} /  {prodRatings[0][2]} Ratings & {prodRatings[0][2]} User Reviews</p>
                            <Row>
                              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                                <p className='prodInfo'><b>INR:{product[7]}</b></p>
                                <p className='prodInfo'>{product[8]}% Off - MRP:<span style={{ textDecoration: 'line-through' }}>{product[6]}</span></p>
                              </Col>
                              <Col lg={5} md={5} xl={5} sm={11} xs={11}>
                                <button className="btn btn-primary btn-sm smallHeight" onClick={() => addtoCart(product[0])}>Add to Cart</button>
                              </Col>
                            </Row>

                            <div style={{ borderTop: '2px dotted gray', marginTop: '.5rem', width: "95%", marginLeft: '.3rem' }}>
                              <Row >
                                <Col lg={2} md={2} xl={2} sm={2} xs={2}>
                                  <img width='60%' src={require('../../static/images/Icons/coupon.png')} alt="" /><b>+</b>
                                </Col>
                                <Col lg={10} md={10} xl={10} sm={10} xs={10}>
                                  <p className='prodInfo' style={{ marginTop: '.3rem' }}>5% Extra discount + Free Shipping </p>
                                  <p className='prodInfo' style={{ fontSize: '.6rem' }}>With membership plan <i className="fa fa-exclamation-circle"></i></p>
                                </Col>
                              </Row>
                            </div>
                            <div style={{ borderTop: '2px solid lightgray', marginTop: '.5rem', width: "95%", marginLeft: '.3rem' }}>
                              <Row >
                                <Col lg={12} md={12} xl={12} sm={12} xs={12}>
                                  <p className='prodInfo' style={{ fontSize: '.8rem' }}>Choose Pack Size</p>
                                  {/* Display Variants */}
                                </Col>
                              </Row>
                            </div>

                            <div style={{ borderTop: '2px solid lightgray', marginTop: '.5rem', width: "95%", marginLeft: '.3rem' }}>
                              <Row >
                                <Col lg={7} md={7} xl={6} sm={6} xs={6}>
                                  <p className='prodInfo'>Delivered by </p>
                                  <p className='prodInfo'>{deliveryDate.date} | <span style={{ color: "green" }}><b>FREE DELIVERY </b></span></p>
                                </Col>
                                <Col lg={2} md={2} xl={2} sm={2} xs={2}>
                                  <img width="50%" src={require('../../static/images/Icons/delivery-boy.png')} alt="" />
                                </Col>
                              </Row>
                            </div>

                            <div className='cartColTopBorderDotted'>
                              <Row>
                                <Col lg={12} md={12} xl={12} sm={12} xs={12}>
                                  <p className='prodInfo' style={{ fontSize: '.6rem' }}>Use Pincode to check delivery informations</p>
                                  <input type='text' id="pincode" size='6' maxLength={6} /><button className='btn btn-primary btn-sm' style={{ lineHeight: '.7rem', marginTop: '.3rem', marginLeft: '1rem' }}>Submit</button>
                                </Col>
                              </Row>
                            </div>
                          </>
                          : ""
                      }
                    </div>
                  </Col>
                </Row>
                {
                /* 
                    <button className = "btn btn-outline-primary btn-sm toolItem smallHeight" onClick={() => addtoCart(product[0]) }>Add to Cart</button>
                */}
              </div>
            )
          })
          : ''
      }
    </>
  )
}

export default DisplayProduct