import React, { useEffect, useState } from 'react'
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar'
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel'
import { LuSearch } from "react-icons/lu";
import { FaChevronDown } from 'react-icons/fa';
import { ReactSession } from 'react-client-session';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../Containers/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../DatePicker.css';

ReactSession.setStoreType("localStorage");

const ProductWisePnLSummary = () => {
  
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState(null);
  const [isLoading, setIsLoading] =useState(false);
  const [startDate, setstartDate ] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [dateFilter, setDateFilter] = useState(false);

  const [filltered, setFilltered] = useState(false);
  const [Qry, setQry] = useState('');
  const [noofRec, setNoOfRec] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesize, setPageSize] = useState(2);

  const executeQry = () => {
    setFilltered(!filltered);
    // setPage(1);
  }

  const handleSearchText = (e) => {
    setQry(e.target.value);
    if (e.key === "Enter")
    {
      executeQry();
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
    else{
      alert("No more data");
    }
  }

  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }
  
  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
    else{
      alert("No more data");
    }
  }

  const getInvoiceDetails = (dtFrom = null, dtTo = null) => {
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?user_Id=' + dp_id + '&sales_bill=-1&page=' + page + '&pagesize=' + pagesize + '&Qry=' + Qry;

    if (dtFrom !== null && dtTo !== null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;
    
    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else if(resp.status === "accessError")
      {
        alert("Your current subscription doesnot support Products and Invoice wise P&L service, please contact pillsit sales team.")
      }
      else if (resp.status === "success") { 
        setInvoiceData(resp.invoiceHeads);
        setInvoiceItems(resp.invoiceItems);
        setNoOfRec(resp.noofRec);
      }
      setIsLoading(false);
    });
  }

  const openDropDown = (element) => {
    let el = document.getElementById(element);
    el.classList.toggle('active');
  }

  // useEffect(()=>{
  //   getInvoiceDetails();
  // }, [page]);

  useEffect(()=>{
    setPage(1);
    setstartDate(null);
    setendDate(null);
  }, [filltered]);

  useEffect(()=>{
    setPage(1);
  }, [endDate]);

  useEffect(()=>{
    if (startDate !== null && endDate !== null && endDate > startDate)
    {
      var dtFrom= (startDate.toLocaleDateString()).split('/');
      var dtTo= (endDate.toLocaleDateString()).split('/');
      var dateFrom = dtFrom[2] + '-' + dtFrom[0] + '-' + dtFrom[1];
      var dateTo = dtTo[2] + '-' + dtTo[0] + '-' + dtTo[1];
      getInvoiceDetails(dateFrom, dateTo);
    }
    else 
    {
      getInvoiceDetails();
    }
  }, [page, filltered, endDate]);
  
  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent orderDetailsPage' id='DigiDashContent' style={{ backgroundColor: '#fff', padding: "10px 20px" }}>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: 10, paddingBottom: 10, borderBottom: "1.5px solid #000", marginTop: 10 }}>
            <h3 style={{ color: "#000", fontWeight: "600", fontSize: 25, lineHeight: 1, margin: 0, padding: 0, marginRight: 50 }}>Product Wise P&L Summary</h3>

            <div className="searchBox" style={{ display: "flex", alignItems: "center", backgroundColor: "#E8E8E8", width: "400px", borderRadius: 10, marginLeft: -30 }}>
              <input type="text" style={{ fontStyle: "italic", fontSize: "14px", padding: "10px 10px", border: "none", outline: "none", flex: 1, backgroundColor: "#E8E8E8", borderRadius: 10 }} 
                placeholder='Search by user name / order ID..!' 
                onChange={handleSearchText}  
                onKeyDown ={(e)=> {
                if (e.key === "Enter") {
                  executeQry();
                }
                }}
                value={Qry}
              />
              <i style={{ width: "60px", cursor: "pointer", minHeight: "40px", color: "#fff", background: "#000", display: "flex", alignItems: "center", justifyContent: "center", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}><LuSearch /></i>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: 10, marginLeft: 20 }}>
              {/* <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "105px" }} />
              <span style={{ margin: 0, padding: 0 }}>-</span>
              <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "105px" }} /> */}
              <div className='dateCont'>
                <div>From&nbsp;:&nbsp;</div>
                <DatePicker selected={startDate} onChange={(date) => {
                  if (endDate && (new Date(date) > endDate) )
                  {
                    alert("Start date must smaller than End date");
                  }
                  else
                  {
                    setstartDate(new Date(date));
                    setDateFilter(true);
                  }
                }} />
              </div>

              <div className='dateCont'>
                <div>&nbsp;To&nbsp;:&nbsp;</div>
                <DatePicker selected={endDate} 
                onChange={(date) => {
                  if (startDate && (new Date(date) < startDate) )
                  {
                    alert("End Date must bigger than start date");
                  }
                  else
                  {
                    console.log("Date Filter invoked");
                    setDateFilter(true);
                    setendDate(new Date(date));
                  }
                }} 
                />
              </div>
              <img src={require("../../static/DPImages/calendar.png")} style={{ width: 25, height: 25, cursor: "pointer" }} alt="" />
              <button style={{ padding: 5, minWidth: "60px", border: 0, outline: 0, borderRadius: "5px", color: "#fff", background: "#05B7FE" }}>Go</button>
            </div>
            {
              Qry !== ""  || dateFilter ? 
                <Tooltip title="Remove Filter">
                  <img onClick={() => {
                    setQry('');
                    setFilltered(!filltered);
                    setDateFilter(false)
                  }} className="closeBtn" style={{ width: '20px', height: '20px' }} src={require('../../static/DPImages/Icons/filterClear.png')} alt="" />
                </Tooltip>
              : 
                null
            }
          </div>
          
          {/* <div className="pagination">
            <button className="prev" onClick={()=>prevPage()}>Prev</button>
            <button className="page">{page}</button>
            <button className="next" onClick={()=>nextPage()}>Next</button>
          </div> */}
          { isLoading ? <Loader /> : null}

          <div className="userAddress customScroll" style={{ overflowY: "auto", height: "75vh", cursor: "pointer",overflowX:"hidden",paddingRight:15 }}>
            {
              invoiceData && invoiceData !== null ?
              invoiceData.map((invoice, index)=>{
                var billDate = invoice[5].split('T');
                const clss = "address-"+String(index);
                var totQty = 0, totPrice = 0, totAmt = 0, totDis = 0, gstTot = 0, totPurAmt=0;
                return (
                  <div key={index} className='userAddressHead' onClick={()=>openDropDown(`${clss}`)} style={{ borderBottom: "1.5px solid #000",paddingBottom:10 }} >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        <h4 style={{ color: "#000", fontSize: 18 }}>{invoice[8]}"({invoice[2]})"</h4>
                        <i style={{ marginTop: -10, fontSize: 20 }}><FaChevronDown /></i>
                      </div>
                      <p style={{ margin: 0, padding: 0, color: "gray", fontSize: 14 }}>Order ID : #{invoice[1]} | Order Date And Time : {billDate[0]} | {billDate[1]}</p>
                    </div>
                    <div className={`addressCon ${clss}`} id={`${clss}`}>
                      <p style={{margin:0,padding:0,color:"gray",fontSize:"11px"}}>{invoice[9]}</p>
                      <p style={{margin:0,padding:0,color:"gray",fontSize:"11px"}}>{invoice[10]}, {invoice[11]}, {invoice[12]} - {invoice[14]}</p>
                      <p style={{margin:0,padding:0,color:"gray",fontSize:"11px"}}>{invoice[15]} | {invoice[16]}</p>
                    </div>

                    <div className="details detail-1">
                      <div class="row tableHead">
                        <div class="col-sm">
                          Particulars
                        </div>
                        <div class="col-sm">
                          Unit
                        </div>
                        <div class="col-sm">
                          Sale Qty
                        </div>
                        <div class="col-sm">
                          MRP
                        </div>
                        <div class="col-sm">
                          Discount %
                        </div>
                        <div class="col-sm">
                          Sale Amount
                        </div>
                        <div class="col-sm">
                          GST Slab | Amount
                        </div>
                        <div class="col-sm">
                          Purchase Amount
                        </div>
                        <div class="col-sm">
                          Profit / Loss
                        </div>
                      </div>
                      {
                        invoiceItems && invoiceItems !== null ? 
                        invoiceItems.map((items, index)=>{
                          if (items[22] === invoice[0])
                          { 
                            {/* totQty = 0, totPrice = 0, totAmt = 0, totDis = 0, gstTot = 0; */}
                            totQty += parseInt(items[4]);
                            totPrice += (parseInt(items[4]) * parseInt(items[14]));
                            totDis += (((parseInt(items[4]) * parseInt(items[14])) * parseFloat(items[15])) /100 );
                            totAmt += parseFloat(items[16]);
                            totPurAmt += (parseFloat(items[23]) * parseInt(items[4]));
                            gstTot += ((parseFloat(items[6]) + parseFloat(items[7]) + parseFloat(items[8])) * (parseInt(items[16])) / 100);
                            return (
                              <div key = {index} class="row tableRecord">
                                <div class="col-sm">
                                  {items[1]}
                                </div>
                                <div class="col-sm">
                                  {items[2]}
                                </div>
                                <div class="col-sm">
                                  {items[4]}
                                </div>
                                <div class="col-sm">
                                  {items[14]}
                                </div>
                                <div class="col-sm" style={{color:"green",fontWeight:"700"}}>
                                  {items[15]}
                                </div>
                                <div class="col-sm">
                                  {items[16]}
                                </div>
                                <div class="col-sm">
                                {parseFloat(items[6]) + parseFloat(items[7] + parseFloat(items[8]))}% | {Number((parseFloat(items[6]) + parseFloat(items[7]) + parseFloat(items[8])) * parseFloat(items[16]) / 100).toFixed(2)}
                                </div>
                                <div class="col-sm">
                                  {Number(parseFloat(items[23]) * parseFloat(items[4])).toFixed(2)}
                                </div>
                                <div class="col-sm" style={{color:"#000",fontWeight:"700"}}>
                                  {Number((parseFloat(items[16]) - (parseFloat(items[23]) * parseFloat(items[4]))) * 100 / parseFloat(items[16])).toFixed(2)} %
                                </div>
                              </div>
                            );
                          }
                        }) 
                        : null
                      }
                      
                      <h3 style={{margin:0,padding:0,color:"gray",fontSize:14,marginTop:10,textAlign:"right",marginRight:20}}>Total Items :  {totQty} | 
                      MRP Total : {Number(totPrice).toFixed(2)} | Discount Amount : {Number(totDis).toFixed(2)} | GST Paid : {Number(gstTot).toFixed(2)} | Purchase Amount : {Number(totPurAmt).toFixed(2)} | Order Value : {Number(totAmt).toFixed(2)} | 
                      <b style={{color:"#000"}}>Net Profit : {Number(totAmt - totPurAmt).toFixed(2)}</b></h3>
                    </div>

                  </div>
                )
              })
              : null
            }

          </div>

          <div className="prodPagination" style={{padding:'0px', margin:'0px'}}>
            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', margin: "0 auto" }}>
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
              <input className={"pageNavButton"} size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductWisePnLSummary