import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import { useReactToPrint } from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';
import { IoSearchOutline } from "react-icons/io5";

import '../../stockPrint.css';
import Loader from '../Containers/Loader';

ReactSession.setStoreType("localStorage");

const NearExpiryProducts = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [nonMovableProducts, setNonMovableProducts] = useState(null);
  const [nonMovableProductsPrint, setNonMovableProductsPrint] = useState(null);
  const [DPProfile, setDPProfile] = useState(null);

  const [filltered, setFilltered] = useState(false);
  const [Qry, setQry] = useState('');
  const [page, setPage] = useState(1);
  const [noofRec, setNoofRec] = useState(0);
  const [pagesize, setPageSize] = useState(20);
  const [expiryDays, setExpiryDays] = useState(120);
  const [currentDate, setCurrentDate] = useState(new Date());

  // Days difference between dates
  const calculateDaysBetween = (date1, date2) => {
    // Convert both dates to milliseconds
    const date1_ms = new Date(date1).getTime();
    const date2_ms = new Date(date2).getTime();
    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;
    // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
    const differenceInDays = difference_ms / (1000 * 60 * 60 * 24);
    return differenceInDays;
  };

  // Handle Printer
  const printUserStockRef = React.useRef();
  const handlePrintUserStock = useReactToPrint({ content: () => printUserStockRef.current, });

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const handleSearchText = (e) => {
    setQry(e.target.value);
    if (e.key === "Enter")
    {
      executeQry();
    }
  }

  const handleExpiryDays = (e) => {
    setExpiryDays(e.target.value);
    if (e.key === "Enter")
    {
      executeQry();
    }
  }

  // var col1 = 0;
  // var col2 = 0;
  // var col3 = 0;
  // var col4 = 0;
  // var col11 = 0;
  // var col22 = 0;
  // var col33 = 0;
  // var col44 = 0;

  const executeQry = () => {
    setFilltered(!filltered);
  }

  const getNonMovableProducts = (print = null, dtFrom = null, dtTo = null) => {
    var apiURL = 'http://35.154.53.53/digiPartnerAPI?stockState=1&user_Id=' + ReactSession.get('id') + '&Qry=' + Qry + '&expiryDays=' + expiryDays + '&pagesize=' + pagesize + '&page='+page;
    if (print !== null)
      var apiURL = 'http://35.154.53.53/digiPartnerAPI?stockState=1&user_Id=' + ReactSession.get('id') + '&Qry=' + Qry + '&expiryDays=' + expiryDays;

    
    if (dtFrom != null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;
    
    setLoader(true);
    
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      else if (resp.status === "accessError") {
        alert("Your current subscription doesnot support Non Movable Products service, please contact pillsit sales team");
      }
      else if (resp.status === "success") {
        if (print !== null)
        {
          setNonMovableProductsPrint(resp.stockData);
        }
        else
        {
          setNonMovableProducts(resp.stockData);
          setDPProfile(resp.dp_profile[0]);
          setNoofRec(resp.noofRec);
        }
      }
      setLoader(false);
    });
  }


  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before Sales & Stock Statements...")
        navigate('/DigiPartnerProfile');
      }
      else
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          getNonMovableProducts();
        }
  }, [page]);

  useEffect(()=>{
    getNonMovableProducts();
  }, [filltered]);

  useEffect(()=>{
    if (nonMovableProductsPrint !== null)
      {
        handlePrintUserStock();
      }
  }, [nonMovableProductsPrint]);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent manageProducts' id='DigiDashContent' style={{ backgroundColor: 'white' }}>
          
          { loader ? <Loader /> : null}
          
          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
            {menuCollapse ?
              <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
              :
              <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
            }
          </div>

          <div style={{padding:'3vw'}}>
            <div className="head" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <h3 style={{ margin: 0, padding: 0 }}>Near Expiry & Expired Products</h3>

            <div className="pagination">
              <button className="prev" onClick={()=>prevPage()}>Prev</button>
              <button className="page">{page}</button>
              <button className="next" onClick={()=>nextPage()}>Next</button>
              <div className='digiNavBtn2 mediumButtons' onClick={()=>getNonMovableProducts("print")}>Print</div>
            </div>

          </div>

          <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: 10 }}>
            <div className="inputBox">
              <input type="text" placeholder='Search Products...' onChange={handleSearchText}  
              onKeyDown ={(e)=> {
              if (e.key === "Enter") {
                executeQry();
              }
              }}
              value={Qry} id = "serText" />
              <i><IoSearchOutline /></i>
            </div>

            <div  style={{width:'45%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <div>
                Freez Days
              </div>
              <div className="inputBox" style={{width:'70%'}}>
                <input type="text" placeholder='Days since freez' onChange={handleExpiryDays}  
                onKeyDown ={(e)=> {
                if (e.key === "Enter") {
                  executeQry();
                }
                }}
                value={expiryDays} id = "expiryDays" />
                <i><IoSearchOutline /></i>
              </div>
            </div>

            {
              Qry !== "" || expiryDays !== 120 ? 
                <Tooltip title="Remove Filter">
                  <img onClick={() => {
                    setQry('');
                    setExpiryDays(120);
                    setFilltered(!filltered);
                  }} className="closeBtn" style={{ width: '20px', height: '20px' }} src={require('../../static/DPImages/Icons/filterClear.png')} alt="" />
                </Tooltip>
              : 
                null
            }
          </div>

            <div style={{ display: 'flex',paddingRight:'8px', marginTop:'1vw', width: '100%', fontWeight: 'bold' }}>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '6%' }}>S.No</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '8%' }}>Expiry</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '26%' }}>Name</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '19%' }}>Unit</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '10%' }}>Last Sold</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '6%' }}>Days to Expiry</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '10%' }}>Batch</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '7%' }}>Price</div>
              <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '8%' }}>Rack No</div>
            </div>
            <div className='viewExpProd customScroll' style={{width: '100%' }}>
              {
                nonMovableProducts && nonMovableProducts !== null ?
                  nonMovableProducts.map((data, index) => {
                    var dateNtime = String(data[18]).split('T');
                    var daysToExpire = parseInt(calculateDaysBetween(currentDate, data[19]));
                    return (
                      <div key={index} style={{ display: 'flex', width: '100%', border: '1px solid black' }} className="stockList">
                        <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '6%' }}>{index + 1}</div>
                        <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>{data[11]}{"/"}{data[12]}</div>
                        <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '26%' }}>{String(data[3]).length < 51 ? `${data[3]}` : `${data[3].substring(0, 50)}...`}</div>
                        <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '19%' }}>{String(data[4]).length < 46 ? `${data[4]}` : `${data[4].substring(0, 45)}...`} </div>
                        <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '10%' }}>{dateNtime[0]} {dateNtime[1]}</div>
                        <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '6%' }}>{daysToExpire}</div>
                        <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '10%' }}>{data[15]}</div>
                        <div style={{ fontSize: '80%', textAlign: 'right', borderRight: '1px solid gray', width: '7%' }}>{Number(data[10]).toFixed(2)}</div>
                        <div style={{ fontSize: '80%', textAlign: 'center', width: '8%' }}>{data[16]}</div>
                      </div>
                    )
                  })
                : ''
              }
            </div>
          </div>
        </div>

        {/* Print Component */}
        <div ref={printUserStockRef} className='printable-document' >
          {
            nonMovableProductsPrint ?
            nonMovableProductsPrint.map((item, index) => {       
              var dateNtime = String(item[18]).split('T');
              var daysToExpire = parseInt(calculateDaysBetween(currentDate, item[19]));

              return (
                <div key={index}>
                { index % 20 === 0 && index > 9 ?
                  <div style={{ marginLeft: '30%' }}>to be continued on next page......</div>
                  : null
                }

                {
                index % 20 === 0 ?
                  <div className='page-break' style={{ width: '100%', marginTop:'6%' }}>
                    { index === 0  ?
                      <div style={{display:'block', textAlign:'center'}}>
                        <div style={{fontSize:'150%', lineHeight:'95%'}}>{DPProfile[2]}</div>
                        <div style={{fontSize:'90%'}}>{DPProfile[3]}, {DPProfile[4]}, {DPProfile[5]}, {DPProfile[6]}</div>
                        <div style={{fontSize:'90%'}}>Phone : {DPProfile[7]}-{DPProfile[8]} ,{DPProfile[9]}, {DPProfile[10]} | CTX : {DPProfile[11]}, {DPProfile[12]}, {DPProfile[12]} | Mobile : {DPProfile[14]}</div>
                        <div style={{fontSize:'125%', lineHeight:'90%'}}>Non Movable Products</div>
                      </div>
                    :null }
                    
                    <div style={{ display: 'flex',paddingRight:'8px', marginTop:'1vw', width: '100%', fontWeight: 'bold' }}>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '6%' }}>S.No</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '8%' }}>Expiry</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '26%' }}>Name</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '19%' }}>Unit</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '10%' }}>Last Sold</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '6%' }}>Days to Expiry</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '10%' }}>Batch</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '7%' }}>Price</div>
                      <div style={{ fontSize: '80%', textAlign: 'center', border: '1px solid gray', width: '8%' }}>Rack No</div>
                    </div> 
                  </div>
                : null }

                  <div style={{ width: '99%', display: 'flex', backgroundColor: index % 2 === 0 ? 'lightgray' : 'white', justifyContent: 'flex-start' }}>
                    <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '6%' }}>{index + 1}</div>
                    <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>{item[11]}{"/"}{item[12]}</div>
                    <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '26%' }}>{String(item[3]).length < 51 ? `${item[3]}` : `${item[3].substring(0, 50)}...`}</div>
                    <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '19%' }}>{String(item[4]).length < 46 ? `${item[4]}` : `${item[4].substring(0, 45)}...`}</div>
                    <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '10%' }}>{dateNtime[0]} {dateNtime[1]}</div>
                    <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '6%' }}>{daysToExpire}</div>
                    <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '10%' }}>{item[15]}</div>
                    <div style={{ fontSize: '80%', textAlign: 'right', borderRight: '1px solid gray', width: '7%' }}>{Number(item[10]).toFixed(2)}</div>
                    <div style={{ fontSize: '80%', textAlign: 'center', width: '8%' }}>{item[16]}</div>
                  </div>
                </div>
              )})
            :
            null
          }
          
          {/* <div style={{ marginInline: '.5%', borderBlock: '1px solid gray', width: '99%', display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ width: '40%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', fontWeight: 'bold' }}>Totals</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}></div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}></div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col1}</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col2}</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col3}</div>
            <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{col4}</div>
          </div> */}
        </div>

      </div>
    </>
  )
}

export default NearExpiryProducts