import React from 'react'

const Oops = ({title}) => {
  return (
    <>
    <div className="container" style={{height:"70vh",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
      <img src={require("../../static/images/Icons/oops.png")} style={{width:"200px",height:"200px"}} alt="" />
      <h3 style={{color:"#000",paddingTop:15,marginTop:15,borderTop:"1px solid #000",width:"400px",textAlign:"center"}}>{title}</h3>
    </div>
    </>
  )
}

export default Oops