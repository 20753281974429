import React, { useRef, useState } from 'react'
import JoditEditor from 'jodit-react';
import DashNavbar from '../DashContainers/DashNavbar';
import DashPanel from '../DashContainers/DashPanel';

const AboutUSContent = ({type}) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');

  let head = type === "about" ? "About Us" : type === "privacy" ? "Privacy Policy" : type === "faqs" ? "Faqs" : type === "terms" ? "Terms & Conditions" : "";
  console.log(head)

  return (
    <>
      <div className='Orders'>
        <DashNavbar/>
        <div className='dashContainer'>
          <div className='dashPanel customScroll'>
            <DashPanel/>
          </div>
          <div className='dashContents' id='dashContents' style={{ background: "#fff", paddingTop: 10, padding: "10px" }}>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 10, marginBottom: 10, borderBottom: "1.5px solid #000" }}>
              <h3 style={{ margin: 0, padding: 0, color: "#000" }}>{head} Content</h3>
            </div>

            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1} // tabIndex of textarea
              onChange={newContent => setContent(newContent)}
            />

            <div style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
              <button className="adminButton mt-3">Update Content</button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUSContent