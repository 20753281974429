import './App.css';
import './AppMSS.css';
import './AppMSM.css';
import Router from './Components/Router';
import { useNavigate ,Navigate} from 'react-router-dom';
import { useEffect } from 'react';
import './Components/admin/admin.css';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to handle offline event
    const handleOffline = () => {
      navigate('/noInternet');
    };

    // Function to handle online event
    const handleOnline = () => {
      navigate('/');
    };

    // Add event listeners for online and offline events
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    // Check initial online status
    if (!navigator.onLine) {
      navigate('/noInternet');
    }

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [navigate]);

  return (
    <div className='MyContainer'>
      <Router />
    </div>
  );
}

export default App;
