import React, { useEffect, useState } from 'react'
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel'
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar'
import { IoSearchOutline } from "react-icons/io5";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import makeAnimated from 'react-select/animated';
import { ReactSession } from 'react-client-session';
import defProdImage from '../../static/DPImages/med1.png';
import Tooltip from '@material-ui/core/Tooltip';

import { useNavigate } from "react-router-dom";
import Loader from '../Containers/Loader';
import { Button } from '@material-ui/core';

const ManageProducts = () => {

  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isEditModal, setIsEditModal] = useState(false)
  const [isAddProdModal, setIsAddProdModal] = useState(false)
  const [prodData, setProdData] = useState(null);
  const [prodImages, setProdImages] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [cateOptions, setCateOptions] = useState([{ value: '', label: 'Select Category' },]);

  const [Qry, setQry] = useState('');
  const [noofRec, setNoofRec] = useState(0);
  const [page, setPage] = useState(1);
  const [pagesize, setPageSize] = useState(20);

  // var [selectedCate, setSelectedCate] = useState([]);
  // var handleSelectCate = (e) => {
  //   setSelectedCate(Array.isArray(e) ? e.map(x => x.value) : []);
  //   removeErrorMessage("selectCat");
  // }

  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }


  const handleSearchText = (e) => {
    setQry(e.target.value);
    if (e.key === "Enter")
    {
      getDPProducts();
    }
  }


  const handleChange = (selOption) => {
    setSelectedOption(selOption);
    getDPProducts(selOption);
  };

  const animatedComponents = makeAnimated();

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const ShowEditModal = (product) =>{
    setEditProduct(product);
    setIsEditModal(true);
    // where pro.pro_name like '%nor%' and FIND_IN_SET(26, pro.cat_Id)
  }  

  const updateProduct = () =>{
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'PUT',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        user_Id: ReactSession.get("id"),
        prod_code: editProduct[2],
        discount: document.getElementById('proDiscount').value,
      })
    }).then((response) => response.json())
    .then((resp)=>{
      if (resp.status === "success")
      { 
        alert("Discount Updated successfully");
        setEditProduct(null);
        setIsEditModal(false);
        getDPProducts();
      }
      else
      {
        alert("Update Fails, try again...");
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
  }

  const validateProduct = () => {
    var errorElements = [];

    if (document.getElementById("newProdName").value === "")
      errorElements.push("newProdName");

    if (document.getElementById("newProdUnit").value === "")
      errorElements.push("newProdUnit");

    if (document.getElementById("newProdComp").value === "")
      errorElements.push("newProdComp");

    if (document.getElementById("newProdMRP").value === "")
      errorElements.push("newProdMRP");

    if (document.getElementById("newProdDisc").value === "")
      errorElements.push("newProdDisc");

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      addNewProd();
    }
  }
  
  //Add new Product
  //newProdName newProdUnit newProdComp newProdMRP
  const addNewProd = () => {
    setIsLoading(true);
    fetch('http://35.154.53.53/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        dp_id:ReactSession.get("id"),
        newProdName: document.getElementById('newProdName').value,
        newProdUnit: document.getElementById('newProdUnit').value,
        newProdComp: document.getElementById('newProdComp').value,
        newProdMRP: document.getElementById('newProdMRP').value,
        newProdDisc: document.getElementById('newProdDisc').value
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        alert("Product Successfully Added");
        setIsAddProdModal(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  

  //Call API to retreive data for filter on 'manage product' & 'add product' UI
  const buildSearchOptions = () => {
    fetch("http://35.154.53.53/searchProAPI", {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      setCateOptions(res.proCateD);
    });
  }

  const getDPProducts = (catId =null) =>{
    setIsLoading(true);
    var qryURL = "http://35.154.53.53/digiPartnerAPI?getMyProducts=1&user_Id=" + ReactSession.get("id") + '&page='+ page +'&pagesize='+ pagesize + "&searchText="+Qry;
    
    if (catId !== null)
      qryURL += ("&cateId="+catId.value);
    else
      if (selectedOption !== null)
      {
        qryURL += ("&cateId="+selectedOption.value);
      }

    fetch(qryURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((resp) => {
      return resp.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
      }
      
      if (resp.status === "success")
      {
        setNoofRec(resp.noofRec);
        setProdData(resp.dpProducts);
        setProdImages(resp.prodImages);
      }
      setIsLoading(false);
    }).catch((err) => {
      console.log(err.message);
    });

  }

  useEffect(()=>{
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          buildSearchOptions();
          getDPProducts();
        }
  },[]);

  useEffect(()=>{
    getDPProducts();
  },[page]);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer'>
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>

        <div className='DigiDashContent manageProducts' id='DigiDashContent' style={{ backgroundColor: 'white', marginTop: 20 }}>
  
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
      
          { isLoading ? <Loader /> : null }

          <div className="head" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <h3 style={{ margin: 0, padding: 0 }}>Manage Products</h3>

            <div className="pagination">
              <button className="prev" onClick={()=>prevPage()}>Prev</button>
              <button className="page">{page}</button>
              <button className="next" onClick={()=>nextPage()}>Next</button>
              <button className="addProduct" onClick={() => { setIsAddProdModal(true) }}>Add Product +</button>
            </div>

          </div>

          <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: 10 }}>
            <div className="inputBox">
              <input type="text" placeholder='Search Products...' onChange={handleSearchText}  
              onKeyDown ={(e)=> {
              if (e.key === "Enter") {
                getDPProducts();
              }
              }}
              value={Qry} id = "serText" />
              <i><IoSearchOutline /></i>
            </div>

            <Select
              value={selectedOption}
              onChange={handleChange}
              options={cateOptions}
              placeholder="Select Category"
              className='selectCatSelect'
            />
            {
              (selectedOption && selectedOption !== null) || Qry !== "" ? 
                <Tooltip title="Remove Filter">
                  <img onClick={() => {
                    setQry('')
                    setSelectedOption(null);
                    // getDPProducts();
                    window.location.reload();
                  }} className="closeBtn" style={{ width: '20px', height: '20px' }} src={require('../../static/DPImages/Icons/filterClear.png')} alt="" />
                </Tooltip>
              : 
                null
            }
          </div>


          <div className="products customScroll">
          {
            prodData && prodData !==null && prodImages && prodImages !== null ?
              prodData.map((product, index) =>{
                const base64String = prodImages[product[4]];
                return(
                  <div className="product">
                    <img className='proImgAdmin' id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                      alt="products" onError={(e) => { e.target.src = defProdImage }} />

                    <div className="productDetails">
                      <h3 style={{ margin: 0, padding: 0, fontSize: 18 }}>{product[3]}</h3>
                      <p style={{ margin: 0, padding: 0, fontSize: 13, color: "gray" }}>{product[7]}</p>
                      <p style={{ margin: 0, padding: 0, fontSize: 13, color: "gray" }}>{product[6]}</p>
                    </div>
                    <p className='mrp' style={{ margin: 0, padding: 0, fontSize: 13, color: "gray" }}>MRP : {Number(product[8]).toFixed(2)}</p>
                    <p className="discount" style={{ margin: 0, padding: 0, fontSize: 13, color: "green", fontWeight: "700" }}>Discount : {product[10]}%</p>
                    <p className="price" style={{ margin: 0, padding: 0, fontSize: 13,  color: "gray" }}>Price : {Number(product[9]).toFixed(2)}</p>
                    <button className="edit" style={{margin: 0, padding: 0, fontSize: 13 }} onClick={() => {ShowEditModal(product);}}>Edit Discount</button>
                  </div>
                )
              })
              : null
          }
          </div>
        </div>

        {/* Edit Discount Modal */}
        <Modal show={isEditModal} onHide={() => setIsEditModal(false)} size="lg" backdrop="static" keyboard={false} >
          <Modal.Header closeButton>
            <Modal.Title>Editing Product</Modal.Title>
          </Modal.Header>
          <Modal.Body className='dpEditProductModal' >

            <div className="editModelBody">
              <input type="text" disabled defaultValue={editProduct ? editProduct[3] : null}/> <br />
              <input type="text" disabled defaultValue={editProduct ? editProduct[7] : null} /> <br />
              <input type="text" disabled defaultValue={editProduct ? editProduct[6] : null} /> <br />
              Categories <input type="text" disabled defaultValue={editProduct ? [...new Set(editProduct[11].split(','))].join(", ") : null}/> <br />
              Group <input type="text" disabled defaultValue={editProduct ? editProduct[12] : null}/> <br />

              <div className="flex" style={{ display: "flex", alignItems: "center", verticalAlign:'middle', gap: 10 }}>
                MRP <input type="text" disabled  defaultValue={editProduct ? editProduct[8] : null} />
                Discount <input type="text" autoFocus tabIndex={0} defaultValue={editProduct ? editProduct[10] : null} id="proDiscount" name="proDiscount"/>
              </div>
              Price <input type="text" disabled defaultValue={editProduct ? editProduct[9] : null} />
              <button className="submit btnBlue" onClick={updateProduct}>Submit</button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Add new Product Modal */}
        <Modal show={isAddProdModal} onHide={() => setIsAddProdModal(false)} size="lg" backdrop="static" keyboard={false} >
          <Modal.Header closeButton>
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body className='dpAddProductModal' >

            <div className="row" style={{ marginTop: '.2vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiLogin" style={{ display: 'flex', width: "100%" }}>
                  <input className="digiPartLoginInput" style={{ minWidth: "100%", paddingLeft: 0 }} name="newProdName" id="newProdName" type="text" placeholder="Product Name...." autoFocus 
                    onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("newProdUnit").focus();
                    }
                  }} onKeyPress={() => { removeErrorMessage("newProdName") }}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.2vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiLogin" style={{ display: 'flex', width: "100%" }}>
                  <input className="digiPartLoginInput" style={{ minWidth: "100%", paddingLeft: 0 }} name="newProdUnit" id="newProdUnit" type="text" placeholder="Unit...." 
                    onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("newProdComp").focus();
                    }
                  }} onKeyPress={() => { removeErrorMessage("newProdUnit") }}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.2vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiLogin" style={{ display: 'flex', width: "100%" }}>
                  <input className="digiPartLoginInput" style={{ minWidth: "100%", paddingLeft: 0 }} name="newProdComp" id="newProdComp" type="text" placeholder="Company Name...." 
                    onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("newProdMRP").focus();
                    }
                  }} onKeyPress={() => { removeErrorMessage("newProdComp") }}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.2vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiLogin" style={{ display: 'flex', width: "100%" }}>
                  <input className="digiPartLoginInput" style={{ minWidth: "100%", paddingLeft: 0 }} name="newProdMRP" id="newProdMRP" type="text" placeholder="MRP...." 
                    onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("newProdDisc").focus();
                    }
                  }} onKeyPress={() => { removeErrorMessage("newProdMRP") }}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.2vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiLogin" style={{ display: 'flex', width: "100%" }}>
                  <input className="digiPartLoginInput" style={{ minWidth: "100%", paddingLeft: 0 }} name="newProdDisc" id="newProdDisc" type="text" placeholder="Discount in %" 
                    onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("addProd").focus();
                    }
                  }} onKeyPress={() => { removeErrorMessage("newProdDisc") }}
                  />
                </div>
              </div>
            </div>
            <button className='btnBlue mt-3' id="addProd" onClick={validateProduct}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                validateProduct();
              }
            }} onKeyPress={() => { removeErrorMessage("newProdMRP") }}
            >Add Product</button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ManageProducts