import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import { createRoot } from 'react-dom/client'
import { useNavigate } from "react-router-dom";
import DigiPartnerSidePanel from '../DigitalPartner/DigiPartnerContainers/DigiPartnerSidePanel';
import { HiOutlineDotsHorizontal } from "react-icons/hi";


const DashHome = () => {

  const navigate = useNavigate();
  const [dashContents, setDashContents] = useState({});
  const [ordersPending, setOrdersPending] = useState(null);
  const [st, setst] = useState();
  // setDashContents({ ...dashContents, cats: 'no' }) presOrdersCo presCartsCo regUsersCo productsCo

  const hideClose = () => {
    document.getElementById('newOrders').classList.add("hide");
  }



  const GetDashContents = () => {

    fetch("http://35.154.53.53/searchProAPI?dashHome=1", {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      dashContents.presOrdersCo = res.presOrdersCo;
      dashContents.presCartsCo = res.presCartsCo;
      dashContents.regUsersCo = res.regUsersCo;
      dashContents.productsCo = res.productsCo;

      setOrdersPending(res.ordersPend);
    });
  }


  useEffect(() => {
    GetDashContents();
  }, []);


  return (
    <div style={{ display: 'block' }} id="">
      {/* <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        */}

      <h2 style={{ marginTop: 10 }}>Dashboard - Hi, Admin</h2>

      <div className="adminHomeTopMain">

        <div className="adminMainCards">

          <div className="adminMainCard">
             <div className="d-flex align-items-center justify-content-between" style={{display:"flex",height:"30px",alignItems:"center",paddingTop:20}}>
              <p style={{marginTop:0,fontSize:16,marginLeft:5}}>Android Registration</p>
              <i style={{marginTop:-20,cursor:"pointer",fontSize:17,marginRight:5}}><HiOutlineDotsHorizontal /></i>
             </div>
             <h2>474.00</h2>

             <div className="adminCardBottom">
               <p>From <br /> 1st Oct 2023 - 30th Oct 2023</p>
               <img src={require("../../static/AdminPanelImages/android.png")} alt="" />
             </div>
          </div>

          <div className="adminMainCard">
             <div className="d-flex align-items-center justify-content-between" style={{display:"flex",height:"30px",alignItems:"center",paddingTop:20}}>
              <p style={{marginTop:0,fontSize:16,marginLeft:5}}>IOS Registration</p>
              <i style={{marginTop:-20,cursor:"pointer",fontSize:17,marginRight:5}}><HiOutlineDotsHorizontal /></i>
             </div>
             <h2>474.00</h2>

             <div className="adminCardBottom">
               <p>From <br /> 1st Oct 2023 - 30th Oct 2023</p>
               <img src={require("../../static/AdminPanelImages/apple.png")} alt="" />
             </div>
          </div>

          <div className="adminMainCard">
             <div className="d-flex align-items-center justify-content-between" style={{display:"flex",height:"30px",alignItems:"center",paddingTop:20}}>
              <p style={{marginTop:0,fontSize:16,marginLeft:5}}>Retailers Registration</p>
              <i style={{marginTop:-20,cursor:"pointer",fontSize:17,marginRight:5}}><HiOutlineDotsHorizontal /></i>
             </div>
             <h2>474.00</h2>

             <div className="adminCardBottom">
               <p>From <br /> 1st Oct 2023 - 30th Oct 2023</p>
               <img src={require("../../static/AdminPanelImages/supermaket.png")} alt="" />
             </div>
          </div>

          <div className="adminMainCard">
             <div className="d-flex align-items-center justify-content-between" style={{display:"flex",height:"30px",alignItems:"center",paddingTop:20}}>
              <p style={{marginTop:0,fontSize:16,marginLeft:5}}>Wholesaler Registration</p>
              <i style={{marginTop:-20,cursor:"pointer",fontSize:17,marginRight:5}}><HiOutlineDotsHorizontal /></i>
             </div>
             <h2>474.00</h2>

             <div className="adminCardBottom">
               <p>From <br /> 1st Oct 2023 - 30th Oct 2023</p>
               <img src={require("../../static/AdminPanelImages/wholesaler.png")} alt="" />
             </div>
          </div>


        </div>

        <img className='graph1' src={require("../../static/AdminPanelImages/graph1.png")} alt="" />

      </div>


      <img src={require("../../static/AdminPanelImages/graph2.png")} alt="" className="graph2" />

      <div className='adminGraphsFlex'>
        <img src={require("../../static/AdminPanelImages/graph3.png")} alt="" />
        <img src={require("../../static/AdminPanelImages/graph4.png")} alt="" />
      </div>


      {/* <div className='dashHome' id="dashHome">
        <div className='tab Order'>
          <div className='Qty' id='orderQty'>{dashContents.presCartsCo}</div>
          <div className='tabLabel'>New Orders</div>
          <div className='moreInfo' onClick={() => { navigate('/ViewAllOrders?orderType=all') }}>More info &#10095;&#10095; </div>
        </div>

        <div className='tab Rx'>
          <div className='Qty' id='RxQty'>{dashContents.presOrdersCo}</div>
          <div className='tabLabel'>New Prescription</div>
          <div className='moreInfo'>More info &#10095;&#10095; </div>
        </div>

        <div className='tab Reg'>
          <div className='Qty' id='userReg'>{dashContents.regUsersCo}</div>
          <div className='tabLabel'>User Registration</div>
          <div className='moreInfo'>More info &#10095;&#10095; </div>
        </div>

        <div className='tab Product'>
          <div className='Qty' id='Qty'>{dashContents.productsCo}</div>
          <div className='tabLabel'>Products</div>
          <div className='moreInfo'>More info &#10095;&#10095;</div>
        </div>

      </div> */}
      {/* <div className='newOrders' id='newOrders'>

        <div style={{ position: 'relative' }}>
          <Row style={{ marginLeft: '1px', fontSize: '105%' }}>
            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
              <img width='4%' src={require('../../static/images/Icons/pending2.png')} alt='' /> &nbsp;Pending Orders
            </Col>
          </Row>
          <Row className="getDataHeader">
            <Col md={2} lg={2} xl={2} sm={6} xs={6}>
              Order No
            </Col>
            <Col md={2} lg={2} xl={2} sm={6} xs={6}>
              Order Date
            </Col>
            <Col md={4} lg={4} xl={4} sm={6} xs={6}>
              Status
            </Col>
            <Col md={2} lg={2} xl={2} sm={3} xs={3}>
              Quantity
            </Col>
            <Col md={2} lg={2} xl={2} sm={3} xs={3}>
              Amount
            </Col>
          </Row>
          {
            ordersPending ?
              ordersPending.map((order, index) => {
                var ordDate = order[1] !== null ? (order[1]).substring(0, 10).split("-") : ['', '', ''];
                var statusColor = '';

                if ((order[2]).substring(0, 7) === 'Pending')
                  statusColor = "orange"

                return <>
                  <Row className='orderRows'>
                    <Col md={2} lg={2} xl={2} sm={6} xs={6}>
                      {order[0]}
                    </Col>
                    <Col md={2} lg={2} xl={2} sm={6} xs={6}>
                      {ordDate[2]}/{ordDate[1]}/{ordDate[0]}
                    </Col>
                    <Col md={4} lg={4} xl={4} sm={6} xs={6} style={{ backgroundColor: statusColor, borderRadius: '5px' }}>
                      {order[2]}
                    </Col>
                    <Col md={2} lg={2} xl={2} sm={3} xs={3} style={{ textAlign: 'right' }}>
                      {order[3]}
                    </Col>
                    <Col md={2} lg={2} xl={2} sm={3} xs={3} style={{ textAlign: 'right' }}>
                      {(parseFloat(order[4])).toFixed(2)}
                    </Col>
                  </Row>
                </>
              })
              : ''
          }

          <div className='viewAllOrders' onClick={() => { navigate('/ViewAllOrders?orderType=pending') }}>All Orders</div>
          <span className="penOrderClose" onClick={() => { hideClose() }}>
            <img src={require('../../static/images/Icons/close.png')} alt="" />
          </span>
        </div>

      </div> */}
    </div>
  )
}

export default DashHome